import { useCallback } from 'react';
import { useFluxRequestHeaders } from 'utils/sessionHelpers';

const API_URL = window.env.REACT_APP_BASE_FLUX_API_URL;
const BASE_URL = new URL('carriers/code_list', API_URL);

export function useFetchAllCarriers() {
  const headers = useFluxRequestHeaders();

  return useCallback(async () => {
    try {
      const response = await fetch(BASE_URL, { headers });
      if (!response.ok) return null;

      const { data } = await response.json();
      return data;
    } catch {
      return null;
    }
  }, [headers]);
}

export default useFetchAllCarriers;
