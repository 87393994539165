import { PropTypes } from 'prop-types';
import { useCallback, useContext } from 'react';
import { CurrentOwnerContext } from 'utils/currentOwner';
import { useOwnerInvalidBolPartner } from 'components/bol/helpers';
import sidebarStyles from '../Sidebar.module.scss';
import styles from './OwnerSelection.module.scss';

function OwnerLink(props) {
  const { setCurrentOwner } = useContext(CurrentOwnerContext);
  const changeOwner = useCallback((e) => {
    e.preventDefault();
    setCurrentOwner({
      name: props.name,
      apiKey: props.apiKey,
      ownsDepot: props.ownsDepot,
      bolPartner: props.bolPartner,
      bolTokenValid: props.bolTokenValid,
    });
  }, []);

  const selected = props.selected ? styles.selected : '';
  const cssClass = `${sidebarStyles.link} ${styles.default} ${selected}`;

  return (
    <a href="/" onClick={changeOwner} className={cssClass}>
      <i className={`x-icon x-icon-cart ${sidebarStyles.icon}`} />
      {props.name}
    </a>
  );
}

OwnerLink.propTypes = {
  name: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  ownsDepot: PropTypes.bool.isRequired,
  bolPartner: PropTypes.bool.isRequired,
  bolTokenValid: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default function OwnerSelection({ owners }) {
  const { currentOwner } = useContext(CurrentOwnerContext);
  const ownerInvalidBolPartner = useOwnerInvalidBolPartner();

  let containerHeightClassname = styles.defaultHeight;
  if (ownerInvalidBolPartner) {
    containerHeightClassname = styles.emptyHeight;
  }

  // Do not show selection if there is only 1 owner, or list is unavalable.
  if (owners === null || owners === undefined || Object.keys(owners).length <= 1) {
    return <div className={`w-100 mt-2 ${styles.scrollable} ${containerHeightClassname}`} />;
  }

  const ownerNames = Object.keys(owners).sort();

  return (
    <div className={`w-100 mt-2 ${styles.scrollable}`}>
      { ownerNames.reverse().map((owner) => (
        <OwnerLink
          key={owner}
          name={owner}
          apiKey={owners[owner].apiKey}
          ownsDepot={owners[owner].ownsDepot}
          bolPartner={owners[owner].bolPartner}
          bolTokenValid={owners[owner].bolTokenValid}
          selected={owner === currentOwner.name}
        />
      ))}
    </div>
  );
}

OwnerSelection.propTypes = {
  owners: PropTypes.objectOf(PropTypes.shape({
    apiKey: PropTypes.string,
    ownsDepot: PropTypes.bool,
    bolPartner: PropTypes.bool,
    bolTokenValid: PropTypes.bool,
  })),
};
OwnerSelection.defaultProps = { owners: [] };
