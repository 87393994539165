import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import styles from './DeliveryIcon.module.scss';

export default function DeliveryIcon({ delivery }) {
  const icon = delivery.isConfirmed() ? '/icons/linear/0821-check.svg' : '/icons/opened-lock.svg';

  return (
    <div className={styles.default}>
      <img className={styles.icon} alt="icon" src={icon} />
    </div>
  );
}

DeliveryIcon.propTypes = { delivery: PropTypes.instanceOf(Delivery).isRequired };
