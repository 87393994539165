import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Details.module.scss';

function Icon({ status }) {
  let className;
  switch (status) {
    case 'draft':
      className = 'x-icon x-icon-small x-icon-sync';
      break;
    case 'processing':
      className = 'x-icon x-icon-small x-icon-sync';
      break;
    case 'processed':
      className = 'x-icon x-icon-small x-icon-file-check';
      break;
    case 'failed':
      className = 'x-icon x-icon-small x-icon-warning';
      break;
    default:
      className = null;
  }

  return (<i className={`${className} ${styles.icon}`} />);
}

Icon.propTypes = { status: PropTypes.string.isRequired };

function Container(props) {
  if (props.isClickable) {
    const reportPath = `/reports/batches?purchaseReference=${props.reference}&deliveryId=${props.deliveryId}`;
    return (
      <a href={reportPath} className={`${styles.element} ${styles.clickable}`}>
        {props.children}
      </a>
    );
  }

  return (
    <div className={styles.element}>
      {props.children}
    </div>
  );
}

Container.propTypes = {
  isClickable: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  deliveryId: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
};

export default function Details(props) {
  const activeStrikeoutStyle = useMemo(() => (props.strikeout ? styles.activeStrikeout : ''), [props.strikeout]);

  return (
    <Container isClickable={props.isClickable} deliveryId={props.deliveryId} reference={props.reference}>
      <Icon status={props.status} />
      <div className={styles.nameContainer}>
        <span className={`${styles.strikeout} ${activeStrikeoutStyle}`} />
        <span className={styles.name}>{props.reference}</span>
      </div>
    </Container>
  );
}

Details.propTypes = {
  isClickable: PropTypes.bool.isRequired,
  strikeout: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  deliveryId: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
};
