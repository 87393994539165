import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from '../Sidebar.module.scss';

export default function OwnerWithDepotMenu() {
  const { t } = useTranslation();

  return (
    <>
      <NavLink to="/batches/depot" className={styles.link}>
        <i className="x-icon x-icon-files" />
        {t('components.sidebar.batches')}
      </NavLink>
      <NavLink to="/orders" className={styles.link}>
        <i className="x-icon x-icon-cart" />
        {t('components.sidebar.orders')}
      </NavLink>
    </>
  );
}
