import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeliveryCalendar from 'components/calendar/DeliveryCalendar';
import { useCurrentYearAndMonth } from 'components/calendar/helpers';
import styles from 'components/calendar/DeliveryCalendar.module.scss';
import TimeSlotContent from './time_slot/TimeSlotContent';

export default function NewScheduleCalendar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [yearAndMonth, setYearAndMonth] = useCurrentYearAndMonth();
  const { formData, hasPalletReferences } = location.state;

  const submit = useCallback(({
    isLocked,
    date,
    time,
    cleanup,
  }) => {
    cleanup();
    if (isLocked) return;

    const newFormData = { ...formData, delivered_on: date, delivery_time: time };
    navigate('/batches/create', { state: { formData: newFormData } });
  }, [formData]);

  const renderTimeSlotContent = useCallback(({ delivery, name, dateString }) => (
    <TimeSlotContent
      delivery={delivery}
      timeSlot={name}
      dateString={dateString}
      currentBatchHasPalletReferences={hasPalletReferences}
      onClick={submit}
    />
  ), [submit, hasPalletReferences]);

  return (
    <div className={styles.calendar}>
      <DeliveryCalendar
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
        renderTimeSlotContent={renderTimeSlotContent}
      />
    </div>
  );
}
