import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Flash from 'components/Flash';
import NewOrder from 'components/orders/forms/NewOrder';
import ProcessedOrder from 'components/orders/forms/ProcessedOrder';
import { useFetchOrderDetails } from 'api/Orders';
import dateFormatDMY from 'utils/dates';
import styles from './OrderDetails.module.scss';

export default function OrderDetails(props) {
  const { t } = useTranslation();
  const [flash, setFlash] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const { fetchOrderDetails, abortFetchOrderDetails } = useFetchOrderDetails();

  const removeCurrentOrder = useCallback(() => {
    setCurrentOrder(null);
  }, []);

  useEffect(() => {
    setFlash(null);
    if (props.orderReference === null) return null;
    setCurrentOrder(null);

    const fetchOrder = async () => {
      const result = await fetchOrderDetails(props.orderReference);

      if (result.success) {
        setCurrentOrder(result.data);
      } else if (!result.aborted) {
        setFlash({
          error: t('components.orders.order_details.flash.request_error', { reference: props.orderReference }),
        });
      }
    };

    fetchOrder();

    return () => abortFetchOrderDetails();
  }, [props.orderReference]);

  let orderForm;
  switch (props.orderLineStatus) {
    case 'processed':
      orderForm = (
        <ProcessedOrder
          orderReference={props.orderReference}
          onAddTrackingInfoSuccessful={() => props.onMarkAsShippedSuccessful()}
          carriers={props.carriers}
          removeCurrentOrder={() => removeCurrentOrder()}
        />
      );
      break;
    case 'new':
      orderForm = (
        <NewOrder
          orderReference={props.orderReference}
          onMarkAsProcessedSuccessful={() => props.onMarkAsProcessedSuccessful()}
          removeCurrentOrder={() => removeCurrentOrder()}
        />
      );
      break;
    default:
      // TODO: Replace with 'shipped'-specific component once available
      break;
  }

  return (
    <div role="document" className={styles.detailsContainer}>
      { props.orderReference === null
      && (
        <div className={`text-center ${styles.noneSelected}`}>
          {t('components.orders.order_details.no_order_selected')}
        </div>
      ) }
      { flash
      && (
        <div className={styles.centerError}>
          <Flash flash={flash} />
        </div>
      ) }
      { currentOrder
      && (
        <>
          <div className={`${styles.title} mb-3`}>
            <strong>{currentOrder.attributes.title}</strong>
          </div>

          { orderForm }

          <div className={styles.list}>
            <div>{t('components.orders.order_details.sku')}</div>
            <div>{currentOrder.attributes.license_plate}</div>
          </div>
          <div className={styles.list}>
            <div>{t('components.orders.order_details.ean')}</div>
            <div>
              <a
                href={`https://www.google.com/search?q=${currentOrder.attributes.ean}`}
                target="_blank"
                rel="noreferrer"
              >
                {currentOrder.attributes.ean}
              </a>
            </div>
          </div>
          <div className={styles.list}>
            <div>{t('components.orders.order_details.owner_reference')}</div>
            <div>{currentOrder.attributes.owner_reference}</div>
          </div>
          <div className={styles.list}>
            <div>{t('components.orders.order_details.ordered_on')}</div>
            <div>{dateFormatDMY(currentOrder.attributes.ordered_on)}</div>
          </div>
          <div className={styles.list}>
            <div>{t('components.orders.order_details.retail_price')}</div>
            <div>
              {t('currency.intlCurrencyWithOptions', {
                val: currentOrder.attributes.retail_price.value,
                currency: currentOrder.attributes.retail_price.currency,
              })}
            </div>
          </div>
          <div className={styles.list}>
            <div>{t('components.orders.order_details.sale_price')}</div>
            <div>
              {t('currency.intlCurrencyWithOptions', {
                val: currentOrder.attributes.sales_price.value,
                currency: currentOrder.attributes.sales_price.currency,
              })}
            </div>
          </div>
          <div className={styles.list}>
            <div>{t('components.orders.order_details.order_reference')}</div>
            <div>{currentOrder.attributes.order_reference}</div>
          </div>

          { props.orderLineStatus === 'shipped'
          && (
            <>
              <div className={styles.list}>
                <div>{t('components.orders.order_details.tracking_code')}</div>
                <div>{currentOrder.attributes.tracking_code}</div>
              </div>
              <div className={styles.list}>
                <div>{t('components.orders.order_details.carrier')}</div>
                <div>{currentOrder.attributes.carrier_name}</div>
              </div>
            </>
          ) }

          <div className={`${styles.deliveryAddress} mt-4`}>
            {currentOrder.attributes.delivery_address.name}
            <br />
            {currentOrder.attributes.delivery_address.address}
            <br />
            {currentOrder.attributes.delivery_address.city}
            <br />
            {currentOrder.attributes.delivery_address.country}
            <br />
            {currentOrder.attributes.delivery_address.postal_code}
          </div>
        </>
      ) }
    </div>
  );
}

OrderDetails.propTypes = {
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ).isRequired,
  orderLineStatus: PropTypes.oneOf(['new', 'processed', 'shipped']),
  orderReference: PropTypes.string,
  onMarkAsProcessedSuccessful: PropTypes.func.isRequired,
  onMarkAsShippedSuccessful: PropTypes.func.isRequired,
};

OrderDetails.defaultProps = {
  orderReference: null,
  orderLineStatus: null,
};
