import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Header from 'components/Header';
import BatchesCollection from 'components/batches/depot/BatchesCollection';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import styles from './Index.module.scss';

export default function Index() {
  const { t } = useTranslation();
  // TODO: Should we reuse the existing entry for `batch` or create a new one for ownsDepot
  const navigate = useNavigate();
  const ownerOwnsDepot = useOwnerOwnsDepot();

  useEffect(() => {
    document.title = t('routes.batches.depot.index.document_title');

    if (!ownerOwnsDepot) {
      navigate('/batches');
    }
  }, [ownerOwnsDepot]);

  if (ownerOwnsDepot !== true) return '';

  return (
    <div className="index">
      <div className="d-flex mb-3">
        <Header title={t('routes.batches.depot.index.title')} />
        <Link to="/batches/depot/new" className="align-self-center d-flex ms-3">
          <i className="x-icon x-icon-small x-icon-plus" />
          {t('routes.batches.depot.index.announce_batch')}
        </Link>
        <img alt="flying drone" className={styles.illustration} src="/drone.svg" width={175} height={95} />
      </div>

      <BatchesCollection />
    </div>
  );
}
