import PropTypes from 'prop-types';
import DeliveryCalendar from 'components/calendar/DeliveryCalendar';
import { useCurrentYearAndMonth } from 'components/calendar/helpers';
import TimeSlotContent from './calendar_view/TimeSlotContent';

export default function CalendarView({ onError }) {
  const [yearAndMonth, setYearAndMonth] = useCurrentYearAndMonth();

  const renderTimeSlotContent = ({ delivery }) => {
    if (!delivery) return null;

    return (
      <TimeSlotContent delivery={delivery} />
    );
  };

  return (
    <DeliveryCalendar
      yearAndMonth={yearAndMonth}
      setYearAndMonth={setYearAndMonth}
      renderTimeSlotContent={renderTimeSlotContent}
      onError={onError}
    />
  );
}

CalendarView.propTypes = {
  onError: PropTypes.func,
};

CalendarView.defaultProps = {
  onError: () => {},
};
