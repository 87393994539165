import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import BaseDeliveryCard from 'components/calendar/day/time_slot/DeliveryCard';
import styles from 'components/calendar/DeliveryCalendar.module.scss';

export default function DeliveryCard({ delivery }) {
  return (
    <BaseDeliveryCard delivery={delivery}>
      <ul className={styles['no-bullets']}>
        {delivery.batches.slice(0, 3).map((batch) => (
          <li className={styles['card-text']} key={batch.reference}>{batch.reference}</li>
        ))}
      </ul>
    </BaseDeliveryCard>
  );
}

DeliveryCard.propTypes = {
  delivery: PropTypes.instanceOf(Delivery).isRequired,
};
