import PropTypes from 'prop-types';

export default function Table(props) {
  return (
    <table className={`table ${props.className}`}>
      {props.children}
    </table>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

Table.defaultProps = {
  className: '',
  children: [],
};
