import PropTypes from 'prop-types';
import FormElement from './FormElement';

function NumberField(props) {
  return (
    <FormElement id={props.id} label={props.label} errors={props.errors} formText={props.formText} size={props.size}>
      <input
        id={props.id}
        className="form-control"
        type="number"
        required={props.required}
        min={props.min || 1}
        max="999"
        defaultValue={props.defaultValue}
      />
    </FormElement>
  );
}

NumberField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  formText: PropTypes.any,
  size: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.number,
  defaultValue: PropTypes.number,
};

NumberField.defaultProps = {
  label: '',
  errors: {},
  formText: null,
  size: '',
  required: false,
  min: 1,
  defaultValue: null,
};

export default NumberField;
