import { useContext } from 'react';
import PropTypes from 'prop-types';
import { CurrentOwnerContext } from 'utils/currentOwner';
import styles from './OwnerName.module.scss';

export default function OwnerName(props) {
  const { currentOwner } = useContext(CurrentOwnerContext);

  return (
    <div key={currentOwner.name} className={`${styles['owner-name']}  ${props.blink ? styles.blink : ''}`}>
      {currentOwner.name}
    </div>
  );
}

OwnerName.propTypes = {
  blink: PropTypes.bool,
};

OwnerName.defaultProps = {
  blink: false,
};
