import { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAfterFadeOut from './useAfterFadeOut';
import styles from './FlashMessage.module.scss';

const FADE_DURATION = 500; // ms
const VISIBILITY_DURATION = { VALIDATING: 500, FIXED: 1000 }; // ms

function fadeOutClass(apply) {
  return apply ? styles.fadeOut : '';
}

function useDelayedEffect(fn, delay) {
  useEffect(() => setTimeout(fn, delay), []);
}

function Validating(props) {
  const { t } = useTranslation();
  const [fadeOut, setFadeOut] = useState(false);
  const { element, afterFadeOut } = useAfterFadeOut(props.onHide);

  useDelayedEffect(() => {
    if (props.fadeOut) {
      setFadeOut(true);
    } else {
      props.onHide();
    }
  }, VISIBILITY_DURATION.VALIDATING + FADE_DURATION);

  return (
    <div
      ref={element}
      className={`${styles.validating} ${fadeOutClass(fadeOut)}`}
      onTransitionEnd={afterFadeOut}
      data-testid="repair-item-validating-msg"
    >
      <i className={`x-icon x-icon-cog ${styles.cog}`} />
      <span>{t('components.repair_item.flash_message.validating')}</span>
    </div>
  );
}

Validating.propTypes = {
  fadeOut: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

function Fixed({ onHide }) {
  const { t } = useTranslation();
  useDelayedEffect(onHide, VISIBILITY_DURATION.FIXED + FADE_DURATION);

  return (
    <div className={`${styles.fixed} ${styles.fadeIn}`}>
      <i className={`x-icon x-icon-check ${styles.check}`} />
      <span>{t('components.repair_item.flash_message.fixed')}</span>
    </div>
  );
}

Fixed.propTypes = { onHide: PropTypes.func.isRequired };

export default function FlashMessage(props) {
  const [fadeOut, setFadeOut] = useState(false);
  const [showFixed, setShowFixed] = useState(false);
  const showValidating = !showFixed && !fadeOut;
  const { element, afterFadeOut } = useAfterFadeOut(props.onHide);

  const onHideValidating = useCallback(() => {
    const action = props.fixed ? setShowFixed : setFadeOut;
    action(true);
  }, [props.fixed]);

  return (
    <div
      ref={element}
      className={`${styles.default} ${styles.fadeIn} ${fadeOutClass(fadeOut)}`}
      onTransitionEnd={afterFadeOut}
      data-testid="repair-item-flash-message"
    >
      { showValidating && <Validating fadeOut={props.fixed} onHide={onHideValidating} /> }
      { showFixed && <Fixed onHide={props.onHide} /> }
    </div>
  );
}

FlashMessage.propTypes = {
  onHide: PropTypes.func.isRequired,
  fixed: PropTypes.bool.isRequired,
};
