import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import OrdersCollection from 'components/orders/OrdersCollection';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import styles from './Index.module.scss';

export default function Index() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ownerOwnsDepot = useOwnerOwnsDepot();

  document.title = t('routes.orders.index.document_title');

  useEffect(() => {
    if (!ownerOwnsDepot) { navigate('/batches'); }
  }, [ownerOwnsDepot]);

  return (
    <div className="index">
      <div className="d-flex mb-3">
        <Header title={t('routes.orders.index.title')} />
        <img
          alt="paper plane"
          title="paper plane"
          className={styles.illustration}
          src="/paper-plane.svg"
          width={175}
          height={95}
        />
      </div>

      <OrdersCollection />
    </div>
  );
}
