import PropTypes from 'prop-types';
import getOwners from 'utils/owners';
import WizardSteps from 'components/WizardSteps';
import OwnerName from 'components/OwnerName';
import HeaderComponent from 'components/Header';
import styles from './Header.module.scss';

export default function Header(props) {
  const owners = getOwners();
  const ownerCount = owners ? Object.keys(owners).length : null;

  return (
    <div className={styles.header}>
      <HeaderComponent title={props.title} />
      <WizardSteps step={props.step} withScheduleStep={false} />
      { ownerCount > 1 && <OwnerName blink={props.step === 1} /> }
    </div>
  );
}

Header.propTypes = {
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {};
