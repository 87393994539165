import PropTypes from 'prop-types';

function Icon({ status }) {
  let className;
  switch (status) {
    case 'delete':
      className = 'x-icon x-icon-small x-icon-trash me-3';
      break;
    case 'calendar':
      className = 'x-icon x-icon-small x-icon-calendar me-3';
      break;
    case 'pallet':
      className = 'x-icon x-icon-small x-icon-pallet me-3';
      break;
    default:
      className = null;
  }

  return (<i className={`${className}`} />);
}

Icon.propTypes = { status: PropTypes.string.isRequired };

export default function Option(props) {
  return (
    <li>
      <button type="button" className={`dropdown-item ${props.disabled ? 'disabled' : ''}`} onClick={props.onClick}>
        <Icon status={props.icon} />
        {props.label}
      </button>
    </li>
  );
}

Option.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
Option.defaultProps = {
  disabled: false,
};
