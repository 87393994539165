import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchAllBatches } from 'api/depot/Batches';
import Flash from 'components/Flash';
import Table from 'components/Table';
import styles from './BatchesCollection.module.scss';

function createdDate(date) {
  const strDate = new Date(date);
  return `${strDate.getDate()}-${strDate.getMonth() + 1}-${strDate.getFullYear().toString().substring(2)}`;
}

export default function BatchesCollection() {
  const { t } = useTranslation();
  const location = useLocation();
  const [flash, setFlash] = useState(location.state?.flash);
  const [batches, setBatches] = useState([]);
  const fetchBatches = useFetchAllBatches();

  useEffect(() => {
    setBatches([]);

    (async () => {
      const result = await fetchBatches();

      if (Array.isArray(result)) {
        setBatches(result);
      } else {
        setFlash({ error: t('components.batches.depot.batches_collection.flash.request_error') });
      }
    })();
  }, [fetchBatches]);

  return (
    <div>
      <Flash flash={flash} />

      {batches.length > 0
        && (
          <Table className={`table ${styles['batches-table']}`}>
            <tbody>
              { batches.map((batch) => (
                <tr key={batch.id}>
                  <td>{batch.attributes.reference}</td>
                  <td>
                    {t('components.batches.depot.batches_collection.itemWithCount', {
                      count: batch.attributes.no_of_items,
                    })}
                  </td>
                  <td>{createdDate(batch.attributes.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
    </div>
  );
}
