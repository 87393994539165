import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'bootstrap/js/dist/tooltip';

function useLockExplanation({ delivery, currentBatchHasPalletReferences }) {
  const { t } = useTranslation();
  if (!delivery || !delivery.hasBatches()) return null;

  const isDeliveryCompatibleWithCurrentBatch = delivery.hasPalletReferences() === currentBatchHasPalletReferences;
  if (isDeliveryCompatibleWithCurrentBatch) return null;

  const translationKey = currentBatchHasPalletReferences ? 'has_pallet_references' : 'has_no_pallet_references';
  return t(`components.time_slot.lock_explanation.${translationKey}`);
}

// TODO: Remove after adding more functions
/* eslint-disable-next-line  import/prefer-default-export */
export function useLockDetails({ delivery, currentBatchHasPalletReferences }) {
  const explanationTooltipTarget = useRef(null);
  const explanation = useLockExplanation({ delivery, currentBatchHasPalletReferences });
  const isLocked = (() => {
    if (!delivery || !delivery.hasBatches()) return false;
    return delivery.isConfirmed() || explanation !== null;
  })();

  // Add tooltip to target element.
  useEffect(() => {
    if (!isLocked || !explanation) return;

    // eslint-disable-next-line no-new
    new Tooltip(explanationTooltipTarget.current, { title: explanation, customClass: 'tooltip-warning' });
  }, [explanationTooltipTarget, isLocked, explanation]);

  return { isLocked, explanationTooltipTarget };
}
