import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFetchAllDeliveries } from 'api/Deliveries';
import DeliveryBoard from './list_view/DeliveryBoard';
import styles from './ListView.module.scss';

export default function ListView({ onError }) {
  const [deliveries, setDeliveries] = useState([]);
  const fetchDeliveries = useFetchAllDeliveries();

  useEffect(() => {
    setDeliveries([]);

    (async () => {
      const result = await fetchDeliveries({ scope: 'active' });

      if (Array.isArray(result)) {
        setDeliveries(result);
      } else {
        onError();
      }
    })();
  }, [fetchDeliveries]);

  if (deliveries.length === 0) return null;

  return (
    <div className={styles.default}>
      { deliveries.map((delivery) => (
        <DeliveryBoard
          key={`${delivery.reference}-${delivery.delivery_date}-${delivery.delivery_time}`}
          id={delivery.id}
          deliveryDate={delivery.delivery_date}
          deliveryTime={delivery.delivery_time}
          deliveryConfirmed={delivery.delivery_confirmed}
          palletCount={delivery.pallet_count}
          locked={delivery.locked}
          lockedOn={delivery.locked_on}
          batches={delivery.batches}
        />
      ))}
    </div>
  );
}

ListView.propTypes = {
  onError: PropTypes.func,
};

ListView.defaultProps = {
  onError: () => {},
};
