import {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import PropTypes from 'prop-types';
import FitToView from './layouts/FitToView';
import {
  buildEventHandlers,
  fetchReportDetails,
  useResponsiveLayout,
  useReportConfig,
} from '../utils/powerBi';
import styles from './PowerBiTable.module.scss';

export default function PowerBiTable(props) {
  const { config, setConfig } = useReportConfig({ filters: props.filters, pageName: props.pageName });
  const [reportDetails, setReportDetails] = useState(null);
  const [containerSize, setContainerSize] = useState(null);

  // Clear states on new props.
  useEffect(() => {
    setConfig(null);
    setReportDetails(null);
  }, [props.filters, props.pageName]);

  useResponsiveLayout({ reportDetails, containerSize });

  const onLoad = useCallback(async (_event, report) => {
    const details = await fetchReportDetails({ report, pageName: props.pageName });
    setReportDetails(details);
  }, [props]);

  const events = useMemo(() => buildEventHandlers({
    onLoad,
    onHyperlinkClicked: props.onHyperlinkClicked,
  }), [onLoad, props.onHyperlinkClicked]);

  if (!config) return null;

  return (
    <FitToView minWidth={900} maxWidth={1326} marginBottom={20} onUpdate={setContainerSize}>
      <PowerBIEmbed
        embedConfig={config}
        cssClassName={styles.iframeContainer}
        eventHandlers={events}
      />
    </FitToView>
  );
}

PowerBiTable.propTypes = {
  pageName: PropTypes.string.isRequired,
  filters: PropTypes.shape().isRequired,
  onHyperlinkClicked: PropTypes.func,
};

PowerBiTable.defaultProps = {
  onHyperlinkClicked: null,
};
