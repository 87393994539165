import { useContext, useMemo } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { CurrentOwnerContext } from './currentOwner';

export function getOriginalRequestUrl() {
  const originalRequest = localStorage.getItem('original_request');
  if (originalRequest === undefined || originalRequest === null) {
    return '/batches';
  }

  return originalRequest;
}

export function useVerdictApiKey() {
  const { currentOwner } = useContext(CurrentOwnerContext);
  const isAuthenticated = useIsAuthenticated();

  const apiKey = useMemo(() => (
    (isAuthenticated && currentOwner) ? currentOwner.apiKey : null
  ), [currentOwner, isAuthenticated]);

  return apiKey;
}

export function useVerdictRequestHeaders() {
  const apiKey = useVerdictApiKey();
  const headers = useMemo(() => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${apiKey}`,
  }), [apiKey]);

  return headers;
}

export function useFluxRequestHeaders() {
  const apiKey = useVerdictApiKey();
  const headers = useMemo(() => ({
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.flux.v1',
    Authorization: apiKey,
  }), [apiKey]);

  return headers;
}
