import { useCallback, useEffect } from 'react';
import { useFluxRequestHeaders } from 'utils/sessionHelpers';

window.globals ||= {};
window.globals.depotBatchesCache ||= {};

const API_URL = window.env.REACT_APP_BASE_FLUX_API_URL;
const BASE_URL = new URL('stock_batches', API_URL);
const REFERENCE_AVAILABILITY_URL = new URL('stock_batches/reference_availability', API_URL);

export function useFetchAllBatches() {
  const headers = useFluxRequestHeaders();

  // Clear cache on headers update.
  useEffect(() => { window.globals.depotBatchesCache = {}; }, [headers]);

  return useCallback(async () => {
    try {
      const response = await fetch(BASE_URL, { headers });
      if (!response.ok) return null;

      const { data } = await response.json();
      window.globals.depotBatchesCache.all = data;
      return data;
    } catch {
      return null;
    }
  }, [headers]);
}

export function useCreateBatch() {
  const headers = useFluxRequestHeaders();

  return useCallback(async (data = {}) => {
    let response = {};
    try {
      response = await fetch(BASE_URL, {
        headers,
        method: 'POST',
        body: JSON.stringify(data),
      });

      const json = await response.json();
      return { success: response.ok, statusText: response.statusText, json };
    } catch (error) {
      return { success: false, statusText: response.statusText, json: null };
    }
  }, [headers]);
}

export function useCheckReferenceAvailability() {
  const headers = useFluxRequestHeaders();

  return useCallback(async (reference) => {
    let response = {};

    try {
      REFERENCE_AVAILABILITY_URL.search = `reference=${reference}`;
      response = await fetch(REFERENCE_AVAILABILITY_URL, { headers });
      const json = await response.json();

      if (response.ok) {
        return { success: true, statusText: response.statusText, json };
      }

      return { success: false, statusText: response.statusText, errors: json };
    } catch (error) {
      return { success: false, statusText: response.statusText, error };
    }
  }, [headers]);
}
