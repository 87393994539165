import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/form/Button';
import styles from './ReportButtonGroup.module.scss';

export default function ReportButtonGroup(props) {
  const { t } = useTranslation();

  return (
    <div className="d-flex">
      <div className="pt-2 me-2">Group by</div>
      <div className={`input-group mb-3 ${styles.inputGroup}`}>
        <Button
          className={`btn ${props.activeReport === 'batch' ? 'btn-primary' : 'btn-outline'}`}
          label={t('components.sales.report_button_group.batch')}
          onClick={() => props.setActiveReport('batch')}
        />
        <Button
          className={`btn ${props.activeReport === 'monthly' ? 'btn-primary' : 'btn-outline'}`}
          label={t('components.sales.report_button_group.month')}
          onClick={() => props.setActiveReport('monthly')}
        />
      </div>
    </div>
  );
}

ReportButtonGroup.propTypes = {
  activeReport: PropTypes.string.isRequired,
  setActiveReport: PropTypes.func.isRequired,
};
