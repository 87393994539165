import PropTypes from 'prop-types';
import classes from './Button.module.scss';

export default function Button(props) {
  const iconClassName = props.className.indexOf('outline') >= 0 ? `${classes.icon} ${classes.outline}` : classes.icon;
  return (
    <button
      disabled={props.disabled}
      type="button"
      id={props.id}
      className={`${classes.button} ${props.className}`}
      onClick={props.onClick}
      data-e2e={props.e2eData}
    >
      {props.icon ? <img alt="" src={`/icons/${props.icon}`} className={iconClassName} /> : ''}
      {props.label}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  e2eData: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  e2eData: '',
  id: '',
  icon: '',
  disabled: false,
};
