import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from 'components/deliveries/deliveries_collection/list_view/DeliveryBoard.module.scss';
import Batch from './Batch';

const MIN_VISIBLE_BATCH_COUNT = 3;
const MIN_BATCH_COUNT_TO_HIDE_OTHERS = 5;

export default function BatchList(props) {
  const { t } = useTranslation();
  const [visibleBatchCount, setVisibleBatchCount] = useState(MIN_VISIBLE_BATCH_COUNT);
  const visibleBatches = props.batches.slice(0, visibleBatchCount);
  const hiddenBatchCount = props.batches.length - visibleBatchCount;
  const isMoreLinkVisible = props.batches.length >= MIN_BATCH_COUNT_TO_HIDE_OTHERS;
  const moreLabel = t('components.deliveries.batch_list.more', { count: hiddenBatchCount });

  function showAllBatches() {
    setVisibleBatchCount(props.batches.length);
  }

  useEffect(() => {
    if (!isMoreLinkVisible) showAllBatches();
  }, [isMoreLinkVisible]);

  return (
    <ul className="list-group list-group-flush">
      { visibleBatches.map((batch) => (
        <Batch
          key={batch.reference}
          deliveryId={props.deliveryId}
          deliveredOn={props.deliveryDate}
          deliveryTime={props.deliveryTime}
          deliveryStatus={props.deliveryStatus}
          reference={batch.reference}
          status={batch.status}
          palletCount={batch.pallet_count}
          itemCount={batch.items}
          style={props.batchStyle}
          locked={props.locked}
        />
      ))}
      { hiddenBatchCount > 0
        && (
          <li className={`list-group-item px-0 ${styles.moreBatch}`}>
            <div>
              <a href="#more" className={styles.moreLink} onClick={showAllBatches}>{moreLabel}</a>
            </div>
          </li>
        )}
    </ul>
  );
}

BatchList.propTypes = {
  deliveryId: PropTypes.number.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string,
  deliveryStatus: PropTypes.oneOf(['confirmed', 'locked', 'unlocked']).isRequired,
  locked: PropTypes.bool.isRequired,
  batchStyle: PropTypes.objectOf(PropTypes.string),
  batches: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      status: PropTypes.string,
      pallet_count: PropTypes.number,
      items: PropTypes.number,
    })
  ).isRequired,
};

BatchList.defaultProps = {
  deliveryTime: 'morning',
  batchStyle: null,
};
