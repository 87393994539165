import { useCallback, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import ASNExcelSheet from 'classes/asn_excel_sheet';
// import { snakeCase } from 'snake-case';

const REFERENCE_FIELD_CHANGE_BROADCAST_DELAY = 500;

export function useBroadcastReferenceFieldChange(onReferenceFieldChange) {
  return useMemo(() => (
    debounce((e) => {
      if (onReferenceFieldChange) {
        const reference = e.target.value.trim();
        onReferenceFieldChange(e, reference);
      }
    }, REFERENCE_FIELD_CHANGE_BROADCAST_DELAY)
  ), [onReferenceFieldChange]);
}

export function useOnSubmit({
  onFormRead,
  canSubmit,
  announcements,
  withDepot,
}) {
  return useCallback((event) => {
    event.preventDefault();
    if (!canSubmit) return;

    const formData = {
      reference: document.getElementById('reference').value,
      items: announcements,
    };

    if (!withDepot) {
      formData.pallet_count = parseInt(document.getElementById('pallet_count').value) || null;
    }

    onFormRead(formData);
  }, [onFormRead, canSubmit, announcements]);
}

async function buildAnnouncementsFromExcel({ file, t, withDepot }) {
  const excel = new ASNExcelSheet({ file, translateMethod: t, withDepot });

  try {
    const announcements = await excel.read();
    return { success: true, announcements };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export function useOnFileChange({
  onBeforeReadFile,
  onAfterReadFile,
  setAnnouncements,
  withDepot,
}) {
  const { t } = useTranslation();

  return useCallback(async (file) => {
    if (onBeforeReadFile) onBeforeReadFile();

    const result = await buildAnnouncementsFromExcel({ file, t, withDepot });
    setAnnouncements(result.announcements);

    if (!onAfterReadFile) return;

    onAfterReadFile({
      success: result.success,
      announcements: result.announcements,
      error: result.error || null,
    });
  }, [onBeforeReadFile, onAfterReadFile]);
}

export function usePostToAPI({
  onSuccessRedirect,
  onRequestFailure,
  createBatch,
  hashFormData,
  setError,
}) {
  const { t } = useTranslation();

  return useCallback(async () => {
    const result = await createBatch(hashFormData);

    if (result.success) {
      onSuccessRedirect();
    } else if (result.json) {
      onRequestFailure(result);
    } else {
      setError(t('components.server_error.request_error'));
    }
  }, [hashFormData, createBatch]);
}
