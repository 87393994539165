import {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrentOwnerContext } from 'utils/currentOwner';
import PowerBiTable from 'components/PowerBiTable';
import ReportButtonGroup from 'components/sales/ReportButtonGroup';
import Header from 'components/Header';
import styles from './Index.module.scss';

const OWNER_NAME_FIELD = 'Items/Owner';
const PAGE_NAMES = {
  batch: window.env.REACT_APP_POWER_BI_SALES_BATCH_REPORT_PAGE_NAME,
  monthly: window.env.REACT_APP_POWER_BI_SALES_MONTHLY_REPORT_PAGE_NAME,
};

const useOnPurchaseRefClick = () => {
  const navigate = useNavigate();

  return useCallback((event) => {
    const { detail: { url } } = event;

    const powerBiURL = new URL(url);
    const salesDetailsParams = new URLSearchParams();

    const purchaseReference = powerBiURL.searchParams.get('original_purchase_reference');
    const monthYear = powerBiURL.searchParams.get('year_month_name');

    if (purchaseReference) {
      salesDetailsParams.append('original_purchase_reference', purchaseReference);
    } else if (monthYear) {
      salesDetailsParams.append('date', monthYear);
    }

    navigate(`/reports/sales/details?${salesDetailsParams.toString()}`);
  }, []);
};

const useFilters = () => {
  const { currentOwner } = useContext(CurrentOwnerContext);
  const filters = useMemo(() => ({ [OWNER_NAME_FIELD]: currentOwner.name }), [currentOwner]);
  return filters;
};

export default function Index() {
  const { t } = useTranslation();
  const [activeReport, setActiveReport] = useState('batch');
  const filters = useFilters();
  const onPurchaseRefClick = useOnPurchaseRefClick();
  const pageName = PAGE_NAMES[activeReport];

  document.title = t('routes.reports.sales.default.index.document_title');

  return (
    <div className={styles.default}>
      <Header className="pb-1" title={t('routes.reports.sales.default.index.header')} />
      <ReportButtonGroup activeReport={activeReport} setActiveReport={setActiveReport} />
      <PowerBiTable
        pageName={pageName}
        filters={filters}
        onHyperlinkClicked={onPurchaseRefClick}
      />
    </div>
  );
}
