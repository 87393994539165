import { useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import en from 'dayjs/locale/en';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.locale({
  ...en,
  weekStart: 1,
});

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export function useCurrentYearAndMonth() {
  return useState(() => {
    const now = new Date();

    // In JS, January is 0, February is 1, and so on...
    // We + 1 to month so that we can get the actual month value.
    return [now.getFullYear(), now.getMonth() + 1];
  });
}

export function getNumberOfDaysInMonth(year, month) {
  return dayjs(`${year}-${month}-01`).daysInMonth();
}

function createDaysForCurrentMonth(year, month) {
  return [...Array(getNumberOfDaysInMonth(year, month))].map((_, index) => ({
    dateString: dayjs(`${year}-${month}-${index + 1}`).format('YYYY-MM-DD'),
    dayOfMonth: index + 1,
    isCurrentMonth: true,
  }));
}

// saturday === 5, sunday === 6
export function getWeekday(dateString) {
  return dayjs(dateString).weekday();
}

export function isWeekendDay(dateString) {
  return [5, 6].includes(getWeekday(dateString));
}

function createDaysForPreviousMonth(year, month, currentMonthDays) {
  const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].dateString);
  const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month');
  const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;
  const previousMonthLastMondayDayOfMonth = dayjs(
    currentMonthDays[0].dateString
  ).subtract(visibleNumberOfDaysFromPreviousMonth, 'day').date();

  return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => ({
    dateString: dayjs(
      `${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`
    ).format('YYYY-MM-DD'),
    dayOfMonth: previousMonthLastMondayDayOfMonth + index,
    isCurrentMonth: false,
    isPreviousMonth: true,
  }));
}

function createDaysForNextMonth(year, month, currentMonthDays) {
  const lastDayOfTheMonthWeekday = getWeekday(
    `${year}-${month}-${currentMonthDays.length}`
  );
  const nextMonth = dayjs(`${year}-${month}-01`).add(1, 'month');
  const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday;
  return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => ({
    dateString: dayjs(
      `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
    ).format('YYYY-MM-DD'),
    dayOfMonth: index + 1,
    isCurrentMonth: false,
    isNextMonth: true,
  }));
}

export function useCalendarGridDayObjects({ year, month }) {
  return useMemo(() => {
    const currentMonthDays = createDaysForCurrentMonth(year, month);
    const previousMonthDays = createDaysForPreviousMonth(
      year,
      month,
      currentMonthDays
    );
    const nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);

    return ([
      ...previousMonthDays,
      ...currentMonthDays,
      ...nextMonthDays,
    ]);
  }, [year, month]);
}

export function useFindDeliveriesByDate(deliveries) {
  return useMemo(() => {
    const lookup = {};
    deliveries.forEach((delivery) => {
      const deliveryDate = delivery.date;
      if (!deliveryDate) return;

      lookup[deliveryDate] ||= [];
      lookup[deliveryDate].push(delivery);
    });

    return (date) => lookup[date];
  }, [deliveries]);
}

export function useMoveToNextMonth({ yearAndMonth, setYearAndMonth }) {
  const [currentYear, currentMonth] = yearAndMonth;

  return useCallback(() => {
    let nextYear = currentYear;
    let nextMonth = currentMonth + 1;

    // If exceeds December then go to January next year.
    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear++;
    }

    setYearAndMonth([nextYear, nextMonth]);
  }, [currentYear, currentMonth, setYearAndMonth]);
}

export function useMoveToPreviousMonth({ yearAndMonth, setYearAndMonth }) {
  const [currentYear, currentMonth] = yearAndMonth;

  return useCallback(() => {
    let prevYear = currentYear;
    let prevMonth = currentMonth - 1;

    // If before January, then go to December last year.
    if (prevMonth < 1) {
      prevMonth = 12;
      prevYear--;
    }

    setYearAndMonth([prevYear, prevMonth]);
  }, [currentYear, currentMonth, setYearAndMonth]);
}
