import { useTranslation } from 'react-i18next';
import helpUrls from 'config/help.json';

export default function useHelpUrl(helpKey) {
  const { i18n } = useTranslation();
  const url = helpUrls[helpKey];
  const { language } = i18n;
  if (url === undefined) return null;

  return url.replace('<language>', language);
}
