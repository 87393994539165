import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import Unauthorized from 'components/session/Unauthorized';

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <>
      <AuthenticatedTemplate>
        <div>
          <h1>{t('routes.not_found.title')}</h1>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Unauthorized path={window.location.pathname} />
      </UnauthenticatedTemplate>
    </>
  );
}
