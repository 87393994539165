import PropTypes from 'prop-types';
import getOwners from 'utils/owners';
import WizardSteps from './WizardSteps';
import OwnerName from './OwnerName';
import Header from './Header';
import styles from './AnnouncementHeader.module.scss';

export default function AnnouncementHeader(props) {
  const owners = getOwners();
  const ownerCount = owners ? Object.keys(owners).length : null;

  return (
    <div className={styles.header}>
      <Header title={props.title} />
      <WizardSteps step={props.step} />
      { ownerCount > 1 && <OwnerName blink={props.step === 1} /> }
    </div>
  );
}

AnnouncementHeader.propTypes = {
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

AnnouncementHeader.defaultProps = {};
