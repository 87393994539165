import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomCurrentOwnerSetter } from 'utils/currentOwner';

export default function useConfirmChangeOwner() {
  const { t } = useTranslation();
  const confirmChangeOwner = useCallback(({ newOwner, prevOwner, originalSetter }) => {
    const newOwnerName = newOwner.name;
    const prevOwnerName = prevOwner.name;
    const proceed = window.confirm(t('utils.useConfirmChangeOwner.message', { newOwnerName, prevOwnerName }));

    if (proceed) originalSetter(newOwner);
  }, []);

  useCustomCurrentOwnerSetter(confirmChangeOwner);
}
