import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useShipOrder } from 'api/Orders';
import styles from './Form.module.scss';

export default function ProcessedOrder(props) {
  const { t } = useTranslation();
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [trackingCode, setTrackingCode] = useState('');
  const { shipOrder, abortShipOrder } = useShipOrder();
  const inputRef = useRef();
  const selectRef = useRef();

  function addTrackingInfo() {
    const payload = {
      data: {
        type: 'orders',
        attributes: {
          order_reference: props.orderReference,
          carrier_code: selectRef.current.value,
          tracking_code: trackingCode,
        },
      },
    };

    const shipOrderRequest = async () => {
      const result = await shipOrder(payload);
      setConfirmationInProgress(false);

      if (result.success) {
        props.onAddTrackingInfoSuccessful();
        props.removeCurrentOrder();
      } else if (!result.aborted) {
        setRequestError(true);
      }
    };

    setConfirmationInProgress(true);
    setRequestError(false);
    shipOrderRequest();

    return () => abortShipOrder();
  }

  return (
    <div className="text-left mb-3 d-grid d-xl-block">
      <div className="form-group">
        <label htmlFor="trackingCode">{t('components.orders.order_details.tracking_code')}</label>
        <input
          ref={inputRef}
          type="text"
          id="trackingCode"
          name="trackingCode"
          className="form-control"
          required
          onChange={(el) => setTrackingCode(el.currentTarget.value)}
          aria-describedby="trackingCode"
        />
      </div>
      <div className="form-group">
        <label htmlFor="carriers">{t('components.orders.order_details.carrier')}</label>
        <select ref={selectRef} className="form-control" id="carriers">
          { props.carriers.map((carrier) => (
            <option key={carrier.code} value={carrier.code}>{carrier.name}</option>
          ))}
        </select>
      </div>
      <button
        onClick={() => addTrackingInfo()}
        disabled={(confirmationInProgress || !trackingCode) ? 'disabled' : ''}
        className="btn btn-primary my-3"
        type="button"
      >
        { t('components.orders.order_details.add_tracking_info') }
        { confirmationInProgress ? <span className={styles.loading} /> : ''}
        { requestError ? <i className={`x-icon x-icon-warning ${styles.requestError}`} /> : '' }
      </button>
    </div>
  );
}

ProcessedOrder.propTypes = {
  orderReference: PropTypes.string.isRequired,
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ).isRequired,
  onAddTrackingInfoSuccessful: PropTypes.func.isRequired,
  removeCurrentOrder: PropTypes.func.isRequired,
};
