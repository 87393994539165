import { PropTypes } from 'prop-types';
import { useCallback } from 'react';
import SignOutButton from 'components/session/SignOutButton';
import { useOwnerInvalidBolPartner } from 'components/bol/helpers';
import getOwners from 'utils/owners';
import useHelpUrl from 'utils/help';
import Navigation from './sidebar/Navigation';
import OwnerSelection from './sidebar/OwnerSelection';
import styles from './Sidebar.module.scss';

function Items() {
  const ownerInvalidBolPartner = useOwnerInvalidBolPartner();
  const helpUrl = useHelpUrl('index');
  let navigation = null;

  if (ownerInvalidBolPartner) {
    navigation = '';
  } else {
    navigation = <Navigation helpUrl={helpUrl} />;
  }

  return (
    <div className={styles.items}>
      {navigation}
      <OwnerSelection owners={getOwners()} />
      <div className={styles.divider} />
      <div className="w-100 d-flex flex-column">
        <SignOutButton className={styles.link} />
      </div>
    </div>
  );
}

export default function Sidebar(props) {
  const cssClass = props.isCollapsed ? styles.collapsed : styles.expanded;
  const toggle = useCallback((e) => {
    e.preventDefault();
    props.toggle();
  }, [props.toggle]);

  return (
    <div className={`${styles.sidebar} ${cssClass}`}>
      <a href="/" className={styles.menuButton} onClick={toggle} aria-label="Toggle navigation">
        <i className="x-icon x-icon-hamburger" />
      </a>

      { !props.isCollapsed && <Items /> }
    </div>
  );
}

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
