import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BatchReport from 'components/batches/default/Report';
import ReportHeader from 'components/batches/default/ReportHeader';
import Delivery from 'classes/delivery';
import { useFindDelivery } from 'api/Deliveries';
import { useRedirectOnChangeOwner } from 'utils/currentOwner';
import styles from './Report.module.scss';

export default function Report() {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const purchaseReference = params.get('purchaseReference');
  const deliveryId = params.get('deliveryId');
  const findDelivery = useFindDelivery();

  document.title = t('routes.reports.batches.default.document_title');

  useRedirectOnChangeOwner('/batches');

  useEffect(() => {
    const fetchDeliveryDetails = async () => {
      try {
        const deliveryData = await findDelivery(deliveryId);
        if (!deliveryData) return;
        const delivery = new Delivery(deliveryData);

        setDeliveryDetails({
          isLocked: delivery.isLocked(),
          reference: delivery.reference,
          date: delivery.formattedDeliveryDate(),
          time: delivery.formattedDeliveryTime(),
        });
      } catch (error) {
        console.error(error);
        setDeliveryDetails(null);
      }
    };

    fetchDeliveryDetails();
  }, [findDelivery]);

  if (!deliveryDetails) return null;

  return (
    <div className={styles.batches}>
      <ReportHeader
        reference={purchaseReference}
        deliveryDate={deliveryDetails.date}
        deliveryTime={deliveryDetails.time}
        isLocked={deliveryDetails.isLocked}
      />
      <BatchReport deliveryReference={deliveryDetails.reference} purchaseReference={purchaseReference} />
    </div>
  );
}
