import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDeleteBatch } from 'api/Batches';
import Option from './Option';

const DELETE_ANIMATION_DURATION = 500; // 0.5 second

export default function DeleteOption(props) {
  const { t } = useTranslation();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const deleteBatch = useDeleteBatch(props.reference);

  // eslint-disable-next-line no-alert
  useEffect(() => { if (alertError) alert(alertError); }, [alertError]);

  const deleteBatchRequest = async () => {
    setAlertError(null);
    setRequestInProgress(true);
    props.onBeforeAjaxRequest();
    props.onDeleteStart();

    try {
      const result = await deleteBatch();

      if (result.success) {
        props.onDeleteSuccess();
      } else {
        setTimeout(() => {
          const error = { errorMessage: `${result.statusText} - ${result.errors?.details[0] || result.error}` };
          setAlertError(t('routes.batches.default.flash.error_with_message', error));
          props.onDeleteFailed();
        }, DELETE_ANIMATION_DURATION);
      }
    } finally {
      props.onAfterAjaxRequest();
      setRequestInProgress(false);
    }
  };

  const confirmDelete = useCallback(() => {
    // eslint-disable-next-line no-alert
    const res = window.confirm(t('components.deliveries.batch.options.confirm_deletion', { count: props.itemCount }));

    if (res) deleteBatchRequest();
  }, [props.itemCount]);

  return (
    <Option
      onClick={confirmDelete}
      disabled={requestInProgress}
      label={t('components.deliveries.batch.options.delete')}
      icon="delete"
    />
  );
}

DeleteOption.propTypes = {
  itemCount: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  onDeleteStart: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  onDeleteFailed: PropTypes.func.isRequired,
  onBeforeAjaxRequest: PropTypes.func.isRequired,
  onAfterAjaxRequest: PropTypes.func.isRequired,
};
