import PropTypes from 'prop-types';
import FormElement from './FormElement';

export default function TextField(props) {
  return (
    <FormElement id={props.id} label={props.label} errors={props.errors} formText={props.formText} size={props.size}>
      <input
        id={props.id}
        className="form-control"
        type="text"
        required={props.required}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </FormElement>
  );
}

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  formText: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  id: '',
  label: '',
  errors: {},
  formText: '',
  size: '',
  required: false,
  defaultValue: '',
  onChange: null,
};
