import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import FitToView from 'components/layouts/FitToView';
import { useFetchAllDeliveries } from 'api/Deliveries';
import Delivery from 'classes/delivery';
import {
  daysOfWeek,
  useCalendarGridDayObjects,
  useMoveToNextMonth,
  useMoveToPreviousMonth,
  useFindDeliveriesByDate,
} from './helpers';
import Day from './Day';
import styles from './DeliveryCalendar.module.scss';

function useLoadDeliveriesOnFirstRender({ dateRange, onError }) {
  const [deliveries, setDeliveries] = useState([]);
  const fetchDeliveries = useFetchAllDeliveries();

  useEffect(async () => {
    const result = await fetchDeliveries({ start_date: dateRange.from, end_date: dateRange.to });
    if (result) {
      const models = result.map((json) => new Delivery(json));
      setDeliveries(models);
    } else {
      onError();
    }
  }, [setDeliveries, fetchDeliveries, dateRange.from, dateRange.to]);

  return deliveries;
}

function DeliveryCalendar({
  yearAndMonth,
  setYearAndMonth,
  renderTimeSlotContent,
  onError,
}) {
  const [year, month] = yearAndMonth;
  const calendarGridDayObjects = useCalendarGridDayObjects({ year, month });
  const onForward = useMoveToNextMonth({ yearAndMonth, setYearAndMonth });
  const onBack = useMoveToPreviousMonth({ yearAndMonth, setYearAndMonth });

  const dateRange = {
    from: calendarGridDayObjects[0]?.dateString,
    to: calendarGridDayObjects[calendarGridDayObjects.length - 1]?.dateString,
  };
  const deliveries = useLoadDeliveriesOnFirstRender({ dateRange, onError });
  const findDeliveriesByDate = useFindDeliveriesByDate(deliveries);

  return (
    <div className={styles['calendar-root']}>
      <div className={styles['navigation-header']}>
        <div className={styles['navigation-month-year']}>
          {dayjs(`${year}-${month}-${1}`).format('MMMM YYYY')}
        </div>
        <div className="column">
          <div className={styles['month-nav-arrow-buttons']}>
            <div onClick={onBack} aria-hidden="true" className={`me-1 ${styles['navigation-button-container']}`}>
              <i title="previous" className={`x-icon x-icon-chevron-left ${styles['navigation-button']}`} />
            </div>
            <div onClick={onForward} aria-hidden="true" className={styles['navigation-button-container']}>
              <i title="next" className={`x-icon x-icon-chevron-right ${styles['navigation-button']}`} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['days-of-week']}>
        {daysOfWeek.map((day) => (
          <div key={day} className={styles['day-of-week-header-cell']}>
            {day.substring(0, 2)}
          </div>
        ))}
      </div>
      <FitToView marginBottom={20}>
        <div className={styles['days-grid']}>
          {calendarGridDayObjects.map((day) => (
            <Day
              key={day.dateString}
              calendarDayObject={day}
              deliveries={findDeliveriesByDate(day.dateString)}
              renderTimeSlotContent={renderTimeSlotContent}
            />
          ))}
        </div>
      </FitToView>
    </div>
  );
}

DeliveryCalendar.propTypes = {
  yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // e.g. [2021, 6] for June 2021
  setYearAndMonth: PropTypes.func.isRequired,
  renderTimeSlotContent: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

DeliveryCalendar.defaultProps = {
  onError: () => {},
};

export default DeliveryCalendar;
