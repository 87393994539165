import 'utils/env';

// Stylesheets
import 'bootstrap';

// React imports
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

import App from './App';
import msalInstance from './utils/msalInstance';

const honeybadgerConfig = {
  apiKey: window.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: window.env.REACT_APP_HONEYBADGER_ENVIRONMENT,
  developmentEnvironments: ['development', 'dev'],
};
const honeybadger = Honeybadger.configure(honeybadgerConfig);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        <App pca={msalInstance} />
      </HoneybadgerErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
