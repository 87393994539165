import { useTranslation } from 'react-i18next';
import NewScheduleCalendar from 'components/announcements/calendars/NewScheduleCalendar';
import AnnouncementHeader from 'components/AnnouncementHeader';
import useConfirmChangeOwner from '../useConfirmChangeOwner';

export default function Schedule() {
  const { t } = useTranslation();

  useConfirmChangeOwner();

  document.title = t('routes.batches.default.schedule.document_title');

  return (
    <>
      <AnnouncementHeader step={3} title={t('routes.batches.default.schedule.title')} />
      <NewScheduleCalendar />
    </>
  );
}
