import { useEffect, useState } from 'react';
import { models } from 'powerbi-client';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import msalInstance from './msalInstance';

// The computed PowerBI page height has a slight inaccuracy, thus it is resulting to a
// 2nd vertical scrollbar in the iframe.
//
// We will apply this factor to the final computed page height as a way to correct the value.
//
// TODO: Investigate if we have something wrong in our formulas, so we can remove this correction factor.
const HEIGHT_CORRECTION_FACTOR = 0.99;
const REPORT_ID = window.env.REACT_APP_POWER_BI_REPORT_ID;
const REPORT_WITH_DEPOT_ID = window.env.REACT_APP_POWER_BI_REPORT_WITH_DEPOT_ID;
const EMBED_BASE_URL = window.env.REACT_APP_POWER_BI_EMBED_BASE_URL;

const fetchAccessToken = async () => {
  const account = msalInstance.getActiveAccount();
  const scopes = ['https://analysis.windows.net/powerbi/api/Report.Read.All',
    'https://analysis.windows.net/powerbi/api/Dataset.Read.All'];
  let response = null;

  try {
    response = await msalInstance.acquireTokenSilent({ scopes, account });
  } catch {
    response = await msalInstance.acquireTokenPopup({ scopes, account });
  }

  return response.accessToken;
};

export default fetchAccessToken;

// Will compute the new report page size and table size based on the iframe's container size.
// - originalReportSize : { page: { width, height }, table: { width, height } }
// - containerSize : { width, height }
function computeReportSize({ originalReportSize, containerSize }) {
  const scaledPage = {};
  scaledPage.width = containerSize.width;
  scaledPage.zoom = scaledPage.width / originalReportSize.page.width;
  scaledPage.height = originalReportSize.page.height * scaledPage.zoom;

  const scaledBlankSpaceHeight = containerSize.height - scaledPage.height;
  const blankSpaceHeight = scaledBlankSpaceHeight / scaledPage.zoom;
  const pageHeight = (originalReportSize.page.height + blankSpaceHeight) * HEIGHT_CORRECTION_FACTOR;

  const tableHeightOffest = originalReportSize.page.height - originalReportSize.table.height;
  const tableHeight = pageHeight - tableHeightOffest;

  return {
    pageSize: {
      width: originalReportSize.page.width,
      height: pageHeight,
    },
    tableSize: {
      width: originalReportSize.table.width,
      height: tableHeight,
    },
  };
}

// Query powerBI for the original report page size and table size.
// See: https://docs.microsoft.com/en-us/javascript/api/powerbi/powerbi-client/report.report
//      https://docs.microsoft.com/en-us/javascript/api/powerbi/powerbi-client/page.page
const fetchOriginalReportSize = async ({ page, table }) => ({
  page: {
    width: page.defaultSize.width,
    height: page.defaultSize.height,
  },
  table: {
    width: table.layout.width,
    height: table.layout.height,
  },
});

// Query first table visual on report page.
const fetchTableVisual = async (page) => {
  const visuals = await page.getVisuals();
  return visuals.find((v) => v.type === 'tableEx');
};

export const fetchReportDetails = async ({ report, pageName }) => {
  const fetchDetails = async () => {
    const page = await report.getPageByName(pageName);
    const table = await fetchTableVisual(page);
    const size = await fetchOriginalReportSize({ page, table });

    return {
      report,
      size,
      pageName,
      tableName: table.name,
    };
  };

  return fetchDetails();
};

export function buildEventHandlers({ onLoad, onHyperlinkClicked }) {
  return (
    new Map([
      ['loaded', onLoad],
      ['dataHyperlinkClicked', (event) => {
        if (onHyperlinkClicked) { onHyperlinkClicked(event); }
      }],
    ])
  );
}

// SEE: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings
function defaultEmbedConfig(pageName, ownerOwnsDepot) {
  return {
    type: 'report',
    id: ownerOwnsDepot ? REPORT_WITH_DEPOT_ID : REPORT_ID,
    pageName,
    viewMode: models.ViewMode.View,
    tokenType: models.TokenType.Aad,
  };
}

// SEE: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/custom-layout
function defaultCustomLayout(pageName) {
  return {
    displayOption: models.DisplayOption.FitToPage,
    pagesLayout: {
      [pageName]: {
        defaultLayout: {
          displayState: { mode: models.VisualContainerDisplayMode.Hidden },
        },
        visualsLayout: {},
      },
    },
  };
}

// SEE: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings
function defaultEmbedConfigSettings(pageName) {
  return {
    panes: {
      filters: { visible: false },
      pageNavigation: { visible: false },
    },
    hyperlinkClickBehavior: models.HyperlinkClickBehavior.RaiseEvent,
    background: models.BackgroundType.Transparent,
    layoutType: models.LayoutType.Custom,
    customLayout: defaultCustomLayout(pageName),
  };
}

// Build a new report settings with an updated page and table size values.
// - pageSize : { width, height }
// - tableSize : { width, height }
function buildSettings({
  pageName,
  tableName,
  pageSize,
  tableSize,
}) {
  const settings = { ...defaultEmbedConfigSettings(pageName) };

  const pageLayout = settings.customLayout.pagesLayout[pageName];
  pageLayout.defaultLayout.displayState.mode = models.VisualContainerDisplayMode.Visible;
  pageLayout.visualsLayout[tableName] = { height: tableSize.height };

  settings.customLayout.pageSize = {
    type: models.PageSizeType.Custom,
    width: pageSize.width,
    height: pageSize.height,
  };

  return settings;
}

// Resize the report inside the iframe.
//
// - report : powerBiReport instance
// - containerSize : { width, height }
// - originalReportSize : { page: { width, height }, table: { width, height } }
function updateReportLayout({
  pageName,
  tableName,
  containerSize,
  originalReportSize,
  report,
}) {
  if (!report || !containerSize || !originalReportSize) return;

  const { pageSize, tableSize } = computeReportSize({ containerSize, originalReportSize });
  const settings = buildSettings({
    pageName,
    tableName,
    pageSize,
    tableSize,
  });

  report.updateSettings(settings);
}

// SEE: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/control-report-filters
function buildFilters(filters) {
  return Object.keys(filters).map((field) => {
    const [table, column] = field.split('/');
    const value = filters[field];

    return ({
      $schema: 'http://powerbi.com/product/schema#basic',
      target: { table, column },
      operator: 'In',
      values: [value],
      filterType: models.FilterType.Basic,
      requireSignleSelection: true,
    });
  });
}

function embedURL(ownerOwnsDepot) {
  if (ownerOwnsDepot) return `${EMBED_BASE_URL}?reportId=${REPORT_WITH_DEPOT_ID}`;

  return `${EMBED_BASE_URL}?reportId=${REPORT_ID}`;
}

function buildInitialConfig({
  accessToken, filters, pageName, ownerOwnsDepot,
}) {
  return {
    ...defaultEmbedConfig(pageName, ownerOwnsDepot),
    settings: defaultEmbedConfigSettings(pageName),
    embedUrl: embedURL(ownerOwnsDepot),
    filters: buildFilters(filters),
    accessToken,
  };
}

export function useReportConfig({ filters, pageName }) {
  const [config, setConfig] = useState(null);
  const ownerOwnsDepot = useOwnerOwnsDepot();

  useEffect(() => {
    if (config) return;

    (async () => {
      const accessToken = await fetchAccessToken();
      const value = buildInitialConfig({
        accessToken, filters, pageName, ownerOwnsDepot,
      });
      setConfig(value);
    })();
  }, [config, filters, pageName]);

  return { config, setConfig };
}

// reportDetails = {
//   report,
//   pageName,
//   tableName,
//   size: {
//     page: { width, height },
//     table: { width, height }
//   },
// }
//
// containerSize = { width, height }
//
export function useResponsiveLayout({ reportDetails, containerSize }) {
  useEffect(() => {
    if (!reportDetails || !containerSize) return;

    updateReportLayout({
      report: reportDetails.report,
      pageName: reportDetails.pageName,
      tableName: reportDetails.tableName,
      originalReportSize: reportDetails.size,
      containerSize,
    });
  }, [reportDetails, containerSize]);
}

export function homePageQuery(ownerName) {
  return {
    queries: [
      {
        query: `// DAX Query
          DEFINE
          VAR __DS0FilterTable = TREATAS({"${ownerName}"}, 'Owners'[Owner])
          VAR __DS0FilterTable2 = TREATAS({"Mlp Shipped At Date"}, 'Date Column Selector'[Date Column Used])

          EVALUATE
          SUMMARIZECOLUMNS(
          __DS0FilterTable,
          "Latest_Delivery", IGNORE('Generic Measures'[Latest Delivery]),
          "Inventory_Retail_Value_Excl", IGNORE('Generic Measures'[Inventory Retail Value Excl]),
          "Inventory_Items_Count", IGNORE('Generic Measures'[Inventory Items Count]),
          "v__Of_Last_Delivery_In_Status_In_Progess",
            IGNORE('Generic Measures'[% Of Last Delivery In Status In Progess]),
          "v__Of_Last_Delivery_In_Status_On_Stock", IGNORE('Generic Measures'[% Of Last Delivery In Status On Stock]),
          "v__Of_Last_Delivery_In_Status_Sold", IGNORE('Generic Measures'[% Of Last Delivery In Status Sold]),
          "B2B_Retail_Value_In_Last_Delivery", IGNORE('Generic Measures'[B2B Retail Value In Last Delivery]),
          "B2B_Recovery___In_Last_Delivery", IGNORE('Generic Measures'[B2B Recovery % In Last Delivery]),
          "B2B_Recovery___In_Before_Last_Delivery", IGNORE('Generic Measures'[B2B Recovery % In Before Last Delivery]),
          "B2C_Retail_Value_In_Last_Delivery", IGNORE('Generic Measures'[B2C Retail Value In Last Delivery]),
          "B2C_Recovery___In_Last_Delivery", IGNORE('Generic Measures'[B2C Recovery % In Last Delivery]),
          "B2C_Recovery___In_Before_Last_Delivery", IGNORE('Generic Measures'[B2C Recovery % In Before Last Delivery]),
          "Average_Grade_Score_In_Last_Delivery", IGNORE('Generic Measures'[Average Grade Score In Last Delivery]),
          "Average_Grade_Score_In_Before_Last_Delivery",
            IGNORE('Generic Measures'[Average Grade Score In Before Last Delivery]),
          "Inventory_Average_Retail_Value_Excl", IGNORE('Generic Measures'[Inventory Average Retail Value Excl]),
          "Average_Grade_Score_Inventory", IGNORE('Generic Measures'[Average Grade Score Inventory]),
          "B2C___B2B_Retail_Value_YTD", IGNORE('Generic Measures'[B2C & B2B Retail Value YTD]),
          "B2C_Retail_Value_YTD", IGNORE('Generic Measures'[B2C Retail Value YTD]),
          "B2C_Recovery___YTD", IGNORE('Generic Measures'[B2C Recovery % YTD]),
          "B2C_Recovery___YTD_Previous_Year", IGNORE('Generic Measures'[B2C Recovery % YTD Previous Year]),
          "B2B_Retail_Value_YTD", IGNORE('Generic Measures'[B2B Retail Value YTD]),
          "B2B_Recovery___YTD", IGNORE('Generic Measures'[B2B Recovery % YTD]),
          "B2B_Recovery___YTD_Previous_Year", IGNORE('Generic Measures'[B2B Recovery % YTD Previous Year]),
          "Average_Grade_Score_YTD", IGNORE('Generic Measures'[Average Grade Score YTD]),
          "Average_Grade_Score_YTD_Previous_Year", IGNORE('Generic Measures'[Average Grade Score YTD Previous Year]),
          "Latest_Delivery_Date", IGNORE('Generic Measures'[Latest Delivery Date]),
          "Before_Latest_Delivery_Date", IGNORE('Generic Measures'[Before Latest Delivery Date]),
          "Current_Year", IGNORE('Generic Measures'[Current Year]),
          "B2C___B2B_Recovery_YTD", IGNORE('Generic Measures'[B2C & B2B Recovery YTD])
        )`,
      },
    ],
  };
}
