import Announcement from './announcement';

export default class GradedAnnouncement extends Announcement {
  // TODO: Confirm if this is indeed a fixed list
  static validGrades = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'C-', 'C300'];

  validate() {
    this._validateSku();
    this._validateGrade();
    this._validateGradeRemark();
    const hasBaseErrors = super.validate();

    return hasBaseErrors && !this._hasErrors();
  }

  _validateSku() {
    this._clearError('sku');
    if (!this.properties.sku?.raw) {
      this._setError('sku', 'no_sku');
    }
  }

  _validateGrade() {
    this._clearError('grade');
    if (!this.properties.grade?.raw) {
      this._setError('grade', 'no_grade');
    } else if (!this.constructor.validGrades.includes(this.properties.grade.raw)) {
      this._setError('grade', 'invalid_grade');
    }
  }

  _validateGradeRemark() {
    this._clearError('grade remark');
    if (!this.properties['grade remark']?.raw) {
      this._setError('grade remark', 'no_grade_remark');
    }
  }
}
