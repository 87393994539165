import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';

export const CurrentOwnerContext = createContext({ currentOwner: null, setCurrentOwner: () => {} });

export function useRedirectOnChangeOwner(url) {
  const navigate = useNavigate();
  const { currentOwner } = useContext(CurrentOwnerContext);
  const [pageOwnerName, setPageOwnerName] = useState(null);

  useEffect(() => {
    if (!pageOwnerName) {
      setPageOwnerName(currentOwner.name);
      return;
    }

    if (pageOwnerName !== currentOwner.name) navigate(url);
  }, [pageOwnerName, currentOwner]);
}

export function useOwnerOwnsDepot() {
  const { currentOwner } = useContext(CurrentOwnerContext);
  return currentOwner.ownsDepot === true;
}

window.globals ||= {};
window.globals.customCurrentOwnerSetter ||= null;

function useCurrentOwnerState(initialValue) {
  const [currentOwner, baseSetter] = useState(initialValue);
  const setCurrentOwner = useCallback((newOwner) => {
    const originalSetter = (owner) => {
      baseSetter(owner);
      localStorage.setItem('currentOwner', JSON.stringify(owner));
    };

    const customSetter = window.globals.customCurrentOwnerSetter;
    if (customSetter) {
      customSetter({
        newOwner,
        originalSetter,
        prevOwner: currentOwner,
      });
    } else {
      originalSetter(newOwner);
    }
  }, [currentOwner, baseSetter]);

  return [currentOwner, setCurrentOwner];
}

export function useCurrentOwnerContextValue() {
  const currentOwnerJson = JSON.parse(localStorage.getItem('currentOwner'));
  const [currentOwner, setCurrentOwner] = useCurrentOwnerState(currentOwnerJson);

  const value = useMemo(() => ({ currentOwner, setCurrentOwner }), [currentOwner, setCurrentOwner]);
  return value;
}

export function useCustomCurrentOwnerSetter(customSetter) {
  useEffect(() => {
    window.globals.customCurrentOwnerSetter = customSetter;
    return () => { window.globals.customCurrentOwnerSetter = null; };
  }, []);
}
