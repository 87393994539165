export default function getOwners() {
  return JSON.parse(localStorage.getItem('owners'));
}

export function updateBolOwnersAttribute(ownerName) {
  const owners = JSON.parse(localStorage.getItem('owners'));

  owners[ownerName].bolTokenValid = true;

  localStorage.setItem('owners', JSON.stringify(owners));
  localStorage.setItem('currentOwner', JSON.stringify({ name: ownerName, ...owners[ownerName] }));
}
