import PropTypes from 'prop-types';
import styles from './Flash.module.scss';

export default function Flash(props) {
  if (!props.flash) return null;
  if (typeof props.flash !== 'object') return null;

  const type = Object.keys(props.flash)[0];
  const text = Object.values(props.flash)[0];
  let src;
  switch (type) {
    case 'error':
      src = '/icons/linear/0854-warning.svg';
      break;
    case 'info':
      src = '/icons/linear/0858-checkmark-circle.svg';
      break;
    default:
      return null;
  }

  return (
    <div className={`${styles.flash} ${styles[type]} d-table`}>
      <img alt={`${type} icon`} className={styles.icon} src={src} />
      <span>{text}</span>
    </div>
  );
}

// eslint-disable-next-line react/forbid-prop-types
Flash.propTypes = { flash: PropTypes.object };
Flash.defaultProps = { flash: null };
