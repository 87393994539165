import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'components/form/Button';
import { loginRequest } from 'auth/authConfig';

// Renders a button which, when selected, will redirect the page to the login prompt
export default function SignInButton() {
  const { instance } = useMsal();

  return (
    <Button
      onClick={() => instance.loginRedirect(loginRequest)}
      className="btn btn-lg btn-primary mt-3"
      icon="linear/0763-enter-right.svg"
      label="Log in"
      e2eData="signInBtn"
      width="24"
      height="24"
    />
  );
}
