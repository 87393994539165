import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmOrder } from 'api/Orders';
import styles from './Form.module.scss';

export default function NewOrder(props) {
  const { t } = useTranslation();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const { confirmOrder, abortConfirmOrder } = useConfirmOrder();

  function markAsProcessed() {
    const payload = {
      data: {
        attributes: {
          order_reference: props.orderReference,
        },
      },
    };
    const confirmOrderRequest = async () => {
      const result = await confirmOrder(payload);
      setRequestInProgress(false);

      if (result.success) {
        props.onMarkAsProcessedSuccessful();
        props.removeCurrentOrder();
      } else if (!result.aborted) {
        setRequestError(true);
      }
    };

    setRequestInProgress(true);
    setRequestError(false);
    confirmOrderRequest();

    return () => abortConfirmOrder();
  }

  return (
    <div className="text-left mb-3 d-grid d-xl-block">
      <button
        onClick={() => markAsProcessed()}
        disabled={requestInProgress ? 'disabled' : ''}
        className="btn btn-primary"
        type="button"
      >
        { t('components.orders.order_details.mark_as_processed') }
        { requestInProgress ? <span className={styles.loading} /> : ''}
        { requestError ? <i className={`x-icon x-icon-warning ${styles.requestError}`} /> : '' }
      </button>
    </div>
  );
}

NewOrder.propTypes = {
  orderReference: PropTypes.string.isRequired,
  onMarkAsProcessedSuccessful: PropTypes.func.isRequired,
  removeCurrentOrder: PropTypes.func.isRequired,
};
