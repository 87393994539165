import PropTypes from 'prop-types';
import styles from './ServerError.module.scss';
import Card from './Card';
import Button from './form/Button';
import ConveyorAnimation from './ConveyorAnimation';

export default function ServerError(props) {
  return (
    <Card>
      <div className={`d-flex flex-column ${styles.default}`}>
        <ConveyorAnimation play={false} />
        <div className={`d-flex align-items-center flex-grow-1 ${styles['content-container']}`}>
          <div className={`text-center ${styles.content}`}>
            <h2 className={styles.header}>{props.title}</h2>
            <div>{props.body}</div>
          </div>
        </div>
        <div className={`d-flex flex-row ${styles.buttons}`}>
          <Button onClick={props.onRetry} className="btn btn-primary" icon="linear/0730-redo2.svg" label="Retry" />
          <Button
            onClick={() => props.onCancel(props.formData)}
            className="btn btn-outline-primary"
            icon="linear/0811-cross.svg"
            label="Cancel"
          />
        </div>
      </div>
    </Card>
  );
}

ServerError.propTypes = {
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formData: PropTypes.object,
};

ServerError.defaultProps = {
  title: '',
  body: '',
  formData: {},
};
