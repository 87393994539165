import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

// Renders a button which, when selected, will redirect the page to the logout prompt
export default function SignOutButton(props) {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const logout = useCallback((e) => {
    e.preventDefault();
    localStorage.removeItem('original_request');
    localStorage.removeItem('locale');
    localStorage.removeItem('owners');
    localStorage.removeItem('currentOwner');
    instance.logoutRedirect();
  }, [instance]);

  return (
    <a href="/" className={props.className} onClick={logout}>
      <i className="x-icon x-icon-sign-out" />
      { t('components.sign_out_button.log_out') }
    </a>
  );
}

SignOutButton.propTypes = { className: PropTypes.string };
SignOutButton.defaultProps = { className: '' };
