import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from '../Sidebar.module.scss';

export default function OwnerWithoutDepotMenu() {
  const { t } = useTranslation();

  return (
    <NavLink to="/batches" className={styles.link}>
      <i className="x-icon x-icon-truck" />
      {t('components.sidebar.deliveries')}
    </NavLink>
  );
}
