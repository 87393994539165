import PropTypes from 'prop-types';
import dateFormatDMY from 'utils/dates';
import styles from 'components/orders/OrdersCollection.module.scss';

export default function OrderLine(props) {
  return (
    <tr
      onClick={() => props.setAsCurrentOrder()}
      className={props.selected ? styles.highlightedRows : ''}
    >
      <td>{props.order.license_plate}</td>
      <td className={styles.titleNoOverflow}>{props.order.title}</td>
      <td>{props.order.ean}</td>
      <td>{props.order.your_reference}</td>
      <td>{dateFormatDMY(props.order.order_date)}</td>
    </tr>
  );
}

OrderLine.propTypes = {
  order: PropTypes.shape({
    order_id: PropTypes.number,
    order_reference: PropTypes.string,
    license_plate: PropTypes.string,
    title: PropTypes.string,
    ean: PropTypes.string,
    your_reference: PropTypes.string,
    order_date: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  setAsCurrentOrder: PropTypes.func.isRequired,
};

OrderLine.defaultProps = {
  selected: false,
};
