import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useUpdateBatch } from 'api/Batches';
import DeliveryContext from 'utils/deliveryContext';
import styles from './EditPalletCount.module.scss';

const SUBMIT_KEYCODE = 13;
const ESCAPE_KEYCODE = 27;

export default function EditPalletCount(props) {
  const [alertError, setAlertError] = useState(false);
  const palletNumberRef = useRef(null);
  const updateBatch = useUpdateBatch(props.reference);
  const { updateBatchPalletCount } = useContext(DeliveryContext);

  useEffect(() => palletNumberRef.current.select());

  const updatePalletCount = async (newPalletCount) => {
    setAlertError(false);
    props.onBeforeAjaxRequest();

    try {
      const result = await updateBatch({
        pallet_count: newPalletCount,
        delivered_on: props.deliveredOn,
        delivery_time: props.deliveryTime,
      });

      if (result.success) {
        props.afterEdit();
        updateBatchPalletCount(props.reference, newPalletCount);
      } else {
        setAlertError(true);
      }
    } finally {
      props.onAfterAjaxRequest();
    }
  };

  function handleKeyUp(event) {
    if (event.keyCode === ESCAPE_KEYCODE) {
      props.afterEdit();
      return;
    }

    if (event.keyCode !== SUBMIT_KEYCODE) return;

    const value = parseInt(palletNumberRef.current.value);
    const oldValue = parseInt(palletNumberRef.current.defaultValue);

    if (value > 0 && value !== oldValue) {
      updatePalletCount(value);
      return;
    }

    palletNumberRef.current.select();
    setAlertError(true);
  }

  return (
    <span className="d-flex align-items-center">
      <input
        className="form-control"
        aria-label="pallet_count"
        ref={palletNumberRef}
        defaultValue={props.palletCount}
        type="number"
        onBlur={props.afterEdit}
        onKeyUp={handleKeyUp}
        min="1"
        max="999"
        required
      />
      { alertError
        && <span aria-label="pallet_count_invalid" className={`ms-1 ${styles['request-error']}`}>!</span> }
    </span>
  );
}

EditPalletCount.propTypes = {
  palletCount: PropTypes.number,
  reference: PropTypes.string.isRequired,
  deliveredOn: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
  afterEdit: PropTypes.func.isRequired,
  onBeforeAjaxRequest: PropTypes.func.isRequired,
  onAfterAjaxRequest: PropTypes.func.isRequired,
};
EditPalletCount.defaultProps = {
  palletCount: null,
};
