import { useContext } from 'react';
import { DeliveriesViewContext, DELIVERIES_VIEWS } from 'utils/deliveriesViewContext';
import CalendarViewLink from './view_switcher/CalendarViewLink';
import ListViewLink from './view_switcher/ListViewLink';

export default function ViewSwitcher() {
  const { deliveriesView } = useContext(DeliveriesViewContext);

  return (DELIVERIES_VIEWS.CALENDAR === deliveriesView) ? <ListViewLink /> : <CalendarViewLink />;
}
