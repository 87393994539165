import { useRef, useMemo } from 'react';

export class FieldErrors {
  constructor({ fields, onCommit }) {
    this.value = {};
    this.onCommit = onCommit;

    fields.forEach((field) => {
      this.value[field] = {};
    });
  }

  clear(field) {
    if (this.value[field]) this.value[field] = {};
    return this;
  }

  remove(field, errorType) {
    if (this.value[field]) delete this.value[field][errorType];
    return this;
  }

  set(field, errorType, errorMsg) {
    return this.merge({ [field]: { [errorType]: errorMsg } });
  }

  merge(update) {
    const previous = this.value;
    const current = { ...previous };

    Object.entries(update).forEach(([field, errors]) => {
      const previousErrors = previous[field];
      const currentErrors = { ...previousErrors, ...errors };
      current[field] = currentErrors;
    });

    this.value = current;
    return this;
  }

  firstErrorPerField() {
    const result = {};

    Object.entries(this.value).forEach(([field, errors]) => {
      const [error] = Object.values(errors || {});
      if (error) result[field] = error;
    });

    return result;
  }

  hasEntries() {
    return Object.values(this.value).some((errors) => Object.keys(errors).length > 0);
  }

  commit() {
    const valueToCommit = this.firstErrorPerField();
    this.onCommit(valueToCommit);
  }
}

export function useFieldErrors({ fields, onCommit }) {
  const fieldErrors = useMemo(() => (new FieldErrors({ fields, onCommit })), [onCommit]);
  return useRef(fieldErrors);
}
