import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './OrdersCollection.module.scss';

export default function OrdersTableHeader(props) {
  const { t } = useTranslation();

  function sortIcon(column) {
    return (column === props.sortColumn)
      ? <i className={`x-icon x-icon-sort-${props.sortDirection} ${styles.sort_icon}`} />
      : null;
  }

  return (
    <thead>
      <tr>
        <th scope="col" className={styles.sku} onClick={() => props.onClick('license_plate')}>
          {t('components.orders.orders_collection.table.sku')}
          {sortIcon('license_plate')}
        </th>
        <th scope="col" className={styles.title} onClick={() => props.onClick('title')}>
          {t('components.orders.orders_collection.table.title')}
          {sortIcon('title')}
        </th>
        <th scope="col" className={styles.ean} onClick={() => props.onClick('ean')}>
          {t('components.orders.orders_collection.table.ean')}
          {sortIcon('ean')}
        </th>
        <th scope="col" className={styles.owner_reference} onClick={() => props.onClick('your_reference')}>
          {t('components.orders.orders_collection.table.owner_reference')}
          {sortIcon('your_reference')}
        </th>
        <th scope="col" className={styles.ordered_on} onClick={() => props.onClick('order_date')}>
          {t('components.orders.orders_collection.table.ordered_on')}
          {sortIcon('order_date')}
        </th>
      </tr>
    </thead>
  );
}

OrdersTableHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};
