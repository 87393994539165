import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

export default function Unauthorized(props) {
  localStorage.setItem('original_request', props.path);

  return (
    <Navigate to="/login" replace />
  );
}

Unauthorized.propTypes = { path: PropTypes.string };
Unauthorized.defaultProps = { path: null };
