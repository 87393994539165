import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import OwnerTypeSpecificMenu from 'components/layouts/with_sidebar/sidebar/OwnerTypeSpecificMenu';
import styles from '../Sidebar.module.scss';

export default function Navigation(props) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column w-100 mb-2">
      <NavLink to="/batches/new" className={styles.link}>
        <i className="x-icon x-icon-plus" />
        {t('components.sidebar.new_announcement')}
      </NavLink>

      <OwnerTypeSpecificMenu />

      <NavLink to="/reports/sales" className={styles.link}>
        <i className="x-icon x-icon-cash-euro" />
        {t('components.sidebar.sales')}
      </NavLink>

      <NavLink to="/reports/items" className={styles.link}>
        <i className="x-icon x-icon-tag" />
        {t('components.sidebar.items')}
      </NavLink>

      {props.helpUrl
        && (
          <a
            href={props.helpUrl}
            aria-label="main-help-link"
            target="partner_portal_help"
            className={styles.link}
          >
            <i className="x-icon x-icon-question-circle" />
            {t('components.sidebar.help')}
            <i className="x-icon-external-link" />
          </a>
        )}
    </div>
  );
}

Navigation.propTypes = {
  helpUrl: PropTypes.string,
};

Navigation.defaultProps = {
  helpUrl: null,
};
