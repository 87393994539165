import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import Public from 'components/layouts/Public';
import SignInButton from 'components/session/SignInButton';

export default function Login() {
  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to="/" replace />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Public>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-5">
                <img src="/buybay-logo.svg" alt="buybay-logo" width="128" height="22" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-5">
                <img
                  src="/partner-portal.svg"
                  alt="partner-portal"
                  className="img-responsive center-block d-block mx-auto"
                  width="334"
                  height="250"
                />
                <h1 className="text-center mt-3 mb-0 pb-0">Partner portal</h1>
                <p className="text-center">Announce your returns and keep track of progress</p>
                <div className="text-center">
                  <SignInButton />
                </div>
              </div>
            </div>
          </div>
        </Public>
      </UnauthenticatedTemplate>
    </>
  );
}
