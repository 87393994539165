import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig, loginRequest, graphConfig } from 'auth/authConfig';
import i18n from 'i18n/config';

const AZURE_GROUPS_PREFIX = window.env.REACT_APP_MSA_OWNER_PREFIX;
const OWNER_DEPOT = '(?<ownsDepot>OWNER_DEPOT-)?';
const BOL_PARTNER = '(?<bolPartner>BOL-)?';
const GROUP_NAME_FORMAT = new RegExp(`${AZURE_GROUPS_PREFIX}-${OWNER_DEPOT}${BOL_PARTNER}(?<ownerName>.*)`);

const msalInstance = new PublicClientApplication(msalConfig);

// This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

async function callMsGraph(url, token) {
  let accessToken = token;

  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  const options = { method: 'GET', headers };

  const response = await fetch(url, options);
  const json = await response.json();

  return json;
}

function forceLogout(account) {
  const currentAccount = msalInstance.getAccountByHomeId(account.homeAccountId);
  const logoutHint = currentAccount.idTokenClaims.login_hint;
  msalInstance.logoutRedirect({ logoutHint });
  alert('The configuration for your user is incorrect, please contact the technical support.');
}

async function fetchOwners() {
  const allOwners = {};

  async function getPaginatedOwners(url) {
    const owners = {};
    const response = await callMsGraph(url);

    try {
      response.value.forEach((owner) => {
        // See section on README regarding owner names (Groups in Azure)
        const groupName = owner.displayName.match(GROUP_NAME_FORMAT);

        if (groupName?.groups.ownerName) {
          const ownerName = groupName?.groups.ownerName.replaceAll('+', ' ');
          owners[ownerName] = {
            apiKey: owner.description,
            ownsDepot: groupName?.groups.ownsDepot !== undefined,
            bolPartner: groupName?.groups.bolPartner !== undefined,
            bolTokenValid: false,
          };
        }
      });

      return { owners, nextPageUrl: response['@odata.nextLink'] };
    } catch {
      return null;
    }
  }

  let url = graphConfig.groupEndpoint;
  while (url) {
    // eslint-disable-next-line no-await-in-loop
    const page = await getPaginatedOwners(url);
    url = page.nextPageUrl;

    Object.assign(allOwners, page.owners);
  }

  return allOwners;
}

// Callback which gets triggered at every MSAL action
msalInstance.addEventCallback(async (event) => {
  // Setup account for logged in user with correct language
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // Get account info from response and set user account as active
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);

    // Request extra user info via api
    const userInfo = await callMsGraph(graphConfig.graphMeEndpoint);
    const { preferredLanguage } = userInfo;

    // Change locale settings based on user preference
    if (preferredLanguage !== null) {
      // Store preferred language in localStorage and change i18n configuration if needed
      localStorage.setItem('locale', preferredLanguage);

      const language = preferredLanguage.split('-')[0];
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
      }
    }

    const ownerConfig = await fetchOwners();
    if (!Object.keys(ownerConfig).length) forceLogout(account);

    const defaultOwner = Object.keys(ownerConfig).sort()[0];
    const defaultSession = JSON.stringify({ name: defaultOwner, ...ownerConfig[defaultOwner] });

    localStorage.setItem('owners', JSON.stringify(ownerConfig));
    localStorage.setItem('currentOwner', defaultSession);
  }
});

export default msalInstance;
