import {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ASNExcelSheet from 'classes/asn_excel_sheet';
import Progress from 'components/Progress';
import ServerError from 'components/ServerError';
import Announcement from 'classes/announcement';
import { useCreateBatch } from 'api/depot/Batches';
import { formatForAPI, totalQuantity, averageValue } from 'utils/batches';
import { usePostToAPI } from 'utils/forms';

const PROGRESS_ANIMATION_DURATION = 1000; // 1 second

export default function Create() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formData, submitted } = { ...location.state };
  const [error, setError] = useState(null);
  const createBatch = useCreateBatch();

  // TODO:
  // Refactor this. We are only doing this so that item properties can be accessed
  // without the _ (underscore) prefix.
  //
  // Cast items so that they will be instances of the Announcement class.
  formData.items = useMemo(() => formData.items.map((i) => new Announcement(i)), [formData.items]);

  document.title = t('routes.batches.depot.create.document_title');

  const quantity = useMemo(() => totalQuantity(formData.items), [formData.items]);
  const displayQuantity = Intl.NumberFormat('en-EN').format(quantity);
  const displayAverageValue = averageValue(formData, quantity);
  const flashParams = { quantity: displayQuantity, averageValue: displayAverageValue };
  const hashFormData = useMemo(() => {
    const hashAnnouncements = formData.items.map((item) => {
      const allHeaders = Object.keys(item.properties);
      const otherHeaders = allHeaders.filter((header) => !ASNExcelSheet.allHeadersWithDepot.includes(header));
      const regularProperties = formatForAPI(item, ASNExcelSheet.allHeadersWithDepot);
      const otherProperties = formatForAPI(item, otherHeaders);

      // Remap sku -> license_plate
      delete Object.assign(regularProperties, { license_plate: regularProperties.sku }).sku;

      return { ...regularProperties, custom_attributes: otherProperties };
    });

    return {
      data: {
        type: 'stock_batches',
        attributes: {
          reference: formData.reference,
          items: hashAnnouncements,
        },
      },
    };
  }, [formData]);

  const onSuccessRedirect = useCallback(() => {
    navigate('/batches/depot/create', { replace: true, state: { formData, submitted: true } });
    navigate('/batches/depot', {
      state: { flash: { info: t('routes.batches.depot.flash.confirmation', flashParams) } },
    });
  }, [formData, flashParams]);

  const onRequestFailure = useCallback((result) => {
    setError(t(
      'components.server_error.response_error',
      { statusText: result.json.errors.status, details: result.json.errors.details[0] }
    ));
  }, []);

  const postToAPI = usePostToAPI({
    onSuccessRedirect,
    onRequestFailure,
    createBatch,
    hashFormData,
    setError,
  });

  const onCancel = useCallback(() => {
    navigate('/batches/depot/new', { state: { flash: { info: t('routes.batches.depot.flash.cancel') } } });
  }, [formData]);

  const clearError = useCallback(() => setError(null), [setError]);

  if (submitted) return null;

  if (error) {
    return (
      <ServerError
        title={t('components.server_error.title')}
        onRetry={clearError}
        onCancel={onCancel}
        formData={formData}
        body={error}
      />
    );
  }

  return (
    <Progress
      title={t('routes.batches.depot.create.announcing.title')}
      body={t('routes.batches.depot.create.announcing.body', flashParams)}
      minAnimationDuration={PROGRESS_ANIMATION_DURATION}
      afterAnimation={postToAPI}
    />
  );
}
