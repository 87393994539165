// Stylesheets
import './App.scss';

// React imports
import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';

// MSAL imports
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

// App imports
import PageLayout from 'components/PageLayout';

// Routes
import Login from 'routes/Login';
import NotFound from 'routes/NotFound';
import Home from 'routes/Home';

import Batches from 'routes/Batches';
// Non-depot owners (default)
import BatchesIndex from 'routes/batches/default/Index';
import BatchesNew from 'routes/batches/default/New';
import BatchesRepair from 'routes/batches/default/Repair';
import BatchesSchedule from 'routes/batches/default/Schedule';
import BatchesReschedule from 'routes/batches/default/Reschedule';
import BatchesCreate from 'routes/batches/default/Create';
// Depot owners
import BatchesDepotIndex from 'routes/batches/depot/Index';
import BatchesDepotNew from 'routes/batches/depot/New';
import BatchesDepotRepair from 'routes/batches/depot/Repair';
import BatchesDepotCreate from 'routes/batches/depot/Create';
import Orders from 'routes/Orders';
import OrdersIndex from 'routes/orders/Index';

import Bol from 'routes/Bol';
import BolConnect from 'routes/bol/Connect';
import BolIntegration from 'routes/bol/Integration';

// Power BI Reports
import Reports from 'routes/Reports';
import BatchesReport from 'routes/reports/batches/default/Report';
import Items from 'routes/reports/items/default/Index';
import SalesIndex from 'routes/reports/sales/default/Index';
import SalesDetails from 'routes/reports/sales/default/Details';

function App({ pca }) {
  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <Pages />
      </PageLayout>
    </MsalProvider>
  );
}

App.propTypes = {
  pca: PropTypes.instanceOf(PublicClientApplication).isRequired,
};

function Pages() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/batches" element={<Batches />}>
        {/* Non-depot owners */}
        <Route index element={<BatchesIndex />} />
        <Route path="new" element={<BatchesNew />} />
        <Route path="repair" element={<BatchesRepair />} />
        <Route path="schedule" element={<BatchesSchedule />} />
        <Route path="reschedule" element={<BatchesReschedule />} />
        <Route path="create" element={<BatchesCreate />} />
        {/* Depot owners */}
        <Route path="depot" element={<BatchesDepotIndex />} />
        <Route path="depot/new" element={<BatchesDepotNew />} />
        <Route path="depot/repair" element={<BatchesDepotRepair />} />
        <Route path="depot/create" element={<BatchesDepotCreate />} />
      </Route>

      <Route path="/bol" element={<Bol />}>
        <Route path="connect" element={<BolConnect />} />
        <Route path="integration" element={<BolIntegration />} />
      </Route>

      <Route path="/orders" element={<Orders />}>
        <Route index element={<OrdersIndex />} />
      </Route>

      <Route path="/reports" element={<Reports />}>
        <Route path="batches" element={<BatchesReport />} />
        <Route path="items" element={<Items />} />
        <Route path="sales" element={<SalesIndex />} />
        <Route path="sales/details" element={<SalesDetails />} />
      </Route>

      <Route path="/" element={<Home />} />

      <Route path="/deliveries*" element={<Navigate to="/" replace />} />
      <Route path="/announcements*" element={<Navigate to="/" replace />} />
      <Route path="/sales" element={<Navigate to="/reports/sales" replace />} />
      <Route path="/items" element={<Navigate to="/reports/items" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
