import { useState } from 'react';
import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import { useLockDetails } from './helpers';
import DeliveryCard from './time_slot_content/DeliveryCard';
import styles from './TimeSlotContent.module.scss';

export default function TimeSlotContent(props) {
  const [disabled, setDisabled] = useState(false);
  const { isLocked, explanationTooltipTarget } = useLockDetails({
    delivery: props.delivery,
    currentBatchHasPalletReferences: props.currentBatchHasPalletReferences,
  });

  const lockedStyle = isLocked ? styles.locked : '';
  const waitStyle = disabled ? styles.wait : '';
  const stateStyles = `${lockedStyle} ${waitStyle}`;

  const onClick = () => {
    if (disabled) return;

    setDisabled(true);
    props.onClick({
      isLocked,
      date: props.dateString,
      time: props.timeSlot,
      cleanup: () => { setDisabled(false); },
    });
  };

  return (
    <div
      ref={explanationTooltipTarget}
      className={`${styles.default} ${stateStyles}`}
      onClick={onClick}
      aria-hidden="true"
    >
      {props.delivery && <DeliveryCard delivery={props.delivery} />}
    </div>
  );
}

TimeSlotContent.propTypes = {
  delivery: PropTypes.instanceOf(Delivery),
  currentBatchHasPalletReferences: PropTypes.bool,
  onClick: PropTypes.func,
  dateString: PropTypes.string.isRequired,
  timeSlot: PropTypes.oneOf(['morning', 'afternoon']).isRequired,
};

TimeSlotContent.defaultProps = {
  delivery: null,
  currentBatchHasPalletReferences: false,
  onClick: () => {},
};
