import PropTypes from 'prop-types';
import classes from './RepairItemErrors.module.scss';

export default function RepairItemErrors(props) {
  return (
    <div className="d-flex mb-2">
      {props.errors.map((error) => (
        <div key={`err-${error}`} className={`d-flex me-4 ${classes['error-text']}`}>
          <img alt="Error" className="icon text-secondary me-1" src="/icons/linear/0854-warning.svg" />
          {error}
        </div>
      ))}
    </div>
  );
}

RepairItemErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
