import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Announcement from 'classes/announcement';
import GradedAnnouncement from 'classes/graded_announcement';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import AnnouncementHeader from 'components/AnnouncementHeader';
import Header from 'components/batches/depot/new/Header';
import classes from './RepairTable.module.scss';
import RepairItem from './RepairItem';
import Progress from '../Progress';

const DELAY_BEFORE_NEXT_STEP = 1000;

function InitializeAnnouncement(params) {
  const ownerOwnsDepot = useOwnerOwnsDepot();

  if (ownerOwnsDepot) {
    return new GradedAnnouncement(params);
  }

  return new Announcement(params);
}

function RepairTable(props) {
  const { t } = props;
  const navigate = useNavigate();
  const ownerOwnsDepot = useOwnerOwnsDepot();
  const [state, setAnnouncements] = useState({
    validAnnouncements: props.validAnnouncements.map((a) => InitializeAnnouncement(a)),
    invalidAnnouncements: props.invalidAnnouncements.map((a) => InitializeAnnouncement(a)),
  });

  let errorStat = t('components.repair_table.single_error');
  if (state.invalidAnnouncements.length > 1) {
    errorStat = t('components.repair_table.errors', { error_count: state.invalidAnnouncements.length });
  }

  useEffect(() => {
    const inputs = document.querySelector(`.${classes['repair-tables']} input`);
    if (inputs !== null) { inputs.select(); }
  });

  const moveToValidAnnouncements = useCallback((updatedAnnouncement, announcementIndex) => {
    const newValidAnnouncements = [...state.validAnnouncements, updatedAnnouncement];
    const newInvalids = [...state.invalidAnnouncements];
    newInvalids.splice(announcementIndex, 1);
    setAnnouncements({
      validAnnouncements: newValidAnnouncements,
      invalidAnnouncements: [...newInvalids],
    });
  }, [state, setAnnouncements]);

  useEffect(() => {
    if (state.invalidAnnouncements.length > 0) return;

    setTimeout(() => {
      const nextStepUrl = (ownerOwnsDepot) ? '/batches/depot/create' : '/batches/schedule';

      navigate(nextStepUrl, {
        state: {
          formData: {
            ...props.formData,
            items: state.validAnnouncements,
            hasPalletReferences: props.hasPalletReferences,
          },
        },
      });
    }, DELAY_BEFORE_NEXT_STEP);
  }, [state, props.hasPalletReferences]);

  if (state.invalidAnnouncements.length === 0) {
    return (
      <div>
        <Progress title={t('components.progress.validating.title')} body={t('components.progress.validating.body')} />
      </div>
    );
  }

  return (
    <>
      { ownerOwnsDepot
        ? <Header step={2} title={t('routes.batches.depot.repair.title')} />
        : <AnnouncementHeader step={2} title={t('routes.batches.default.repair.title')} />}
      <div className={classes['repair-tables']}>
        <p className={classes['error-summary']}>
          {t('components.repair_table.error_summary', { error_stat: errorStat })}
        </p>

        {state.invalidAnnouncements.map((announcement, idx) => (
          <RepairItem
            key={announcement.properties.row.raw}
            moveToValidAnnouncements={moveToValidAnnouncements}
            announcement={announcement}
            announcementIndex={idx}
            ownerOwnsDepot={ownerOwnsDepot}
          />
        ))}
      </div>
    </>
  );
}

export default withTranslation()(RepairTable);

RepairTable.propTypes = {
  t: PropTypes.func.isRequired,
  validAnnouncements: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      _properties: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      _errors: PropTypes.object,
    })
  ).isRequired,
  invalidAnnouncements: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      _properties: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      _errors: PropTypes.object,
    })
  ).isRequired,
  formData: PropTypes.shape({
    reference: PropTypes.string,
    delivered_on: PropTypes.string,
    delivery_time: PropTypes.string,
    pallet_count: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
  }).isRequired,
  hasPalletReferences: PropTypes.bool.isRequired,
};
