import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styles from './DayHeader.module.scss';

export default function DayHeader({ dayObject }) {
  const now = new Date();
  const dateObject = dayjs(dayObject.dateString);
  const isToday = dateObject.isSame(now, 'day') && dateObject.isSame(now, 'month');

  return (
    <div className={`${styles.default} ${isToday ? styles.current : ''}`}>
      {dayObject.dayOfMonth}
    </div>
  );
}

DayHeader.propTypes = {
  dayObject: PropTypes.shape({
    dateString: PropTypes.string,
    dayOfMonth: PropTypes.number,
  }).isRequired,
};
