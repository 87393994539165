import PropTypes from 'prop-types';
import Announcement from 'classes/announcement';
import classes from './RepairItemTableBody.module.scss';
import RepairItemCellContent from './RepairItemCellContent';

function withError(key, attrsWithError) {
  return (attrsWithError.includes(key)) ? classes.error : '';
}

function noReferenceError(attrsWithError) {
  return (attrsWithError.includes('reference')) ? classes.error : '';
}

export default function RepairItemTableBody(props) {
  const attrsWithError = Object.keys(props.announcement.errors);

  return (
    <tbody>
      <tr>
        <th scope="row">{props.announcement.properties.row.parsed}</th>
        <RepairItemCellContent
          name="ean"
          value={props.announcement.properties.ean.parsed}
          inputType="ean"
          errorClass={`${noReferenceError(attrsWithError)}
          ${withError('ean', attrsWithError)}`}
        />

        <RepairItemCellContent
          name="asin"
          value={props.announcement.properties.asin.parsed}
          inputType="asin"
          errorClass={`${noReferenceError(attrsWithError)}
          ${withError('asin', attrsWithError)}`}
        />

        <RepairItemCellContent
          name="other reference"
          value={props.announcement.properties['other reference'].parsed}
          errorClass={noReferenceError(attrsWithError)}
        />

        <RepairItemCellContent
          name="product title"
          value={props.announcement.properties['product title'].parsed}
          errorClass={withError('product title', attrsWithError)}
        />

        <RepairItemCellContent
          name="retail price"
          value={props.announcement.properties['retail price'].parsed?.amount}
          inputType="price"
          textClass="text-end"
          errorClass={withError('retail price', attrsWithError)}
        />

        <RepairItemCellContent
          name="quantity"
          value={props.announcement.properties.quantity.parsed}
          inputType="integer"
          textClass="text-end"
          errorClass={withError('quantity', attrsWithError)}
        />

        { props.ownerOwnsDepot
          && (
            <>
              <RepairItemCellContent
                name="sku"
                value={props.announcement.properties.sku.parsed}
                errorClass={withError('sku', attrsWithError)}
              />

              <RepairItemCellContent
                name="grade"
                value={props.announcement.properties.grade.parsed}
                errorClass={withError('grade', attrsWithError)}
              />

              <RepairItemCellContent
                name="grade remark"
                value={props.announcement.properties['grade remark'].parsed}
                errorClass={withError('grade remark', attrsWithError)}
              />
            </>
          ) }

        <RepairItemCellContent
          name="owner reference"
          value={props.announcement.properties['owner reference'].parsed}
        />

        <RepairItemCellContent
          name="owner price"
          value={props.announcement.properties['owner price'].parsed?.amount}
          textClass="text-end"
        />

        <RepairItemCellContent
          name="return reason"
          value={props.announcement.properties['return reason'].parsed}
        />
      </tr>
    </tbody>
  );
}

RepairItemTableBody.propTypes = {
  announcement: PropTypes.instanceOf(Announcement).isRequired,
  ownerOwnsDepot: PropTypes.bool.isRequired,
};
