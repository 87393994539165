import PropTypes from 'prop-types';
import { useEffect } from 'react';
import styles from './Progress.module.scss';
import Ellipsis from './Ellipsis';
import Card from './Card';
import ConveyorAnimation from './ConveyorAnimation';

export default function Progress(props) {
  // Execute props.afterAnimation callback after the minimum animation duration elapsed.
  useEffect(() => {
    if (props.minAnimationDuration && props.afterAnimation) {
      setTimeout(props.afterAnimation, props.minAnimationDuration);
    }
  }, [props.minAnimationDuration, props.afterAnimation]);

  return (
    <Card>
      <div className={`d-flex flex-column ${styles.default}`}>
        <ConveyorAnimation play />
        <div className={`d-flex align-items-center flex-grow-1 ${styles['content-container']}`}>
          <div className={`text-center ${styles.content}`}>
            <h2>
              {props.title}
              <Ellipsis />
            </h2>
            <div>{props.body}</div>
          </div>
        </div>
      </div>
    </Card>
  );
}

Progress.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  minAnimationDuration: PropTypes.number,
  afterAnimation: PropTypes.func,
};

Progress.defaultProps = {
  title: '',
  body: '',
  minAnimationDuration: null,
  afterAnimation: null,
};
