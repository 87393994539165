import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Private from 'components/layouts/Private';
import { useCheckPartnerToken } from 'api/Bol';
import { CurrentOwnerContext } from 'utils/currentOwner';
import { useUpdateOwners } from 'components/bol/helpers';

export default function Home() {
  const { currentOwner } = useContext(CurrentOwnerContext);
  const checkPartnerToken = useCheckPartnerToken();
  const updateOwners = useUpdateOwners();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const bolAuthorized = params.get('bolAuthorized');
  let opts;

  useEffect(async () => {
    if (currentOwner === null) return;
    if (currentOwner.bolPartner === undefined
        || currentOwner.bolPartner === false
        || (currentOwner.bolPartner === true && currentOwner.bolTokenValid === true)
    ) {
      navigate('/batches');
      return;
    }

    const data = await checkPartnerToken();

    if (data.success === false || Date.parse(data.json.data.expiration_date) < Date.now()) {
      navigate('/bol/connect');
    } else {
      updateOwners();
      currentOwner.bolTokenValid = true;
      if (bolAuthorized === 'true') {
        opts = {
          state: { flash: { info: t('routes.bol.integration.flash.success', { ownerName: currentOwner.name }) } },
        };
      }
      navigate('/batches', opts);
    }
  }, [currentOwner]);

  return (<Private />);
}
