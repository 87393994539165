import PropTypes from 'prop-types';
import {
  useContext, useEffect, useRef, useState, useLayoutEffect, useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import DeliveryContext from 'utils/deliveryContext';
import { isBatchReportReady } from 'components/deliveries/deliveries_collection/helpers';
import styles from './Batch.module.scss';
import Details from './batch/Details';
import Options from './batch/Options';

function useIsRecentlySaved({ deliveredOn, deliveryTime, reference }) {
  const location = useLocation();
  const locationState = location.state || {};

  return useMemo(() => (
    locationState.deliveredOn === deliveredOn
      && locationState.deliveryTime === deliveryTime
      && locationState.reference === reference
  ), [locationState, deliveredOn, deliveryTime, reference]);
}

function useScrollToElementWhenTrue(elementRef, condition) {
  const DELAY_BEFORE_SCROLL = 1000;
  useLayoutEffect(() => {
    if (elementRef.current && condition) {
      setTimeout(() => elementRef.current.scrollIntoView(), DELAY_BEFORE_SCROLL);
    }
  }, [elementRef, condition]);
}

function useHoverClass({ deliveryStatus, isClickable }) {
  return useMemo(() => (isClickable ? styles[`hover-delivery-${deliveryStatus}`] : ''), [deliveryStatus, isClickable]);
}

export default function Batch(props) {
  const [deletedStatus, setDeletedStatus] = useState(false);
  const [status, setStatus] = useState(props.status);
  const { removeBatch } = useContext(DeliveryContext);
  const batchRef = useRef(null);
  const isRecentlySaved = useIsRecentlySaved({
    deliveredOn: props.deliveredOn,
    deliveryTime: props.deliveryTime,
    reference: props.reference,
  });
  const highlightClass = isRecentlySaved ? styles.highlight : null;
  const isClickable = isBatchReportReady(props.status);
  const hoverClass = useHoverClass({ deliveryStatus: props.deliveryStatus, isClickable });

  useScrollToElementWhenTrue(batchRef, isRecentlySaved);

  useEffect(() => {
    if (deletedStatus === 'success') removeBatch(props.reference);
  }, [deletedStatus]);

  return (
    <li
      data-e2e={props.reference}
      data-delivery-date={props.deliveredOn}
      data-delivery-time={props.deliveryTime}
      data-reference={props.reference}
      ref={batchRef}
      className={`${styles.default} ${highlightClass} ${hoverClass} list-group-item`}
      style={props.style}
    >
      <div className={styles.mainSection}>
        <Details
          isClickable={isClickable}
          strikeout={deletedStatus === 'in_progress'}
          status={status}
          deliveryId={props.deliveryId}
          reference={props.reference}
        />
      </div>
      <div className="d-flex align-items-center">
        <Options
          deliveryId={props.deliveryId}
          disabled={props.locked === true || props.status !== 'processed'}
          deliveryStatus={props.deliveryStatus}
          reference={props.reference}
          deliveredOn={props.deliveredOn}
          deliveryTime={props.deliveryTime}
          itemCount={props.itemCount}
          palletCount={props.palletCount}
          batchRef={batchRef}
          onDeleteStart={() => setDeletedStatus('in_progress')}
          onDeleteSuccess={() => setDeletedStatus('success')}
          onDeleteFailed={() => setDeletedStatus('failed')}
          onBeforeAjaxRequest={() => setStatus('processing')}
          onAfterAjaxRequest={() => setStatus(props.status)}
        />
      </div>
    </li>
  );
}

Batch.propTypes = {
  deliveryId: PropTypes.number.isRequired,
  deliveredOn: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
  deliveryStatus: PropTypes.oneOf(['confirmed', 'locked', 'unlocked']).isRequired,
  reference: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  palletCount: PropTypes.number,
  itemCount: PropTypes.number.isRequired,
  style: PropTypes.objectOf(PropTypes.string),
  locked: PropTypes.bool.isRequired,
};
Batch.defaultProps = {
  style: null,
  palletCount: null,
};
