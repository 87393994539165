import { useTranslation } from 'react-i18next';
import { useSwitchToDeliveriesCalendarView } from 'utils/deliveriesViewContext';

export default function CalendarViewLink() {
  const { t } = useTranslation();
  const switchToCalendarView = useSwitchToDeliveriesCalendarView();

  function onClick(e) {
    e.preventDefault();
    switchToCalendarView();
  }

  return (
    <a href="#calendarView" className="align-self-center d-flex ms-3" onClick={onClick}>
      <i className="x-icon x-icon-calendar me-1" />
      {t('routes.batches.default.index.calendar_view')}
    </a>
  );
}
