import { useCallback, useEffect } from 'react';
import { useVerdictRequestHeaders } from 'utils/sessionHelpers';

window.globals ||= {};
window.globals.deliveriesCache ||= {};

const API_URL = window.env.REACT_APP_BASE_API_URL;
const BASE_URL = new URL('deliveries', API_URL);

function buildSearchParamsString(params = {}) {
  let result = '';
  if (!(params instanceof Object) || Object.keys(params).length === 0) return result;

  const searchParams = new URLSearchParams(params);
  result = searchParams.toString();

  return result;
}

export function useFetchAllDeliveries() {
  const headers = useVerdictRequestHeaders();

  // Clear cache on headers update.
  useEffect(() => { window.globals.deliveriesCache = {}; }, [headers]);

  return useCallback(async (params = {}) => {
    try {
      const searchParams = buildSearchParamsString(params);
      const url = [BASE_URL, searchParams].filter((s) => s).join('?');
      const response = await fetch(url, { headers });
      if (!response.ok) return null;

      const { data } = await response.json();
      window.globals.deliveriesCache.all = data;
      return data;
    } catch {
      return null;
    }
  }, [headers]);
}

export function useAllDeliveries() {
  const fetchAll = useFetchAllDeliveries();

  return useCallback(async () => {
    if (window.globals.deliveriesCache.all) return window.globals.deliveriesCache.all;

    const deliveries = await fetchAll();
    return deliveries;
  }, [fetchAll]);
}

export function useAllDeliveriesById() {
  const all = useAllDeliveries();

  return useCallback(async () => {
    if (window.globals.deliveriesCache.allById) return window.globals.deliveriesCache.allById;

    const allById = {};
    const deliveries = (await all()) || [];
    deliveries.forEach((delivery) => { allById[delivery.id] = delivery; });

    return allById;
  }, [all]);
}

// TODO:
// Optimize. Use a separate endpoint to fetch a single delivery.
export function useFindDelivery() {
  const headers = useVerdictRequestHeaders();
  const allById = useAllDeliveriesById();

  return useCallback(async (id) => {
    const byId = await allById();
    const delivery = (id in byId) ? byId[id] : null;

    return delivery;
  }, [headers]);
}
