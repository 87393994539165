import {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ASNExcelSheet from 'classes/asn_excel_sheet';
import Progress from 'components/Progress';
import ServerError from 'components/ServerError';
import Announcement from 'classes/announcement';
import { useCreateBatch } from 'api/Batches';
import { formatForAPI, totalQuantity, averageValue } from 'utils/batches';
import { usePostToAPI } from 'utils/forms';

const PROGRESS_ANIMATION_DURATION = 1000; // 1 second

export default function Create() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formData, submitted } = { ...location.state };
  const [error, setError] = useState(null);
  const createBatch = useCreateBatch();

  // TODO:
  // Refactor this. We are only doing this so that item properties can be accessed
  // without the _ (underscore) prefix.
  //
  // Cast items so that they will be instances of the Announcement class.
  formData.items = useMemo(() => formData.items.map((i) => new Announcement(i)), [formData.items]);

  document.title = t('routes.batches.default.create.document_title');

  const quantity = useMemo(() => totalQuantity(formData.items), [formData.items]);
  const displayQuantity = Intl.NumberFormat('en-EN').format(quantity);
  const displayAverageValue = averageValue(formData, quantity);
  const flashParams = { quantity: displayQuantity, averageValue: displayAverageValue };
  const hashFormData = useMemo(() => {
    const hashAnnouncements = formData.items.map((item) => {
      const otherHeaders = Object.keys(item.properties).filter((header) => !ASNExcelSheet.allHeaders.includes(header));
      const regularProperties = formatForAPI(item, ASNExcelSheet.allHeaders);
      const otherProperties = formatForAPI(item, otherHeaders);
      return { ...regularProperties, custom_attributes: otherProperties };
    });

    return { ...formData, items: hashAnnouncements };
  }, [formData]);

  const batchSignature = useMemo(() => ({
    deliveredOn: formData.delivered_on,
    deliveryTime: formData.delivery_time,
    reference: formData.reference,
  }), [formData.delivered_on, formData.delivery_time, formData.reference]);

  const onSuccessRedirect = useCallback(() => {
    navigate('/batches/create', { replace: true, state: { formData, submitted: true } });

    const flash = { info: t('routes.batches.default.flash.confirmation', flashParams) };
    navigate('/batches', { state: { flash, ...batchSignature } });
  }, [formData, flashParams, batchSignature]);

  const onRequestFailure = useCallback((result) => {
    setError(t(
      'components.server_error.response_error',
      { statusText: result.statusText, details: result.json.errors.details[0] }
    ));
  }, []);

  const postToAPI = usePostToAPI({
    onSuccessRedirect,
    onRequestFailure,
    createBatch,
    hashFormData,
    setError,
  });

  const onCancel = useCallback(() => {
    navigate(
      '/batches/new',
      { state: { flash: { info: t('routes.batches.default.flash.cancel', { reference: formData.reference }) } } }
    );
  }, [formData]);

  const clearError = useCallback(() => setError(null), [setError]);

  if (submitted) return null;

  if (error) {
    return (
      <ServerError
        title={t('components.server_error.title')}
        onRetry={clearError}
        onCancel={onCancel}
        formData={formData}
        body={error}
      />
    );
  }

  return (
    <Progress
      title={t('components.progress.announcing.title')}
      body={t('components.progress.announcing.body', flashParams)}
      minAnimationDuration={PROGRESS_ANIMATION_DURATION}
      afterAnimation={postToAPI}
    />
  );
}
