import { useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Unauthorized from 'components/session/Unauthorized';
import { CurrentOwnerContext } from 'utils/currentOwner';

// Continuously check for owner data availability
const SESSION_AVAILABILITY_RETRY = 250;

export default function Private(props) {
  const { currentOwner, setCurrentOwner } = useContext(CurrentOwnerContext);
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  useEffect(() => {
    if (currentOwner === null) {
      const timer = setInterval(() => {
        const sessionOwner = JSON.parse(localStorage.getItem('currentOwner'));
        if (sessionOwner !== null) {
          setCurrentOwner({ ...sessionOwner });
          clearInterval(timer);
        }
      }, SESSION_AVAILABILITY_RETRY);
    }
  }, []);

  return (
    <>
      <AuthenticatedTemplate>{currentOwner && props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthorized path={currentPath} />
      </UnauthenticatedTemplate>
    </>
  );
}

Private.propTypes = { children: PropTypes.element };
Private.defaultProps = { children: null };
