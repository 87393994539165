// Placeholder for /batches route
import { Outlet } from 'react-router-dom';
import Private from 'components/layouts/Private';

export default function Batches() {
  return (
    <Private>
      <Outlet />
    </Private>
  );
}
