import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';

export default function Header(props) {
  const [isHovered, setIsHovered] = useState(false);
  const icon = isHovered ? 'x-icon-question-circle-inverted' : 'x-icon-question-circle';

  function onMouseOver() {
    setIsHovered(true);
  }

  function onMouseOut() {
    setIsHovered(false);
  }

  return (
    <div className={[props.className, styles.default].join(' ')}>
      <h1 className="p-0 m-0">
        {props.title}
        <button
          type="button"
          id="page-help-icon"
          aria-label="contextual-help-link"
          onMouseOver={onMouseOver}
          onFocus={onMouseOver}
          onMouseOut={onMouseOut}
          onBlur={onMouseOut}
          className={`x-icon ${icon} ${styles['help-icon']}`}
        />
      </h1>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: null,
};
