import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PowerBiTable from 'components/PowerBiTable';
import Header from 'components/Header';
import { CurrentOwnerContext } from 'utils/currentOwner';
import styles from './Index.module.scss';

const PAGE_NAME = window.env.REACT_APP_POWER_BI_INVENTORY_REPORT_PAGE_NAME;
const OWNER_NAME_FIELD = 'Items/Owner';

export default function Index() {
  const { t } = useTranslation();
  const { currentOwner } = useContext(CurrentOwnerContext);
  const filters = useMemo(() => ({ [OWNER_NAME_FIELD]: currentOwner.name }), [currentOwner]);

  document.title = t('routes.reports.items.default.index.document_title');

  return (
    <div className={styles.default}>
      <Header
        className={styles.header}
        title={t('routes.reports.items.default.index.header')}
      />
      <PowerBiTable pageName={PAGE_NAME} filters={filters} />
    </div>
  );
}
