import { createRef } from 'react';
import classes from './Ellipsis.module.scss';

export default function Ellipsis() {
  const ellipsis = createRef();
  const values = ['  ', '.  ', '..  ', '...', '.. ', '.  '];
  let i = 0;

  setInterval(() => {
    if (ellipsis.current) {
      ellipsis.current.innerHTML = values[i % values.length];
    }
    i++;
  }, 200);

  return (
    <span className={classes.ellipsis} ref={ellipsis}>...</span>
  );
}
