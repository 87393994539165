import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import styles from './TimeSlot.module.scss';

export default function TimeSlot({
  name,
  dateString,
  delivery,
  renderContent,
}) {
  return (
    <div className={`${styles.default} row g-0`} data-key={`${dateString}-${name}`}>
      {renderContent({ dateString, name, delivery })}
    </div>
  );
}

TimeSlot.propTypes = {
  name: PropTypes.oneOf(['morning', 'afternoon']).isRequired,
  dateString: PropTypes.string.isRequired,
  delivery: PropTypes.instanceOf(Delivery),
  renderContent: PropTypes.func.isRequired,
};

TimeSlot.defaultProps = {
  delivery: null,
};
