import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from 'components/orders/OrdersCollection.module.scss';

const HIDE_FLASH_MESSAGE_DELAY = 2000;

export default function RemoveOrderLine(props) {
  const [rowStyle, setRowStyle] = useState();

  useEffect(() => {
    setTimeout(() => setRowStyle(styles.fadeOut), HIDE_FLASH_MESSAGE_DELAY);
  }, []);

  return (
    <tr onTransitionEnd={props.afterFadeOut} className={rowStyle}>
      <td className={styles.remove} colSpan={5}>
        {props.message}
      </td>
    </tr>
  );
}

RemoveOrderLine.propTypes = {
  afterFadeOut: PropTypes.func.isRequired,
  message: PropTypes.string,
};

RemoveOrderLine.defaultProps = {
  message: null,
};
