import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './WizardSteps.module.scss';

function WizardSteps(props) {
  const { t } = useTranslation();
  return (
    <div className={styles['wizard-steps']}>
      <ul>
        <li className={props.step === 1 ? styles.active : styles.inactive}>
          {t('components.wizard_steps.step_1')}
        </li>
        <li className={props.step === 2 ? styles.active : styles.inactive}>
          {t('components.wizard_steps.step_2')}
        </li>
        {props.withScheduleStep
          && (
            <li className={props.step === 3 ? styles.active : styles.inactive}>
              {t('components.wizard_steps.step_3')}
            </li>
          )}
      </ul>
    </div>
  );
}

WizardSteps.propTypes = {
  step: PropTypes.number.isRequired,
  withScheduleStep: PropTypes.bool,
};

WizardSteps.defaultProps = {
  withScheduleStep: true,
};

export default WizardSteps;
