import {
  createContext,
  useEffect,
  useContext,
  useState,
  useMemo,
} from 'react';

export const LayoutExtraClassNameContext = createContext({ layoutExtraClassName: null, setLayoutExtraClassName: null });

export function useLayoutExtraClassNameContextValue() {
  const [layoutExtraClassName, setLayoutExtraClassName] = useState('');

  return useMemo(
    () => ({ layoutExtraClassName, setLayoutExtraClassName }),
    [layoutExtraClassName, setLayoutExtraClassName]
  );
}

export function useLayoutExtraClassName(className) {
  const { setLayoutExtraClassName } = useContext(LayoutExtraClassNameContext);

  useEffect(() => {
    setLayoutExtraClassName(className);
    return () => setLayoutExtraClassName('');
  });
}
