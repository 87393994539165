import PropTypes from 'prop-types';
import styles from './PalletCount.module.scss';

export default function PalletCount(props) {
  return (
    <div className="d-flex flex-column align-items-center" style={props.style}>
      <div className={styles.palletCount}>{props.count || '?'}</div>
      <img src="/icons/pallet.svg" alt="pallet icon" title="pallet count" width="19" height="5" />
    </div>
  );
}

PalletCount.propTypes = {
  count: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.string),
};
PalletCount.defaultProps = { count: null, style: null };
