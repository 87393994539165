import parseMoney from 'parse-money';
import { Ean13Utils } from 'ean13-lib';
import i18n from 'i18n/config';
import Property from './property';

export default class Announcement {
  constructor({ _properties, _errors } = {}) {
    this._properties = {};
    this._errors = _errors || {};

    if (_properties) {
      Object.keys(_properties).forEach((k) => {
        const property = _properties[k];
        // eslint-disable-next-line no-underscore-dangle
        const value = property._raw;
        this.setProperty(k, value);
      });
    }
  }

  static parseMoney = parseMoney;

  get properties() {
    return this._properties;
  }

  get errors() {
    return this._errors;
  }

  get errorMessages() {
    return Object.values(this.errors);
  }

  setProperty(key, value) {
    // eslint-disable-next-line no-underscore-dangle
    this._properties[key] = new Property(value, value ? this.constructor._parse(key, value) : null);
  }

  validate() {
    this._validateReferences();
    this._validateProductTitle();
    this._validateRetailPrice();
    this._validateQuantity();
    return !this._hasErrors();
  }

  _hasErrors() {
    return Object.keys(this._errors).length > 0;
  }

  _setError(key, errorId) {
    if (errorId) {
      this._errors[key] = i18n.t(`classes.announcement.errors.${errorId}`);
    } else {
      delete this._errors[key];
    }
  }

  _clearError(key) {
    this._setError(key, null);
  }

  _validateReferences() {
    this._clearError('ean');
    this._clearError('asin');
    this._clearError('reference');
    const { properties } = this;

    if (!properties.ean.raw && !properties.asin.raw && !properties['other reference'].raw) {
      this._setError('reference', 'no_reference');
    } else {
      this._validateEAN();
      this._validateAsin();
    }
  }

  _validateEAN() {
    this._clearError('ean');
    const { properties } = this;

    if (properties.ean.raw) {
      if (properties.ean.parsed.replace(/\D/g, '').length !== 13) {
        this._setError('ean', 'ean_not_13');
      } else if (!Ean13Utils.validate(properties.ean.parsed)) {
        this._setError('ean', 'ean_invalid');
      }
    }
  }

  _validateAsin() {
    this._clearError('asin');

    if (this.properties.asin.raw) {
      if (this.properties.asin.parsed.length !== 10) {
        this._setError('asin', 'asin_not_10');
      } else if (!this.properties.asin.parsed.match(/^[BX]\d{1}\w{8}|\d{9}(X|\d){10}$/)) {
        this._setError('asin', 'asin_invalid');
      }
    }
  }

  _validateProductTitle() {
    this._clearError('product title', null);
    if (!this.properties['product title'].raw) {
      this._setError('product title', 'no_product_name');
    }
  }

  _validateRetailPrice() {
    this._clearError('retail price');
    if (!this.properties['retail price'].raw) {
      this._setError('retail price', 'no_product_price');
    } else if (!this.properties['retail price'].parsed) { // parsing failed
      this._setError('retail price', 'invalid_product_price');
    }
  }

  _validateQuantity() {
    this._clearError('quantity');
    if (!this.properties.quantity.raw) {
      this._setError('quantity', 'no_quantity');
    } else if (!this.properties.quantity.parsed) { // parsing failed
      this._setError('quantity', 'invalid_quantity');
    }
  }

  static _parse(key, value) {
    if (key === 'retail price' || key === 'owner price') {
      let price = null;
      try {
        price = this.parseMoney(Number(value).toFixed(2)).amount;
        return { currency: 'EUR', amount: price };
      } catch {
        return null;
      }
    }

    if (key === 'quantity') {
      return parseInt(value) || null;
    }

    if (key === 'pallet reference') {
      return value?.toString().trim() || null;
    }

    return value.toString();
  }
}
