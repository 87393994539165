import { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from './FormElement.module.scss';
import FormElement from './FormElement';

function FileField(props) {
  const input = useRef(null);
  const filenameLabel = useRef(null);
  const { t } = props;

  const triggerFileUpload = useCallback(() => input.current.click(), [input]);

  const onChange = useCallback(() => {
    const file = input.current.files[0] || null;
    const fileName = file?.name;

    filenameLabel.current.placeholder = fileName;
    if (props.onChange) props.onChange(file);
  }, [input, filenameLabel, props.onChange]);

  // We use a hidden file input field because the text within a file input by default uses the browser language
  // we want to use our own locales for the file upload input so that's why we reproduce it with our own locales
  return (
    <FormElement id={props.id} label={props.label} errors={props.errors} formText={props.formText} size={props.size}>
      <div className="input-icon mb-2 d-flex">
        <input
          ref={filenameLabel}
          id="visibleFileInput"
          type="text"
          className={`form-control ${styles['file-input']}`}
          placeholder={t('components.form.file_input_placeholder')}
          readOnly
          onClick={triggerFileUpload}
        />
        <button type="button" className={`input-group-text ${styles['file-input-browse']}`} onClick={triggerFileUpload}>
          {t('components.form.file_input_select')}
        </button>
      </div>

      <input
        ref={input}
        id={props.id}
        data-testid="announcement_field"
        className={styles['hidden-file-input']}
        type="file"
        onChange={onChange}
        required={props.required}
      />

      {props.children}
    </FormElement>
  );
}

FileField.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  formText: PropTypes.any,
  size: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

FileField.defaultProps = {
  id: '',
  label: '',
  errors: {},
  formText: null,
  size: '',
  children: [],
  onChange: null,
  required: false,
};

export default withTranslation()(FileField);
