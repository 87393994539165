import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const enTranslations = require('./locales/en/translations.json');
const nlTranslations = require('./locales/nl/translations.json');

// Initialize locale setup
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'nl',
    lng: 'en',
    resources: {
      en: {
        translations: enTranslations,
      },
      nl: {
        translations: nlTranslations,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: { escapeValue: false },
    debug: window.env.REACT_APP_LOG_LEVEL === 'debug',
  });

// Available languages
i18n.languages = ['en', 'nl'];

export default i18n;
