import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import Announcement from 'classes/announcement';
import Button from 'components/form/Button';
import Table from 'components/Table';
import RepairItemErrors from './RepairItemErrors';
import RepairItemTableBody from './RepairItemTableBody';
import RepairItemTableHeader from './RepairItemTableHeader';
import FlashMessage from './repair_item/FlashMessage';
import useAfterFadeOut from './repair_item/useAfterFadeOut';
import styles from './RepairItem.module.scss';

// TODO: Refactor to remove `.getElementsByTagName`
function useAnnouncementValidator(announcement) {
  const form = useRef(null);
  const validateAnnouncement = useCallback(() => {
    const el = form.current;
    const updatedAttrs = [...el.getElementsByTagName('input')];
    updatedAttrs.forEach((attr) => announcement.setProperty(attr.name, attr.value));

    return announcement.validate();
  }, [announcement, form.current]);

  return { validateAnnouncement, form };
}

export default function RepairItem(props) {
  const { announcement } = props;
  const [errors, setErrors] = useState(announcement.errorMessages);
  const [fadeOut, setFadeOut] = useState(null);
  const [readyForRetry, setReadyForRetry] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const { validateAnnouncement, form } = useAnnouncementValidator(announcement);
  const markAsValid = useCallback(() => props.moveToValidAnnouncements(announcement, props.announcementIndex), [props]);
  const { element, afterFadeOut } = useAfterFadeOut(markAsValid);
  const overflowClass = (props.ownerOwnsDepot) ? styles['overflow-x-scroll'] : '';

  const onHideFlash = useCallback(() => {
    if (isFixed) {
      setFadeOut(styles.fadeOut);
    } else {
      setErrors(announcement.errorMessages);
      setReadyForRetry(true);
    }
  }, [props, isFixed]);

  const retry = useCallback((event) => {
    event.preventDefault();
    setReadyForRetry(false);
    if (validateAnnouncement()) setIsFixed(true);
  }, [props]);

  return (
    <div
      ref={element}
      className={`repair-item clearfix ${styles['repair-item']} ${fadeOut}`}
      onTransitionEnd={afterFadeOut}
      data-testid="repair-item"
    >
      <RepairItemErrors errors={errors} />

      <form ref={form} onSubmit={retry}>
        <div role="grid" className={`card ${styles['repair-table']} ${overflowClass}`}>
          { !readyForRetry && <FlashMessage onHide={onHideFlash} fixed={isFixed} /> }

          <Table className="table-bordered mb-0">
            <RepairItemTableHeader ownerOwnsDepot={props.ownerOwnsDepot} />
            <RepairItemTableBody announcement={announcement} ownerOwnsDepot={props.ownerOwnsDepot} />
          </Table>
        </div>

        <Button
          disabled={!readyForRetry}
          onClick={retry}
          className="btn btn-outline-primary mt-3 float-end px-5"
          icon="linear/0730-redo2.svg"
          label="Retry"
        />
      </form>
    </div>
  );
}

RepairItem.propTypes = {
  announcement: PropTypes.instanceOf(Announcement).isRequired,
  announcementIndex: PropTypes.number.isRequired,
  moveToValidAnnouncements: PropTypes.func.isRequired,
  ownerOwnsDepot: PropTypes.bool.isRequired,
};
