import PropTypes from 'prop-types';
import styles from './Card.module.scss';

export default function Card(props) {
  return (
    <div>
      <div className={styles.background} />
      <div className={`align-middle ${styles.card}`}>
        {props.children}
      </div>
    </div>
  );
}

Card.propTypes = { children: PropTypes.element };
Card.defaultProps = { children: null };
