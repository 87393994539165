import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from './FormElement.module.scss';

function FormElement(props) {
  const { t } = props;

  return (
    <div className={props.errors[props.id] ? `mb-4 ${classes.error}` : 'mb-4'}>
      <label htmlFor={props.id} className={classes['form-label']}>
        {t(`components.form.${props.id}`)}
      </label>
      <div className="d-flex flex-row">
        <div className={props.size === 'small' ? classes['field-small'] : classes.field}>
          {props.children}
          <div className="form-text">{props.formText}</div>
        </div>
        <div className={classes['error-message']}>{props.errors[props.id]}</div>
      </div>
    </div>
  );
}

FormElement.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  id: PropTypes.string,
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formText: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
  ]),
};

FormElement.defaultProps = {
  errors: {},
  id: '',
  size: '',
  formText: '',
  children: null,
};

export default withTranslation()(FormElement);
