import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import Header from 'components/Header';
import styles from './ReportHeader.module.scss';

function LockIcon(props) {
  const icon = props.isLocked ? 'x-icon-lock-close' : 'x-icon-lock-open';
  return <i title="icon" className={`x-icon ${icon}`} />;
}

LockIcon.propTypes = { isLocked: PropTypes.bool.isRequired };

function ReportHeader(props) {
  const { t } = props;
  const text = t('components.batches.default.report_header', { reference: props.reference });

  return (
    <div className="d-flex pt-0 align-items-start">
      <Header className="pb-1" title={text} />
      <div className={`d-flex ms-3 fs-5 ${styles.marginTop}`}>
        <span className="me-1">{props.deliveryDate}</span>
        <span className="me-2">{props.deliveryTime}</span>
        <span><LockIcon isLocked={props.isLocked} /></span>
      </div>
    </div>
  );
}

ReportHeader.propTypes = {
  t: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
};

export default withTranslation()(ReportHeader);
