import {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveriesViewContext, DELIVERIES_VIEWS } from 'utils/deliveriesViewContext';
import Flash from 'components/Flash';
import ListView from './deliveries_collection/ListView';
import CalendarView from './deliveries_collection/CalendarView';

export default function DeliveriesCollection() {
  const { t } = useTranslation();
  const [flash, setFlash] = useState(null);
  const { deliveriesView } = useContext(DeliveriesViewContext);
  const onCalendarView = deliveriesView === DELIVERIES_VIEWS.CALENDAR;

  const onError = useCallback(() => {
    const error = t('routes.batches.default.flash.request_error');
    setFlash({ error });
  });

  // Remove current flash message on change view.
  useEffect(() => setFlash(null), [deliveriesView]);

  return (
    <>
      <Flash flash={flash} />
      {onCalendarView ? <CalendarView onError={onError} /> : <ListView onError={onError} /> }
    </>
  );
}
