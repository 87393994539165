import PropTypes from 'prop-types';
import classes from './ConveyorAnimation.module.scss';

export default function ConveyorAnimation(props) {
  const state = (props.play === false) ? 'paused' : 'playing';

  return (
    <div className={classes.default}>
      <img className={`${classes.animation} ${classes[state]}`} alt="" src="/conveyor.svg" />
    </div>
  );
}

ConveyorAnimation.propTypes = { play: PropTypes.bool };
ConveyorAnimation.defaultProps = { play: true };
