import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import styles from './DeliveryCard.module.scss';
import DeliveryIcon from './delivery_card/DeliveryIcon';

export default function DeliveryCard({ delivery, children }) {
  const statusStyle = delivery.isConfirmed() ? 'confirmed' : 'open';
  const { t } = useTranslation();

  return (
    <div className={styles.default}>
      <div className={`${styles.card} ${styles[statusStyle]}`}>
        <div>{ delivery.hasBatches() ? children : t('components.delivery_card.empty_delivery') }</div>
        <DeliveryIcon delivery={delivery} />
      </div>
    </div>
  );
}

DeliveryCard.propTypes = {
  delivery: PropTypes.instanceOf(Delivery).isRequired,
  children: PropTypes.element,
};

DeliveryCard.defaultProps = { children: null };
