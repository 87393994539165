import { PropTypes } from 'prop-types';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import WithSidebar from './layouts/WithSidebar';
import { LayoutExtraClassNameContext, useLayoutExtraClassNameContextValue } from './layouts/extraClassName';

export default function PageLayout(props) {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <LayoutExtraClassNameContext.Provider value={useLayoutExtraClassNameContextValue()}>
          <WithSidebar>{props.children}</WithSidebar>
        </LayoutExtraClassNameContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>{props.children}</UnauthenticatedTemplate>
    </div>
  );
}

PageLayout.propTypes = { children: PropTypes.element.isRequired };
