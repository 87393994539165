import PropTypes from 'prop-types';
import {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';

const RESIZE_DELAY = 250;

function executeNowAndOnWindowResize(callback) {
  callback();

  let resizeId = null;
  const delayCallbackExecution = () => {
    clearTimeout(resizeId);
    resizeId = setTimeout(callback, RESIZE_DELAY);
  };

  window.addEventListener('resize', delayCallbackExecution);
  return () => window.removeEventListener('resize', delayCallbackExecution);
}

function onResizeOf(element, callback) {
  let resizeId = null;

  const resizeObserver = new ResizeObserver(() => {
    clearTimeout(resizeId);
    resizeId = setTimeout(callback, RESIZE_DELAY);
  });

  resizeObserver.observe(element);
  return () => resizeObserver.unobserve(element);
}

function useEffectOnSizeChange({ effect, size }) {
  const lastBroadcastedSize = useRef({ width: null, height: null });

  useEffect(() => {
    if (!effect) return;

    // Do not broadcast if just the same size with the previous one.
    const lastSize = lastBroadcastedSize.current;
    if (lastSize.width === size.width && lastSize.height === size.height) return;

    effect(size);
    lastBroadcastedSize.current = size;
  }, [size]);
}

// This component will increase its height to fill-in the empty space until the bottom of the window.
// Use props.marginBottom to add a space between this component and the bottom of the window.
export default function FitToView(props) {
  const el = useRef(null);
  const [windowHeight, setWindowHeight] = useState(0);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const widthLimits = useMemo(() => ({ minWidth: props.minWidth, maxWidth: props.maxWidth }), []);

  const updateSize = useCallback(() => {
    if (el.current === null) return;

    const { width, top } = el.current.getBoundingClientRect();
    const height = windowHeight - top - props.marginBottom;

    // Prevent duplicate size update.
    if (size.width !== width || size.height !== height) setSize({ width, height });
  }, [size, windowHeight]);

  // Keep track of window height.
  useLayoutEffect(() => executeNowAndOnWindowResize(() => setWindowHeight(window.innerHeight)), [setWindowHeight]);

  // Keep track of change in element size that is not caused by window resize.
  // Example: When sidebar is opened or closed.
  useEffect(() => onResizeOf(el.current, updateSize), [updateSize]);

  useEffectOnSizeChange({ effect: props.onUpdate, size });

  return (
    <div ref={el} style={{ ...widthLimits, height: size.height }}>
      {size.height > 0 && props.children}
    </div>
  );
}

FitToView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  marginBottom: PropTypes.number,
  onUpdate: PropTypes.func,
};

FitToView.defaultProps = {
  children: null,
  maxWidth: null,
  minWidth: null,
  marginBottom: 0,
  onUpdate: null,
};
