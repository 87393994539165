import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'snake-case';

const VALIDATING_ANIMATION_DURATION = 2000;

// TODO: Verify: If there's only 1 row, this should not be applicable, as row can be repaired
// find errors that occur on every announcement
export function columnErrorMessages(announcements) {
  const errorCounts = announcements.flatMap((announcement) => announcement.errorMessages).reduce(
    (hash, message) => {
      hash[message] ||= 0;
      hash[message]++;
      return hash;
    },
    {}
  );

  return Object.keys(errorCounts).filter((msg) => errorCounts[msg] === announcements.length);
}

function validateAnnouncements(announcements) {
  const [validAnnouncements, invalidAnnouncements] = announcements.reduce((total, current) => {
    if (current.validate() === true) {
      total[0].push(current);
    } else {
      total[1].push(current);
    }

    return total;
  }, [[], []]);

  return [validAnnouncements, invalidAnnouncements];
}

function validateRequiredFields(formData, isPalletCountRequired, t) {
  const errors = {};

  if (!formData.reference) errors.reference = t('routes.batches.default.new.errors.missing_reference');
  if (!formData.items) errors.announcement_field = t('routes.batches.default.new.errors.missing_file');

  if (isPalletCountRequired && !formData.pallet_count) {
    errors.pallet_count = t('routes.batches.default.new.errors.missing_pallet_count');
  }

  return errors;
}

export function formatForAPI(item, headers) {
  const keyTranslations = { 'retail price': 'product price', 'product title': 'product name' };

  return headers.reduce((formattedAnnouncement, key) => {
    const newKey = keyTranslations[key] || key;
    if (key in item.properties) {
      return { ...formattedAnnouncement, [snakeCase(newKey)]: item.properties[key].parsed };
    }

    return formattedAnnouncement;
  }, {});
}

export function totalQuantity(items) {
  return items.reduce((total, item) => total + item.properties.quantity.parsed, 0);
}

export function averageValue(formData, quantity) {
  const average = useMemo(() => {
    if (formData.items.length === 0) return null;

    const amounts = formData.items.map((item) => {
      const retailPrice = item.properties['retail price'].parsed.amount;
      const qty = parseInt(item.properties.quantity.parsed);
      return retailPrice * qty;
    });

    const sum = amounts.reduce((price, total) => total + price, 0);
    return new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'EUR' }).format(sum / quantity);
  }, [formData.items, quantity]);

  return average;
}

// Prevent form submit if all announcements exhibits a common error
function showColumnErrorsIfAny(formData, setState, setBaseErrors, fieldErrors, t) {
  const columnErrors = columnErrorMessages(formData.items);

  if (columnErrors.length === 0) return false;

  setState({ name: 'form', formData });
  setBaseErrors({ messages: columnErrors, title: t('routes.batches.default.new.all_items_error_title') });
  fieldErrors.current
    .set('announcement_field', 'problematic', t('routes.batches.default.new.errors.problematic_file'))
    .commit();

  return true;
}

export function useOnSubmit({
  moveToRepair,
  moveToNextStep,
  showBatchErrorsIfAny,
  isPalletCountRequired,
  setBaseErrors,
  setState,
  fieldErrors,
}) {
  const { t } = useTranslation();

  return useCallback((formData) => {
    setBaseErrors({});
    fieldErrors.current
      .remove('reference', 'blank')
      .remove('pallet_count', 'blank')
      .remove('announcement_field', 'blank');

    const blankFieldsErrors = validateRequiredFields(formData, isPalletCountRequired, t);
    Object.entries(blankFieldsErrors).forEach(([field, error]) => {
      if (error) fieldErrors.current.set(field, 'blank', error);
    });

    fieldErrors.current.commit();
    if (fieldErrors.current.hasEntries()) return;

    setState({ name: 'validatingItems' });
    setTimeout(() => {
      const [validAnnouncements, invalidAnnouncements] = validateAnnouncements(formData.items);
      const hasErrors = showColumnErrorsIfAny(formData, setState, setBaseErrors, fieldErrors, t)
                        || showBatchErrorsIfAny(formData);

      if (hasErrors) return;

      if (invalidAnnouncements.length > 0) {
        moveToRepair(formData, validAnnouncements, invalidAnnouncements);
      } else {
        moveToNextStep(formData);
      }
    }, VALIDATING_ANIMATION_DURATION);
  });
}
