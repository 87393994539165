import PropTypes from 'prop-types';
import {
  useState,
  useMemo,
  useCallback,
  useContext,
  createContext,
} from 'react';

export const DELIVERIES_VIEWS = {
  LIST: 0,
  CALENDAR: 1,
};

const STORAGE_KEY = 'deliveriesView';
const DEFAULT_VIEW = DELIVERIES_VIEWS.LIST;

export const DeliveriesViewContext = createContext({ value: DEFAULT_VIEW, setValue: () => {} });

function useDeliveriesViewState(initialValue) {
  const rawValue = initialValue || localStorage.getItem(STORAGE_KEY) || DEFAULT_VIEW;
  const initialView = JSON.parse(rawValue);
  const [deliveriesView, baseSetter] = useState(initialView);

  // Override baseSetter so that localStorage will be updated too.
  const setDeliveriesView = useCallback((view) => {
    baseSetter(view);
    localStorage.setItem(STORAGE_KEY, view);
  }, [baseSetter]);

  return [deliveriesView, setDeliveriesView];
}

export function useSwitchToDeliveriesListView() {
  const { setDeliveriesView } = useContext(DeliveriesViewContext);

  return useCallback(() => {
    setDeliveriesView(DELIVERIES_VIEWS.LIST);
  }, [setDeliveriesView]);
}

export function useSwitchToDeliveriesCalendarView() {
  const { setDeliveriesView } = useContext(DeliveriesViewContext);

  return useCallback(() => {
    setDeliveriesView(DELIVERIES_VIEWS.CALENDAR);
  }, [setDeliveriesView]);
}

export function DeliveriesViewContextWrapper({ children, initialView }) {
  const [deliveriesView, setDeliveriesView] = useDeliveriesViewState(initialView);
  const contextValue = useMemo(() => ({ deliveriesView, setDeliveriesView }), [deliveriesView, setDeliveriesView]);

  return (
    <DeliveriesViewContext.Provider value={contextValue}>
      {children}
    </DeliveriesViewContext.Provider>
  );
}

DeliveriesViewContextWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  initialView: PropTypes.oneOf(Object.values(DELIVERIES_VIEWS)),
};

DeliveriesViewContextWrapper.defaultProps = { initialView: null };
