import { useCallback } from 'react';
import { useVerdictRequestHeaders } from 'utils/sessionHelpers';

const API_URL = window.env.REACT_APP_BASE_API_URL;
const BASE_URL = new URL('batches', API_URL);

export function useCreateBatch() {
  const headers = useVerdictRequestHeaders();

  return useCallback(async (data = {}) => {
    let response = {};
    try {
      response = await fetch(BASE_URL, {
        headers,
        method: 'POST',
        body: JSON.stringify(data),
      });

      const json = await response.json();
      return { success: response.ok, statusText: response.statusText, json };
    } catch (error) {
      return { success: false, statusText: response.statusText, json: null };
    }
  }, [headers]);
}

export function useUpdateBatch(reference) {
  const headers = useVerdictRequestHeaders();

  return useCallback(async (data = {}) => {
    const url = new URL('batches', API_URL);
    let response = {};
    data.reference = reference;

    try {
      response = await fetch(url, {
        headers,
        method: 'PATCH',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        return { success: response.ok, statusText: response.statusText };
      }
      // when response does not have any body, .json() will raise an exception
      // so we only call it when response.ok !== true
      const { errors } = await response.json();
      return { success: false, statusText: response.statusText, errors };
    } catch (error) {
      return { success: false, statusText: response.statusText, error };
    }
  }, [headers, reference]);
}

export function useDeleteBatch(reference) {
  const headers = useVerdictRequestHeaders();

  return useCallback(async () => {
    const url = new URL('batches', API_URL);
    let response = {};

    try {
      response = await fetch(url, {
        headers,
        method: 'DELETE',
        body: JSON.stringify({ reference }),
      });

      if (response.ok) {
        return { success: response.ok, statusText: response.statusText };
      }
      // when response does not have any body, .json() will raise an exception
      // so we only call it when response.ok !== true
      const { errors } = await response.json();
      return { success: false, statusText: response.statusText, errors };
    } catch (error) {
      return { success: false, statusText: response.statusText, error };
    }
  }, [headers, reference]);
}

export function useCheckReferenceAvailability() {
  const headers = useVerdictRequestHeaders();

  return useCallback(async (reference) => {
    const url = new URL(`batches/reference_availability?reference=${reference}`, API_URL);
    let response = {};

    try {
      response = await fetch(url, { headers });
      const json = await response.json();

      if (response.ok) {
        return { success: true, statusText: response.statusText, json };
      }

      const { errors } = json;
      return { success: false, statusText: response.statusText, errors };
    } catch (error) {
      return { success: false, statusText: response.statusText, error };
    }
  }, [headers]);
}
