import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Options.module.scss';
import DeleteOption from './DeleteOption';
import UpdateDeliveryDateOption from './UpdateDeliveryDateOption';
import UpdatePalletCountOption from './UpdatePalletCountOption';
import EditPalletCount from './EditPalletCount';
import PalletCount from '../PalletCount';

export default function Options(props) {
  const [editPalletCount, setEditPalletCount] = useState(false);

  const showEditPalleCount = useCallback(() => {
    props.onAfterAjaxRequest();
    setEditPalletCount(true);
  }, [props.onAfterAjaxRequest, setEditPalletCount]);

  if (props.deliveryStatus === 'confirmed') {
    return (
      <div className="d-flex">
        <PalletCount count={props.palletCount} />
      </div>
    );
  }

  if (props.disabled) {
    return (
      <img
        src="/icons/linear/0014-pencil.svg"
        className={styles.disabled}
        alt="batch edit icon"
        title="batch edit"
        width="20"
        height="20"
      />
    );
  }

  if (editPalletCount) {
    return (
      <EditPalletCount
        palletCount={props.palletCount}
        reference={props.reference}
        deliveredOn={props.deliveredOn}
        deliveryTime={props.deliveryTime}
        afterEdit={() => setEditPalletCount(false)}
        onBeforeAjaxRequest={props.onBeforeAjaxRequest}
        onAfterAjaxRequest={props.onAfterAjaxRequest}
      />
    );
  }

  return (
    <div className="d-flex">
      <div className={`dropstart ${styles.dropdownMenu}`}>
        <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <img
            src="/icons/linear/0014-pencil.svg"
            className={styles.iconOption}
            alt="batch edit icon"
            title="batch edit"
            width="20"
            height="20"
          />
        </button>

        <ul className={`dropdown-menu ${styles.dropdownOptions}`}>
          <UpdatePalletCountOption onClick={showEditPalleCount} />
          <UpdateDeliveryDateOption deliveryId={props.deliveryId} reference={props.reference} />
          <DeleteOption
            itemCount={props.itemCount}
            reference={props.reference}
            onDeleteStart={props.onDeleteStart}
            onDeleteSuccess={props.onDeleteSuccess}
            onDeleteFailed={props.onDeleteFailed}
            onBeforeAjaxRequest={props.onBeforeAjaxRequest}
            onAfterAjaxRequest={props.onAfterAjaxRequest}
          />
        </ul>
      </div>
      <PalletCount count={props.palletCount} />
    </div>
  );
}

Options.propTypes = {
  palletCount: PropTypes.number,
  deliveryId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  deliveryStatus: PropTypes.oneOf(['confirmed', 'locked', 'unlocked']).isRequired,
  itemCount: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  deliveredOn: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
  onDeleteStart: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  onDeleteFailed: PropTypes.func.isRequired,
  onBeforeAjaxRequest: PropTypes.func.isRequired,
  onAfterAjaxRequest: PropTypes.func.isRequired,
};
Options.defaultProps = {
  palletCount: null,
};
