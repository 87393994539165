import { useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash.orderby';
import { useFetchAllOrders } from 'api/Orders';
import { useFetchAllCarriers } from 'api/Carriers';
import Flash from 'components/Flash';
import Table from 'components/Table';
import FilterOrders from 'components/orders/FilterOrders';
import OrderDetails from 'components/orders/OrderDetails';
import OrdersTableHeader from 'components/orders/OrdersTableHeader';
import OrderLine from 'components/orders/collection/OrderLine';
import RemoveOrderLine from 'components/orders/collection/RemoveOrderLine';
import styles from './OrdersCollection.module.scss';

// missing statuses: draft, allocate_stock, allocated, refund
const ORDER_STATUS = {
  new: ['allocated'],
  processed: ['processing_by_wms'],
  shipped: ['shipped', 'returned'],
};

// Filter by query and status
function filterOrders(orders, query, orderStatus, sort) {
  let matchedOrders = orderBy(orders, [sort.column], [sort.direction]);

  if (orders.length === 0) {
    return matchedOrders;
  }

  if (query) {
    const pattern = new RegExp(query, 'i');
    matchedOrders = matchedOrders.filter((order) => order.license_plate.match(pattern) !== null
      || order.title.match(pattern) !== null
      || order.ean.match(pattern) !== null
      || order.your_reference.match(pattern) !== null);
  }

  if (orderStatus) {
    matchedOrders = matchedOrders.filter((order) => ORDER_STATUS[orderStatus].includes(order.status));
  }

  return matchedOrders;
}

export default function OrdersCollection() {
  const { t } = useTranslation();
  const location = useLocation();
  const [flash, setFlash] = useState(location.state?.flash);
  const [orders, setOrders] = useState([]);
  const [fetchedOrders, setFetchedOrders] = useState({ new: false, processed: false, shipped: false });
  const [carriers, setCarriers] = useState([]);
  const [query, setQuery] = useState(null);
  const [orderStatus, setOrderStatus] = useState('new');
  const [sort, setSort] = useState({ column: 'order_date', direction: 'asc' });
  const fetchOrders = useFetchAllOrders();
  const fetchCarriers = useFetchAllCarriers();
  const [orderReference, setOrderReference] = useState(null);
  const [orderLineStatus, setOrderLineStatus] = useState(null);
  const [processedOrderReference, setProcessedOrderReference] = useState(null);
  const [shippedOrderReference, setShippedOrderReference] = useState(null);

  async function getOrder() {
    if (fetchedOrders[orderStatus]) {
      return;
    }
    const result = await fetchOrders(orderStatus);

    if (Array.isArray(result)) {
      setOrders(orderBy(orders.concat(result), [sort.column], [sort.direction]));
      setFetchedOrders((prevState) => ({ ...prevState, [orderStatus]: true }));
    } else {
      setFlash({ error: t('components.orders.orders_collection.flash.request_error') });
    }
  }

  const reverseSortDirection = useCallback((column) => {
    const direction = (sort.direction === 'asc') ? 'desc' : 'asc';
    setSort({ column, direction });
  }, [sort]);

  useEffect(() => (async () => getOrder(orderStatus))(), [fetchOrders, orderStatus]);

  useEffect(() => {
    (async () => {
      const result = await fetchCarriers();

      if (Array.isArray(result)) {
        setCarriers(result);
      } else {
        setFlash({ error: t('components.orders.orders_collection.flash.request_error') });
      }
    })();
  }, []);

  const filteredOrders = filterOrders(orders, query, orderStatus, sort);

  function updateOrderStatus(targetReference = '', status = '') {
    setOrders((prevOrders) => prevOrders.map((order) => (
      order.order_reference !== targetReference ? order : { ...order, status }
    )));
  }

  const removeProcessedFromOrders = useCallback((index) => {
    const targetReference = orderReference;

    // Auto select the next row for Marking as 'processed'. Ignore previous rows
    if (index >= 0 && filteredOrders[index + 1]) {
      setOrderReference(filteredOrders[index + 1].order_reference);
    } else {
      setOrderLineStatus(null);
      setOrderReference(null);
    }

    updateOrderStatus(targetReference, 'processing_by_wms');
    setProcessedOrderReference(null);
  }, [orderReference]);

  const removeShippedFromOrders = useCallback(() => {
    setOrderLineStatus(null);
    setOrderReference(null);
    updateOrderStatus(orderReference, 'shipped');
    setShippedOrderReference(null);
  }, [orderReference]);

  const markOrderAsProcessed = useCallback(() => {
    const processedOrder = orders.find((order) => order.order_reference === orderReference);

    if (ORDER_STATUS[orderStatus].includes(processedOrder.status)) {
      setProcessedOrderReference(orderReference);
    } else {
      // Skip 'Marked as Processed' animation since the active tab is not equal to the order's state
      // Proceed with moving the processed order
      removeProcessedFromOrders(-99999);
    }
  }, [orderReference, orders, orderStatus]);

  const markOrderAsShipped = useCallback(() => {
    const shippedOrder = orders.find((order) => order.order_reference === orderReference);

    if (ORDER_STATUS[orderStatus].includes(shippedOrder.status)) {
      setShippedOrderReference(orderReference);
    } else {
      // Skip 'Marked as shipped' animation since the active tab is not equal to the order's state
      // Proceed with moving the processed order
      removeShippedFromOrders(-99999);
    }
  }, [orderReference, orders, orderStatus]);

  function setCurrentOrder(salesOrderReference, status) {
    const targetStatus = Object.keys(ORDER_STATUS).find((key) => ORDER_STATUS[key].includes(status));

    if (targetStatus) {
      setOrderLineStatus(targetStatus);
      setOrderReference(salesOrderReference);
    }
  }

  function determineRow(order, idx) {
    switch (order.order_reference) {
      case processedOrderReference:
        return (
          <RemoveOrderLine
            key={order.order_id}
            afterFadeOut={() => removeProcessedFromOrders(idx)}
            message={t('components.orders.collection.processed_order.mark_as_processed')}
          />
        );
      case shippedOrderReference:
        return (
          <RemoveOrderLine
            key={order.order_id}
            afterFadeOut={() => removeShippedFromOrders(idx)}
            message={t('components.orders.collection.processed_order.mark_as_shipped')}
          />
        );
      default:
        return (
          <OrderLine
            key={order.order_id}
            order={order}
            selected={orderReference === order.order_reference}
            setAsCurrentOrder={() => setCurrentOrder(order.order_reference, order.status)}
          />
        );
    }
  }

  return (
    <div className={styles.ordersContainer}>
      <div className={styles.orders}>
        <Flash flash={flash} />
        <FilterOrders onQueryChange={setQuery} onFilterChange={setOrderStatus} orderStatus={orderStatus} />

        {filteredOrders.length > 0
          && (
            <Table className={`table ${styles['orders-table']}`}>
              <OrdersTableHeader
                onClick={reverseSortDirection}
                sortColumn={sort.column}
                sortDirection={sort.direction}
              />
              <tbody>
                { filteredOrders.map((order, idx) => determineRow(order, idx))}
              </tbody>
            </Table>
          )}
      </div>
      {/* This is only applicable for "new" orders.
      Update this section once Processed and Shipped are supported */}
      <OrderDetails
        orderLineStatus={orderLineStatus}
        carriers={carriers}
        orderReference={orderReference}
        onMarkAsProcessedSuccessful={markOrderAsProcessed}
        onMarkAsShippedSuccessful={markOrderAsShipped}
      />
    </div>
  );
}
