// Placeholder for /orders route
import { Outlet } from 'react-router-dom';
import Private from 'components/layouts/Private';

export default function Orders() {
  return (
    <Private>
      <Outlet />
    </Private>
  );
}
