import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './BaseErrors.module.scss';

export default function BaseErrors(props) {
  if (props.messages.length === 0) return null;

  const header = useMemo(() => (
    props.title ? <div className={styles.header}>{props.title}</div> : null
  ), [props.title]);

  return (
    <div className={styles.errors}>
      {header}
      <ul>
        {
          props.messages.map((message) => <li key={`base-error-${message}`} className={styles.error}>{message}</li>)
        }
      </ul>
    </div>
  );
}

BaseErrors.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
};

BaseErrors.defaultProps = {
  title: null,
  messages: [],
};
