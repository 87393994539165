import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Flash from 'components/Flash';
import styles from './Connect.module.scss';

export default function Connect() {
  const { t } = useTranslation();
  const location = useLocation();
  const [bolFlash] = useState(location.state?.flash);

  document.title = t('routes.bol.connect.document_title');
  const bolLoginUrl = `${window.env.REACT_APP_BOL_URL}/authorize?response_type=code`
                  + `&client_id=${window.env.REACT_APP_BOL_CLIENT_ID}&scope=offline_access`
                  + `&redirect_uri=${window.env.REACT_APP_ROOT_URL}/bol/integration&state=csrf_token&prompt=login`;

  return (
    <div className={styles.connectContainer}>
      <Flash flash={bolFlash} />
      <div data-testid="bolContainer" className={styles.lead}>
        <img className={styles.image} alt="" src="/connect-to-bol.png" />
        <p className={styles.text}>{t('routes.bol.connect.lead1')}</p>
        <p>
          <a href={bolLoginUrl} className={`btn btn-primary ${styles.button}`}>
            <img
              className={styles.icon}
              alt="{t('routes.bol.connect.button_label')}"
              src="/icons/linear/0790-link.svg"
            />
            {t('routes.bol.connect.button_label')}
          </a>
        </p>
      </div>
    </div>
  );
}
