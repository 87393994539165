import { PropTypes } from 'prop-types';
import {
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
import { CurrentOwnerContext, useCurrentOwnerContextValue } from 'utils/currentOwner';
import { LayoutExtraClassNameContext } from './extraClassName';
import Sidebar from './with_sidebar/Sidebar';
import Content from './with_sidebar/Content';

import styles from './WithSidebar.module.scss';

export default function WithSidebar(props) {
  const location = useLocation();
  const currentOwnerContextValue = useCurrentOwnerContextValue();
  const { layoutExtraClassName } = useContext(LayoutExtraClassNameContext);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const toggleSidebar = useCallback(() => setIsSidebarCollapsed(!isSidebarCollapsed), [isSidebarCollapsed]);

  // Collapse sidebar after location and owner change.
  useEffect(() => setIsSidebarCollapsed(true), [location, currentOwnerContextValue]);

  return (
    <div className={`${styles.base} ${layoutExtraClassName}`}>
      <CurrentOwnerContext.Provider value={currentOwnerContextValue}>
        <Sidebar isCollapsed={isSidebarCollapsed} toggle={toggleSidebar} />
        <Content isPushed={!isSidebarCollapsed}>{props.children}</Content>
      </CurrentOwnerContext.Provider>
    </div>
  );
}

WithSidebar.propTypes = { children: PropTypes.element };
WithSidebar.defaultProps = { children: null };
