import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import calendarStyles from 'components/calendar/DeliveryCalendar.module.scss';
import Button from 'components/form/Button';
import { useFindDelivery } from 'api/Deliveries';
import EditScheduleCalendar from 'components/announcements/calendars/EditScheduleCalendar';
import useConfirmChangeOwner from '../useConfirmChangeOwner';
import styles from './Reschedule.module.scss';

function useNavigateToDeliveries(state) {
  const navigate = useNavigate();

  return useCallback(() => (
    navigate('/batches', { state })
  ), [state]);
}

function useDeliveryHasPalletReferences() {
  const location = useLocation();
  const { deliveryId } = location.state;
  const findDelivery = useFindDelivery();
  const [hasPalletReferences, setHasPalletReferences] = useState(null);

  useEffect(() => {
    (async () => {
      const delivery = await findDelivery(deliveryId);
      setHasPalletReferences(delivery.has_pallet_references);
    })();
  }, [findDelivery, setHasPalletReferences, deliveryId]);

  return hasPalletReferences;
}

export default function Reschedule() {
  const { t } = useTranslation();
  const location = useLocation();
  const purchaseReference = location.state.reference;
  const navigateToDeliveries = useNavigateToDeliveries();
  const hasPalletReferences = useDeliveryHasPalletReferences();

  useConfirmChangeOwner();

  document.title = t('routes.batches.default.delivery.document_title');

  if (hasPalletReferences === null) return null;

  return (
    <>
      <div className={`${styles.header} row`}>
        <div className="d-flex mb-3">
          <div className="me-auto">
            <div className={`float-start ${calendarStyles['calendar-title']}`}>
              <h1>{t('routes.batches.default.deliveries.change_delivery')}</h1>
            </div>
            <div className={calendarStyles['calendar-subtitle']}>
              <p>{purchaseReference}</p>
            </div>
          </div>
          <div className={`p-1 ${styles['cancel-button']}`}>
            <Button
              id="btn_announce"
              icon="linear/0811-cross.svg"
              onClick={navigateToDeliveries}
              className={`btn btn-outline-primary ${calendarStyles['cancel-button']}`}
              label={t('routes.batches.default.index.btn_cancel')}
            />
          </div>
        </div>
      </div>

      <EditScheduleCalendar
        navigateToDeliveries={navigateToDeliveries}
        purchaseReference={purchaseReference}
        hasPalletReferences={hasPalletReferences}
      />
    </>
  );
}
