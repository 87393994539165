import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Option from './Option';

export default function UpdateDeliveryDateOption(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const selectNewDeliveryDate = useCallback(() => {
    navigate('/batches/reschedule', {
      state: {
        deliveryId: props.deliveryId,
        reference: props.reference,
        update: true,
      },
    });
  }, [props]);

  return (
    <Option
      onClick={selectNewDeliveryDate}
      label={t('components.deliveries.batch.options.change_delivery_date')}
      icon="calendar"
    />
  );
}

UpdateDeliveryDateOption.propTypes = {
  deliveryId: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
};
