import { useCallback } from 'react';
import PropTypes from 'prop-types';
import DeliveryCalendar from 'components/calendar/DeliveryCalendar';
import { useCurrentYearAndMonth } from 'components/calendar/helpers';
import { useUpdateBatch } from 'api/Batches';
import styles from 'components/calendar/DeliveryCalendar.module.scss';
import TimeSlotContent from './time_slot/TimeSlotContent';

export default function EditScheduleCalendar({ navigateToDeliveries, purchaseReference, hasPalletReferences }) {
  const [yearAndMonth, setYearAndMonth] = useCurrentYearAndMonth();
  const updateBatch = useUpdateBatch(purchaseReference);

  const submit = useCallback(async ({
    isLocked,
    date,
    time,
    cleanup,
  }) => {
    if (isLocked) {
      cleanup();
      return;
    }

    const data = { delivered_on: date, delivery_time: time };
    const result = await updateBatch(data);
    cleanup();

    if (result.success) {
      navigateToDeliveries({
        deliveredOn: date,
        deliveryTime: time,
        reference: purchaseReference,
      });
    } else {
      const error = result.error || result.errors?.details[0];
      alert(error);
    }
  }, [navigateToDeliveries, purchaseReference, updateBatch]);

  const renderTimeSlotContent = useCallback(({ delivery, name, dateString }) => (
    <TimeSlotContent
      delivery={delivery}
      timeSlot={name}
      dateString={dateString}
      currentBatchHasPalletReferences={hasPalletReferences}
      onClick={submit}
    />
  ), [submit, hasPalletReferences]);

  return (
    <div className={styles.calendar}>
      <DeliveryCalendar
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
        renderTimeSlotContent={renderTimeSlotContent}
      />
    </div>
  );
}

EditScheduleCalendar.propTypes = {
  navigateToDeliveries: PropTypes.func.isRequired,
  purchaseReference: PropTypes.string.isRequired,
  hasPalletReferences: PropTypes.bool,
};

EditScheduleCalendar.defaultProps = {
  hasPalletReferences: false,
};
