import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Option from './Option';

export default function UpdatePalletCountOption(props) {
  const { t } = useTranslation();

  return (
    <Option
      onClick={props.onClick}
      label={t('components.deliveries.batch.options.edit_pallet_count')}
      icon="pallet"
    />
  );
}

UpdatePalletCountOption.propTypes = {
  onClick: PropTypes.func.isRequired,
};
