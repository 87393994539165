import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOnSubmit } from 'utils/batches';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import Form from 'components/announcements/Form';
import Progress from 'components/Progress';
import Flash from 'components/Flash';
import AnnouncementHeader from 'components/AnnouncementHeader';
import { useCheckReferenceAvailability } from 'api/Batches';
import { useFieldErrors } from 'routes/batches/FieldErrors';
import useConfirmChangeOwner from '../useConfirmChangeOwner';

function useValidateBatch() {
  const { t } = useTranslation();
  const validateBatch = useCallback(({ palletCount, announcements }) => {
    const errors = {};
    const withPalletReferenceCount = announcements.filter((a) => a.properties['pallet reference']?.raw).length;

    if (announcements.length === withPalletReferenceCount && palletCount) {
      errors.palletCount = t('routes.batches.default.new.errors.useless_pallet_count');
    } else if (announcements.length !== withPalletReferenceCount && withPalletReferenceCount > 0) {
      errors.announcements = t('routes.batches.default.new.errors.inconsistent_pallet_references');
    }

    return errors;
  }, [t]);

  return validateBatch;
}

function detectPalletReferences(announcements) {
  const withPalletReferenceCount = announcements.filter((a) => a.properties['pallet reference']?.raw).length;
  return announcements.length === withPalletReferenceCount;
}

export default function New() {
  const location = useLocation();
  const [canSubmit, setCanSubmit] = useState(true);
  const [hasPalletReferences, setHasPalletReferences] = useState(false);
  const [state, setState] = useState({ name: 'form', flash: location.state?.flash });
  const [baseErrors, setBaseErrors] = useState({});
  const [fieldErrorMessages, setFieldErrorMessages] = useState({});
  const fieldErrors = useFieldErrors({
    fields: ['reference', 'pallet_count', 'announcement_field'],
    onCommit: setFieldErrorMessages,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const validateBatch = useValidateBatch();
  const checkReferenceAvailability = useCheckReferenceAvailability();
  const ownerOwnsDepot = useOwnerOwnsDepot();

  useConfirmChangeOwner();

  useEffect(() => {
    if (ownerOwnsDepot) { navigate('/batches/depot/new'); }
  }, [ownerOwnsDepot]);

  document.title = t('routes.batches.default.new.document_title');

  const showBatchErrorsIfAny = (formData) => {
    const palletCount = formData.pallet_count;
    const announcements = formData.items;

    const batchErrors = validateBatch({ palletCount, announcements });
    const batchErrorMessages = Object.values(batchErrors);

    if (batchErrorMessages.length === 0) return false;

    setState({ name: 'form', formData });
    setBaseErrors({ messages: batchErrorMessages });

    return true;
  };

  const moveToRepair = (formData, validAnnouncements, invalidAnnouncements) => {
    navigate('/batches/repair', {
      state: {
        formData,
        validAnnouncements,
        invalidAnnouncements,
        hasPalletReferences,
      },
    });
  };

  const moveToNextStep = (formData) => {
    navigate('/batches/schedule', { state: { formData, hasPalletReferences } });
  };

  const onSubmitParams = {
    moveToRepair,
    moveToNextStep,
    showBatchErrorsIfAny,
    isPalletCountRequired: !hasPalletReferences,
    setBaseErrors,
    setState,
    fieldErrors,
  };
  const onSubmit = useOnSubmit(onSubmitParams);
  const onBeforeReadFile = useCallback(() => setCanSubmit(false), [setCanSubmit]);
  const onAfterReadFile = useCallback(({ success, announcements, error }) => {
    setCanSubmit(true);
    fieldErrors.current.clear('announcement_field');

    if (!success) {
      fieldErrors.current.set('announcement_field', 'invalid', error).commit();
      return;
    }

    fieldErrors.current.remove('announcement_field', 'invalid').commit();
    setHasPalletReferences(detectPalletReferences(announcements));
  }, [setCanSubmit, setHasPalletReferences]);

  const validateReferenceAvailability = useCallback(async (_e, reference) => {
    if (reference === '') return;

    setCanSubmit(false);
    const { available, suggestion } = (await checkReferenceAvailability(reference)).json;

    fieldErrors.current.clear('reference');

    if (available) {
      fieldErrors.current.remove('reference', 'unique');
    } else {
      const error = t('routes.batches.default.new.errors.reference_exists', { reference, suggestion });
      fieldErrors.current.set('reference', 'unique', error);
    }

    fieldErrors.current.commit();
    setCanSubmit(true);
  }, [setCanSubmit, checkReferenceAvailability]);

  if (state.name === 'validatingItems') {
    return (
      <Progress
        title={t('components.progress.validating.title')}
        body={t('components.progress.validating.body')}
      />
    );
  }

  return (
    <>
      <AnnouncementHeader step={1} title={t('routes.batches.default.new.announce_batch')} />
      <Flash flash={state.flash} />
      <Form
        onFormRead={onSubmit}
        baseErrors={baseErrors}
        fieldErrors={fieldErrorMessages}
        formData={state.formData}
        onBeforeReadFile={onBeforeReadFile}
        onAfterReadFile={onAfterReadFile}
        onReferenceFieldChange={validateReferenceAvailability}
        canSubmit={canSubmit}
      />
    </>
  );
}
