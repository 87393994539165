import { useCallback } from 'react';
import { useVerdictRequestHeaders } from 'utils/sessionHelpers';

const API_URL = window.env.REACT_APP_BASE_API_URL;
const BASE_URL = new URL('bol/refresh_token', API_URL);

export function useUpdatePartnerToken() {
  const headers = useVerdictRequestHeaders();

  return useCallback(async (code) => {
    let response = {};
    try {
      response = await fetch(BASE_URL, {
        headers,
        method: 'POST',
        body: JSON.stringify({ code }),
      });

      const json = await response.json();

      if (response.ok) {
        return { success: true, statusText: response.statusText, json };
      }

      const { errors } = json;
      return { success: false, statusText: response.statusText, errors };
    } catch (error) {
      return { success: false, statusText: response.statusText, json: null };
    }
  }, [headers]);
}

export function useCheckPartnerToken() {
  const headers = useVerdictRequestHeaders();

  return useCallback(async () => {
    const url = new URL('bol/refresh_token', API_URL);
    let response = {};

    try {
      response = await fetch(url, { headers });
      const json = await response.json();

      if (response.ok) {
        return { success: true, statusText: response.statusText, json };
      }

      const { errors } = json;
      return { success: false, statusText: response.statusText, errors };
    } catch (error) {
      return { success: false, statusText: response.statusText, error };
    }
  }, [headers]);
}
