import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/form/Button';
import debounce from 'lodash.debounce';
import styles from './FilterOrders.module.scss';

const FILTER_ORDER_DELAY = 300;

export default function FilterOrders(props) {
  const { t } = useTranslation();

  const filterOrders = useMemo(() => (
    debounce((e) => {
      if (props.onQueryChange) props.onQueryChange(e.target.value.trim());
    }, FILTER_ORDER_DELAY)
  ), [props.onQueryChange]);

  return (
    <form className={styles['order-filter-form']}>
      <label htmlFor="query" className="col-sm-4 col-form-label">
        {t('components.orders.filter_orders.search_label')}
      </label>
      <div className={`input-group ${styles.queryInput}`}>
        <input className={`form-control ${styles['no-br']}`} onChange={filterOrders} aria-label="order query" />
        <span className={`input-group-text ${styles.transparent}`}>
          <i className={`x-icon x-icon-magnifier ${styles.grey}`} />
        </span>
      </div>
      <div className={`btn-group ${styles.filterStatusButtons}`} role="group" aria-label="Filter by attribute">
        <Button
          onClick={() => props.onFilterChange('new')}
          className={`btn btn-outline-primary ${props.orderStatus === 'new' ? styles.selected : ''}`}
          label={t('components.orders.filter_orders.filters.new')}
        />
        <Button
          onClick={() => props.onFilterChange('processed')}
          className={`btn btn-outline-primary ${props.orderStatus === 'processed' ? styles.selected : ''}`}
          label={t('components.orders.filter_orders.filters.processed')}
        />
        <Button
          onClick={() => props.onFilterChange('shipped')}
          className={`btn btn-outline-primary ${props.orderStatus === 'shipped' ? styles.selected : ''}`}
          label={t('components.orders.filter_orders.filters.shipped')}
        />
      </div>
    </form>
  );
}

FilterOrders.propTypes = {
  onQueryChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  orderStatus: PropTypes.string.isRequired,
};
