import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import DeliveryCard from './time_slot_content/DeliveryCard';

export default function TimeSlotContent({ delivery }) {
  return (
    <div className="h-100">
      <DeliveryCard delivery={delivery} />
    </div>
  );
}

TimeSlotContent.propTypes = { delivery: PropTypes.instanceOf(Delivery) };
TimeSlotContent.defaultProps = { delivery: null };
