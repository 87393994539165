export default class Property {
  constructor(value, parsedValue) {
    this._raw = value;
    this._parsed = parsedValue;
  }

  get raw() {
    return this._raw;
  }

  get parsed() {
    return this._parsed;
  }
}
