import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import RepairTable from 'components/announcements/RepairTable';
import useConfirmChangeOwner from '../useConfirmChangeOwner';

export default function Repair() {
  const location = useLocation();
  const { t } = useTranslation();
  const {
    formData,
    validAnnouncements,
    invalidAnnouncements,
    hasPalletReferences,
  } = { ...location.state };
  const navigate = useNavigate();
  const ownerOwnsDepot = useOwnerOwnsDepot();

  useConfirmChangeOwner();

  useEffect(() => {
    if (ownerOwnsDepot) { navigate('/batches/depot/new'); }
  }, [ownerOwnsDepot]);

  document.title = t('routes.batches.default.repair.document_title');

  return (
    <RepairTable
      formData={formData}
      validAnnouncements={validAnnouncements}
      invalidAnnouncements={invalidAnnouncements}
      hasPalletReferences={hasPalletReferences}
    />
  );
}
