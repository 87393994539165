// Placeholder for /bol route
import { Outlet } from 'react-router-dom';
import Private from 'components/layouts/Private';

export default function Bol() {
  return (
    <Private>
      <Outlet />
    </Private>
  );
}
