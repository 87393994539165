import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOnSubmit } from 'utils/batches';
import { useCheckReferenceAvailability } from 'api/depot/Batches';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import Form from 'components/batches/depot/Form';
import Progress from 'components/Progress';
import Flash from 'components/Flash';
import Header from 'components/batches/depot/new/Header';
import useConfirmChangeOwner from 'routes/batches/useConfirmChangeOwner';
import { useFieldErrors } from 'routes/batches/FieldErrors';

export default function New() {
  const location = useLocation();
  const [canSubmit, setCanSubmit] = useState(true);
  const hasPalletReferences = false;
  const [state, setState] = useState({ name: 'form', flash: location.state?.flash });
  const [baseErrors, setBaseErrors] = useState({});
  const [fieldErrorMessages, setFieldErrorMessages] = useState({});
  const fieldErrors = useFieldErrors({ fields: ['reference', 'announcement_field'], onCommit: setFieldErrorMessages });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const checkReferenceAvailability = useCheckReferenceAvailability();
  const ownerOwnsDepot = useOwnerOwnsDepot();

  useConfirmChangeOwner();

  useEffect(() => {
    if (!ownerOwnsDepot) { navigate('/batches/new'); }
  }, [ownerOwnsDepot]);

  document.title = t('routes.batches.depot.new.document_title');

  // Prevent moving to next step if duplicate SKUs are detected
  const showBatchErrorsIfAny = (formData) => {
    const skus = formData.items.map((item) => item.properties.sku.parsed);
    const duplicates = skus.filter((sku, index) => skus.indexOf(sku) !== index);

    if (duplicates.length === 0) return false;

    setState({ name: 'form', formData });
    setBaseErrors({ messages: [t('routes.batches.depot.new.errors.duplicate_skus')] });

    return true;
  };

  const moveToRepair = (formData, validAnnouncements, invalidAnnouncements) => {
    navigate('/batches/depot/repair', {
      state: {
        formData,
        validAnnouncements,
        invalidAnnouncements,
        hasPalletReferences,
      },
    });
  };

  const moveToNextStep = (formData) => {
    navigate('/batches/depot/create', { state: { formData } });
  };

  const onSubmitParams = {
    moveToRepair,
    moveToNextStep,
    showBatchErrorsIfAny,
    isPalletCountRequired: false,
    setBaseErrors,
    setState,
    fieldErrors,
  };
  const onSubmit = useOnSubmit(onSubmitParams);
  const onBeforeReadFile = useCallback(() => setCanSubmit(false), [setCanSubmit]);
  const onAfterReadFile = useCallback(({ success, error }) => {
    setCanSubmit(true);
    fieldErrors.current.clear('announcement_field');

    if (!success) {
      fieldErrors.current.set('announcement_field', 'invalid', error).commit();
      return;
    }

    fieldErrors.current.remove('announcement_field', 'invalid').commit();
  }, [setCanSubmit, hasPalletReferences]);

  // Placeholder, will be revisited in PP-313
  const validateReferenceAvailability = useCallback(async (_e, reference) => {
    if (reference === '') return;

    setCanSubmit(false);
    const { available, suggestion } = (await checkReferenceAvailability(reference)).json;

    fieldErrors.current.clear('reference');

    if (available) {
      fieldErrors.current.remove('reference', 'unique');
    } else {
      const error = t('routes.batches.depot.new.errors.reference_exists', { reference, suggestion });
      fieldErrors.current.set('reference', 'unique', error);
    }

    fieldErrors.current.commit();
    setCanSubmit(true);
  }, [setCanSubmit]);

  if (state.name === 'validatingItems') {
    return (
      <Progress
        title={t('components.progress.validating.title')}
        body={t('components.progress.validating.body')}
      />
    );
  }

  return (
    <>
      <Header step={1} title={t('routes.batches.depot.new.announce_batch')} />
      <Flash flash={state.flash} />
      <Form
        onFormRead={onSubmit}
        baseErrors={baseErrors}
        fieldErrors={fieldErrorMessages}
        formData={state.formData}
        onBeforeReadFile={onBeforeReadFile}
        onAfterReadFile={onAfterReadFile}
        onReferenceFieldChange={validateReferenceAvailability}
        canSubmit={canSubmit}
      />
    </>
  );
}
