import { PropTypes } from 'prop-types';
import styles from './Content.module.scss';

export default function Content(props) {
  const position = props.isPushed ? styles.pushed : styles.default;
  const cssClass = `${styles.content} ${position}`;

  return (
    <div className={cssClass}>
      {props.children}
    </div>
  );
}

Content.propTypes = {
  isPushed: PropTypes.bool.isRequired,
  children: PropTypes.element,
};

Content.defaultProps = { children: null };
