import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18n/config';
import { CurrentOwnerContext, useRedirectOnChangeOwner } from 'utils/currentOwner';
import PowerBiTable from 'components/PowerBiTable';
import Header from 'components/Header';
import styles from './Details.module.scss';

const OWNER_NAME_FIELD = 'Items/Owner';
const PURCHASE_REFERENCE_FIELD = 'Items/Original Purchase Reference';
const DATE_FIELD = 'Date/Year Month Name';

function formatDate(yearMonth) {
  return new Date(yearMonth).toLocaleString(i18n.language, { year: 'numeric', month: 'short' });
}

export default function Details() {
  const { t } = useTranslation();
  const { currentOwner } = useContext(CurrentOwnerContext);
  const pageName = window.env.REACT_APP_POWER_BI_SALES_DETAILS_REPORT_PAGE_NAME;
  let title = '';

  const [params] = useSearchParams();
  const purchaseReference = params.get('original_purchase_reference');
  const date = params.get('date');

  const filters = { [OWNER_NAME_FIELD]: currentOwner.name };

  if (purchaseReference !== null) {
    filters[PURCHASE_REFERENCE_FIELD] = purchaseReference;
    title = t('routes.reports.sales.default.details.header_by_purchase_reference', { purchaseReference });
  } else if (date !== null) {
    filters[DATE_FIELD] = date;
    title = t('routes.reports.sales.default.details.header_by_date', { date: formatDate(date) });
  }

  useRedirectOnChangeOwner('/reports/sales');

  document.title = title;

  return (
    <div className={styles.default}>
      <Header className="pb-1" title={title} />
      <PowerBiTable pageName={pageName} filters={filters} />
    </div>
  );
}
