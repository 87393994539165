import { useTranslation } from 'react-i18next';
import { useSwitchToDeliveriesListView } from 'utils/deliveriesViewContext';

export default function CalendarViewLink() {
  const { t } = useTranslation();
  const switchToListView = useSwitchToDeliveriesListView();

  function onClick(e) {
    e.preventDefault();
    switchToListView();
  }

  return (
    <a href="#listView" className="align-self-center d-flex ms-3" onClick={onClick}>
      <i className="x-icon x-icon-hamburger me-1" />
      {t('routes.batches.default.index.list_view')}
    </a>
  );
}
