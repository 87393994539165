import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseErrors from 'components/form/BaseErrors';
import TextField from 'components/form/TextField';
import NumberField from 'components/form/NumberField';
import FileField from 'components/form/FileField';
import Button from 'components/form/Button';
import { useBroadcastReferenceFieldChange, useOnFileChange, useOnSubmit } from 'utils/forms';
import styles from './Form.module.scss';

const TEMPLATE_FILENAME = 'announcements_buybay_en.xlsx';
const TEMPLATE_PATH = `/${TEMPLATE_FILENAME}`;

export default function Form(props) {
  const { t } = useTranslation();
  const [announcements, setAnnouncements] = useState(null);
  const broadcastReferenceFieldChange = useBroadcastReferenceFieldChange(props.onReferenceFieldChange);
  const submit = useOnSubmit({
    onFormRead: props.onFormRead,
    canSubmit: props.canSubmit,
    announcements,
    withDepot: false,
  });
  const onFileChange = useOnFileChange({
    onBeforeReadFile: props.onBeforeReadFile,
    onAfterReadFile: props.onAfterReadFile,
    setAnnouncements,
    withDepot: false,
  });

  return (
    <>
      <BaseErrors messages={props.baseErrors.messages} title={props.baseErrors.title} />
      <form id="announcementForm" className={styles.form} action="#">
        <TextField
          id="reference"
          label={t('components.form.reference')}
          errors={props.fieldErrors}
          defaultValue={props.formData.reference}
          onChange={broadcastReferenceFieldChange}
        />
        <NumberField
          id="pallet_count"
          errors={props.fieldErrors}
          size="small"
          defaultValue={props.formData.pallet_count}
        />
        <FileField
          id="announcement_field"
          errors={props.fieldErrors}
          onChange={onFileChange}
          formText={[<a key="anchor" href={TEMPLATE_PATH}>{t('components.form.download_template')}</a>,
            t('components.form.add_items')]}
        />
        <Button
          id="btn_announce"
          icon=""
          onClick={submit}
          className={`btn btn-primary mt-3 ${styles['submit-button']}`}
          label={t('components.form.btn_announce')}
          disabled={!props.canSubmit}
        />
      </form>
    </>
  );
}

Form.propTypes = {
  onFormRead: PropTypes.func.isRequired,
  baseErrors: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }),
  fieldErrors: PropTypes.objectOf(PropTypes.string),
  formData: PropTypes.shape({
    reference: PropTypes.string,
    pallet_count: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
  }),
  onBeforeReadFile: PropTypes.func,
  onAfterReadFile: PropTypes.func,
  onReferenceFieldChange: PropTypes.func,
  canSubmit: PropTypes.bool,
};

Form.defaultProps = {
  baseErrors: { title: null, messages: [] },
  fieldErrors: {},
  formData: {},
  onReferenceFieldChange: null,
  canSubmit: true,
  onBeforeReadFile: null,
  onAfterReadFile: null,
};
