import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './RepairItemTableHeader.module.scss';

export default function RepairItemTableHeader(props) {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <th scope="col" className={classes.row}>#</th>
        <th scope="col" className={classes.ean}>{t('components.repair_item_table_header.ean')}</th>
        <th scope="col" className={classes.asin}>{t('components.repair_item_table_header.asin')}</th>
        <th scope="col" className={classes['other-reference']}>
          {t('components.repair_item_table_header.other_reference')}
        </th>
        <th scope="col" className={classes.title}>{t('components.repair_item_table_header.title')}</th>
        <th scope="col" className={classes['retail-price']}>{t('components.repair_item_table_header.retail_price')}</th>
        <th scope="col" className={classes.quantity}>{t('components.repair_item_table_header.quantity')}</th>

        { props.ownerOwnsDepot
          && (
            <>
              <th scope="col" className={classes.sku}>{t('components.repair_item_table_header.sku')}</th>
              <th scope="col" className={classes.grade}>{t('components.repair_item_table_header.grade')}</th>
              <th scope="col" className={classes['grade-remark']}>
                {t('components.repair_item_table_header.grade_remark')}
              </th>
            </>
          ) }

        <th scope="col" className={classes['owner-reference']}>
          {t('components.repair_item_table_header.owner_reference')}
        </th>
        <th scope="col" className={classes['owner-price']}>{t('components.repair_item_table_header.owner_price')}</th>
        <th scope="col" className={classes.return}>{t('components.repair_item_table_header.return')}</th>
      </tr>
    </thead>
  );
}

RepairItemTableHeader.propTypes = {
  ownerOwnsDepot: PropTypes.bool.isRequired,
};
