import { PropTypes } from 'prop-types';
import styles from './Public.module.scss';

export default function Public(props) {
  return (
    <div className={styles.public}>
      {props.children}
    </div>
  );
}

Public.propTypes = { children: PropTypes.element };
Public.defaultProps = { children: null };
