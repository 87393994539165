const authority = `https://login.microsoftonline.com/${window.env.REACT_APP_MSA_APP_TENANT_ID}`;

export const msalConfig = {
  auth: {
    clientId: window.env.REACT_APP_MSA_APP_CLIENT_ID,
    authority,
    clientSecret: window.env.REACT_APP_MSA_APP_CLIENT_SECRET,
    knownAuthorities: [authority],
    redirectUri: '/',
    postLogoutRedirectUri: '/login',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if there are issues on IE11 or Edge
  },
};

// scopes for ID token to be used at Microsoft identity platform endpoints
// state for storing url of the original request
export const loginRequest = {
  scopes: ['User.Read'],
};

const url = 'https://graph.microsoft.com/v1.0/me';

// Microsoft Graph API services needed
export const graphConfig = {
  graphMeEndpoint: url,
  groupEndpoint: `${url}/transitiveMemberOf/microsoft.graph.group?$count=true&$select=displayName,description`,
};
