import PropTypes from 'prop-types';
import styles from 'components/calendar/DeliveryCalendar.module.scss';
import { isBatchReportReady } from 'components/deliveries/deliveries_collection/helpers';
import locals from './BatchLink.module.scss';

export default function BatchLink({ reference, deliveryId, status }) {
  const isClickable = isBatchReportReady(status);

  if (isClickable) {
    const reportPath = `/reports/batches?purchaseReference=${reference}&deliveryId=${deliveryId}`;
    return (
      <li className={`${styles['card-text']} ${locals.default} ${locals.clickable}`}>
        <a href={reportPath}>{reference}</a>
      </li>
    );
  }

  return (
    <li className={`${styles['card-text']} ${locals.default} ${locals.disabled}`}>
      {reference}
    </li>
  );
}

BatchLink.propTypes = {
  reference: PropTypes.string.isRequired,
  deliveryId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};
