import { useRef, useCallback } from 'react';

export default function useAfterFadeOut(callback) {
  const element = useRef(null);
  const afterFadeOut = useCallback((e) => {
    if (e.target === element.current && e.propertyName === 'opacity') callback();
  }, [element.current, callback]);

  return { element, afterFadeOut };
}
