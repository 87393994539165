import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import TimeSlot from './day/TimeSlot';
import DayHeader from './day/DayHeader';
import styles from './Day.module.scss';

function useLabelDeliveryByTimeSlot(deliveries) {
  return useMemo(() => {
    const result = { morning: null, afternoon: null };
    if (!deliveries) return result;

    deliveries.forEach((delivery) => { result[delivery.timeSlot] = delivery; });
    return result;
  }, [deliveries]);
}

export default function Day(props) {
  const { morning, afternoon } = useLabelDeliveryByTimeSlot(props.deliveries);

  return (
    <div key={props.calendarDayObject.dateString} className={styles.default}>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <DayHeader dayObject={props.calendarDayObject} />
          <div className={styles.body}>
            <TimeSlot
              name="morning"
              dateString={props.calendarDayObject.dateString}
              delivery={morning}
              renderContent={props.renderTimeSlotContent}
            />

            <TimeSlot
              name="afternoon"
              dateString={props.calendarDayObject.dateString}
              delivery={afternoon}
              renderContent={props.renderTimeSlotContent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Day.propTypes = {
  calendarDayObject: PropTypes.shape({
    dateString: PropTypes.string,
    dayOfMonth: PropTypes.number,
  }).isRequired,
  renderTimeSlotContent: PropTypes.func,
  deliveries: PropTypes.arrayOf(PropTypes.instanceOf(Delivery)),
};

Day.defaultProps = {
  renderTimeSlotContent: () => {},
  deliveries: [],
};
