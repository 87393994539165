import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Header from 'components/Header';
import DeliveriesCollection from 'components/deliveries/DeliveriesCollection';
import ViewSwitcher from 'components/deliveries/ViewSwitcher';
import { DeliveriesViewContextWrapper } from 'utils/deliveriesViewContext';
import { useOwnerOwnsDepot } from 'utils/currentOwner';
import Flash from 'components/Flash';
import styles from './Index.module.scss';

export default function Index() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ownerOwnsDepot = useOwnerOwnsDepot();
  const location = useLocation();
  const [bolFlash] = useState(location.state?.flash);

  useEffect(() => {
    if (ownerOwnsDepot) { navigate('/batches/depot'); }
  }, [ownerOwnsDepot]);

  document.title = t('routes.batches.default.index.document_title');

  if (ownerOwnsDepot === true) return '';

  return (
    <DeliveriesViewContextWrapper>
      <div className="index">
        <div className="d-flex mb-3">
          <Header title={t('routes.batches.default.index.title')} />
          <Link to="/batches/new" className="align-self-center d-flex ms-3">
            <i className="x-icon x-icon-small x-icon-plus" />
            {t('routes.batches.default.index.announce_batch')}
          </Link>
          <div className={styles.divider} />
          <ViewSwitcher />
          <img alt="flying drone" className={styles.illustration} src="/drone.svg" width={175} height={95} />
        </div>
        <Flash flash={bolFlash} />

        <DeliveriesCollection />
      </div>
    </DeliveriesViewContextWrapper>
  );
}
