import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import PowerBiTable from 'components/PowerBiTable';
import { CurrentOwnerContext } from 'utils/currentOwner';

const PAGE_NAME = window.env.REACT_APP_POWER_BI_BATCH_REPORT_PAGE_NAME;
const DELIVERY_REFERENCE_FIELD = 'Bulk Deliveries/Delivery Reference';
const OWNER_NAME_FIELD = 'Items/Owner';
const PURCHASE_REFERENCE_FIELD = 'Items/Original Purchase Reference';

export default function Report(props) {
  const { currentOwner } = useContext(CurrentOwnerContext);
  const filters = useMemo(() => ({
    [OWNER_NAME_FIELD]: currentOwner.name,
    [DELIVERY_REFERENCE_FIELD]: props.deliveryReference,
    [PURCHASE_REFERENCE_FIELD]: props.purchaseReference,
  }), [props.deliveryReference, props.purchaseReference]);

  return <PowerBiTable pageName={PAGE_NAME} filters={filters} />;
}

Report.propTypes = {
  deliveryReference: PropTypes.string.isRequired,
  purchaseReference: PropTypes.string.isRequired,
};
