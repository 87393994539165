import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUpdatePartnerToken } from 'api/Bol';

export default function Integration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const updatePartnerToken = useUpdatePartnerToken();
  const [params] = useSearchParams();
  const shortCode = params.get('code');
  const error = params.get('error');
  const errorDescription = params.get('error-description');

  useEffect(async () => {
    if (error || errorDescription) {
      navigate('/bol/connect', { state: { flash: { error: t('routes.bol.integration.flash.cancelled') } } });
    } else if (!shortCode) {
      navigate('/bol/connect', { state: { flash: { error: t('routes.bol.integration.flash.missing_code') } } });
    } else {
      const result = await updatePartnerToken(shortCode);

      if (result.success) {
        navigate('/?bolAuthorized=true');
      } else {
        navigate('/bol/connect', { state: { flash: { error: t('routes.bol.integration.flash.failure') } } });
      }
    }
  }, [shortCode, error, errorDescription]);

  return '';
}
