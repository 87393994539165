import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DeliveryContext from 'utils/deliveryContext';
import PalletCount from './delivery_board/PalletCount';
import BatchList from './delivery_board/BatchList';
import styles from './DeliveryBoard.module.scss';

function deliveryConfig(deliveryConfirmed, locked) {
  switch (true) {
    case deliveryConfirmed:
      return {
        style: styles.confirmed,
        icon: '/icons/linear/0821-check.svg',
        batchStyle: { borderColor: '#E8F0A0' },
      };
    case locked:
      return {
        style: styles.locked,
        icon: '/icons/lock-black.svg',
        batchStyle: { borderColor: '#FFECBE' },
      };
    case !locked:
      return {
        style: styles.unlocked,
        icon: '/icons/opened-lock-black.svg',
        batchStyle: { borderColor: '#BFE8EB' },
      };
    default:
      return { style: null, icon: null, batchStyle: { borderColor: 'initial' } };
  }
}

function deliveryHeader(deliveryDate, deliveryTime) {
  const designation = (deliveryTime === 'morning') ? 'AM' : 'PM';
  const [year, month, day] = deliveryDate.split('-');

  return `${parseInt(day)}-${parseInt(month)}-${year.slice(-2)} ${designation}`;
}

function closeDate(lockedOn) {
  if (!lockedOn) return null;

  const d = new Date(lockedOn);
  // eslint-disable-next-line no-unused-vars
  const [year, month, day] = d.toISOString().substring(0, 10).split('-');
  return `CLOSES ${day}-${month}`;
}

export default function DeliveryBoard(props) {
  const [batches, setBatches] = useState(props.batches);
  const config = deliveryConfig(props.deliveryConfirmed, props.locked);
  const deliveryStatus = useMemo(() => {
    switch (true) {
      case props.deliveryConfirmed: return 'confirmed';
      case props.locked: return 'locked';
      default: return 'unlocked';
    }
  }, [props.deliveryConfirmed, props.locked]);

  const { t } = useTranslation();

  const deliveryPalletCount = useMemo(() => (
    batches.reduce((totalPalletCount, currentBatch) => totalPalletCount + currentBatch.pallet_count, 0)
  ), [batches]);

  const updateBatchPalletCount = useCallback((batchReference, newPalletCount) => {
    setBatches((current) => current.map((batch) => (
      (batch.reference === batchReference) ? { ...batch, pallet_count: newPalletCount } : batch
    )));
  }, [batches]);

  const removeBatch = useCallback((batchReference = '') => {
    setBatches((current) => current.filter((batch) => batch.reference !== batchReference));
  }, [batches]);

  const deliveryContextValues = useMemo(() => (
    { updateBatchPalletCount, removeBatch }
  ), [updateBatchPalletCount, removeBatch]);

  return (
    <DeliveryContext.Provider value={deliveryContextValues}>
      <div className={config.style}>
        <div className={`card p-0 ${styles.card}`} data-e2e={deliveryHeader(props.deliveryDate, props.deliveryTime)}>
          <div className={`card-header d-flex justify-content-between ${styles.cardHeader}`}>
            <div className="d-flex align-items-center">
              <div className={styles.deliveryTime}>{deliveryHeader(props.deliveryDate, props.deliveryTime)}</div>
              {(batches.length > 0) ? (
                <PalletCount count={deliveryPalletCount} />
              ) : (
                <div className={styles.empty}>{t('components.delivery_board.empty_delivery')}</div>
              )}
            </div>

            <div className={`d-flex ${styles.status}`}>
              <div className={`d-none ${styles.closes}`}>{closeDate(props.lockedOn)}</div>
              <img src={config.icon} alt="delivery status icon" width="18" height="16" />
            </div>
          </div>
          <div className={`card-body p-0 ${styles.cardBody}`}>
            <BatchList
              batches={batches}
              deliveryId={props.id}
              deliveryDate={props.deliveryDate}
              deliveryTime={props.deliveryTime}
              deliveryStatus={deliveryStatus}
              locked={props.locked === true}
              batchStyle={config.batchStyle}
            />
          </div>
        </div>
      </div>
    </DeliveryContext.Provider>
  );
}

DeliveryBoard.propTypes = {
  id: PropTypes.number.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
  deliveryConfirmed: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  lockedOn: PropTypes.string,
  batches: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      status: PropTypes.string,
      pallet_count: PropTypes.number,
      items: PropTypes.number,
    })
  ).isRequired,
};

DeliveryBoard.defaultProps = {
  lockedOn: null,
};
