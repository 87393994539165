export default class Delivery {
  constructor(properties) {
    this._properties = properties;
  }

  get id() {
    return this._properties.id;
  }

  get reference() {
    return this._properties.reference;
  }

  get timeSlot() {
    return this._properties.delivery_time || 'morning';
  }

  get batches() {
    return this._properties.batches;
  }

  get date() {
    return this._properties.delivery_date;
  }

  formattedDeliveryDate() {
    if (this._formattedDeliveryDate) return this._formattedDeliveryDate;

    let [year, month, day] = this._properties.delivery_date.split('-');

    // Remove leading 0
    day = parseInt(day);
    month = parseInt(month);
    // Get last 2 digits
    year = year.slice(-2);

    this._formattedDeliveryDate = [day, month, year].join('-');
    return this._formattedDeliveryDate;
  }

  formattedDeliveryTime() {
    const time = this._properties.delivery_time === 'morning' ? 'AM' : 'PM';
    return time;
  }

  isLocked() {
    return this._properties.locked === true;
  }

  isConfirmed() {
    return this._properties.delivery_confirmed === true;
  }

  hasPalletReferences() {
    return this._properties.has_pallet_references === true;
  }

  hasBatches() {
    return this._properties.batches.length > 0;
  }
}
