import PropTypes from 'prop-types';
import classes from './RepairItemCellContent.module.scss';

export default function RepairItemCellContent(props) {
  function absolute(event) {
    if (event.target.value === '') { return; }

    event.target.value = Math.abs(event.target.value);
    if (event.target.value === '0') {
      event.target.value = 1;
    }
  }

  const INPUT_CONFIGS = {
    price: {
      type: 'number', step: 'any', min: 1, onChange: absolute,
    },
    integer: {
      type: 'number', step: 1, min: 1, onChange: absolute,
    },
    ean: { type: 'text', maxLength: 13 },
    asin: { type: 'text', maxLength: 10 },
    text: { type: 'text' },
  };
  const TEXT_CONFIGS = {
    'product title': classes['product-title'],
    'return reason': classes['return-reason'],
  };
  const inputSetting = INPUT_CONFIGS[props.inputType] || INPUT_CONFIGS.text;
  const textSetting = TEXT_CONFIGS[props.name] || '';

  return (
    <td className={`${classes.cell} ${props.errorClass} ${textSetting}`}>
      <input
        name={props.name}
        aria-label={props.name}
        className="w-100 shadow-none"
        defaultValue={(props.value === null ? '' : props.value)}
        // TODO: Fix this
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputSetting}
      />
    </td>
  );
}

RepairItemCellContent.propTypes = {
  inputType: PropTypes.string,
  name: PropTypes.string,
  textClass: PropTypes.string,
  errorClass: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

RepairItemCellContent.defaultProps = {
  inputType: '',
  name: '',
  textClass: '',
  errorClass: '',
  value: '',
};
