import PropTypes from 'prop-types';
import Delivery from 'classes/delivery';
import BaseDeliveryCard from 'components/calendar/day/time_slot/DeliveryCard';
import styles from 'components/calendar/DeliveryCalendar.module.scss';
import BatchLink from './delivery_card/BatchLink';

export default function DeliveryCard({ delivery }) {
  return (
    <BaseDeliveryCard delivery={delivery}>
      <ul className={styles['no-bullets']}>
        {delivery.batches.slice(0, 3).map((batch) => (
          <BatchLink
            key={batch.reference}
            reference={batch.reference}
            status={batch.status}
            deliveryId={delivery.id}
          />
        ))}
      </ul>
    </BaseDeliveryCard>
  );
}

DeliveryCard.propTypes = {
  delivery: PropTypes.instanceOf(Delivery).isRequired,
};
